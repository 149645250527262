import React from 'react';

class Footer extends React.Component {
	render() {
		return (
				<footer>
					<div className="container">
						<div className="footer__menu">
							<ul>
								<li className="footer__copy"><a href="">© T2, 2024</a></li>
								<li style={{'display': 'none'}}><a href="">О проекте</a></li>
								<li><a href="mailto:wtt@tele2.ru">Обратная связь</a></li>
							</ul>
						</div>
					</div>
				</footer>
		);
	}
}

export default Footer;
