import React from 'react';
import Header from './header.jsx'
import { Route, Redirect } from 'react-router'
import {withRouter} from 'react-router-dom'
import { Link } from 'react-router-dom';
import MarketPrize from './market_prize.jsx'
import withDispatch from './withDispatch.jsx'


class MarketDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      obj: {}
    }
  }

  componentDidMount() {
  	this.props.app.api('GET', '/api/market/prizes/' + this.props.match.params.id, {}, (data) => {
  		this.setState({'obj': data})

  	})
  }

  buyItem(item) {
      this.props.dispatch({type: 'MODAL_DISMISS'});
      this.props.app.api('POST', '/api/market/purchase/' + this.state.obj.ID, {}, (data) => {
          this.props.history.push("/prizes/" + data.purchase);
      })
  }

  onActionClick() {
      this.props.dispatch({
          type: 'MODAL_SHOW',
          modal: {
              header: 'Подтвердите покупку приза',
              message: [this.state.obj.Name],
              buttons: [{name: 'buy', title: 'Купить', default: true, onClick: () => this.buyItem()}, {name: 'price', title: this.state.obj.Cost, price: true}]
          }
      })
  }

  renderAction() {
      if (this.state.obj.CanBuy) {
          return <div className="prize_button" onClick={() => this.onActionClick()}>Купить</div>
      } else {
          return <div className="no-funds">
                Недостаточно ресурсов для покупки
          </div>
      }

  }

  renderContent() {

        return (<div className="prize_content">
              <div className="cost">
                  <span> Стоимость: </span>
                  <span>{this.state.obj.Cost}</span>
                  <span className="coin"/>
              </div>
              <div className="prize_status">Доступно: {this.state.obj.Quantity}</div>
              <div className="actions">
                  {this.renderAction()}
              </div>
        </div>);
  }

  renderData(){
      if (!this.state.obj.ID) {
          return null;
      }

      return (
        <div className="prize_details_content">
          <div className="image">
            <img src={"/api/market/prizes/"+this.props.match.params.id+"/image.png"} />
          </div>
          {this.renderContent()}
        </div>
      )
  }

  render() {
    return (
        <div>
          <header>
            <Header app={this.props.app}/>
            <div className="header__title">
              <div className="container">
                Призы
              </div>
            </div>
          </header>
          <section className="wrapper">
            <div className="container">
                <div className="prize_details_caption">{this.state.obj.Name}</div>
                {this.renderData()}
            </div>
          </section>
        </div>

    );

  }

}

export default withDispatch(withRouter(MarketDetails));
