import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withRouter} from 'react-router-dom'
import Comment from './comment.jsx'
import withScrollHandler from './withScrollHandler.jsx';

class CommentListContent extends React.Component {
	constructor() {
		super();
		this.state = {
			'comments': []
		};
		this.handleScroll = this.scrollHandler.bind(this);
	}

	queryParams(props, last_id) {
		let params = { 'pageSelected': props.page, 'count': 10, 'last_id': last_id };
		for (var key in props.filters) {
        	if (props.filters.hasOwnProperty(key)) {
        		params[key] = props.filters[key];
        	}
    	}
    	console.log(params);
    	return params;
	}

	loadData(props, last_id, callback) {
		this.props.app.api('GET', '/api/comments', this.queryParams(props, last_id), (data)=> {
			if (last_id) {
				this.setState({'comments': this.state.comments.concat(data)});
			} else {
				this.setState({'comments': data});
			}
			if (callback)
			   { callback(); }
		}, ()=> { console.log('error')})
	}

	onScrolledToEnd(scrollBack) {
			if (this.state.comments.length > 0) {
				let last_id = this.state.comments[this.state.comments.length - 1]._id;
				this.loadData(this.props, last_id, scrollBack);
			}
  }

	componentWillMount() {
		this.loadData(this.props)
	}

	componentWillReceiveProps(new_props) {
		if (new_props.page != this.props.page || new_props.filters != this.props.filters || new_props.page != this.props.page) {
			this.loadData(new_props)
		}
	}


	rows() {

		let formatDate = (iso_date) => {
			let date= new Date(iso_date);
			let year = date.getFullYear();
			let month = date.getMonth()+1;
			let dt = date.getDate();

			if (dt < 10) {
			  dt = '0' + dt;
			}
			if (month < 10) {
			  month = '0' + month;
			}

			return dt + '.' + month + '.' + year
		}
		return this.state.comments.map((item)=> {
			return (
				<div key={item._id}>
				<a href={"/ideas/show/" + item.IdeaId}  className="ideas__item" onClick={(e) => {e.preventDefault(); this.props.history.push("/ideas/show/" + item.IdeaId); }}>
                    {item.IdeaTitle}
				</a>
				<Comment comment={item}/>
				</div>
			);
		});
	}


	render() {
		return (
		<div>
			{ this.rows() }

		</div>
		);
	}

}

export default withRouter(withScrollHandler(CommentListContent, "comments"));
