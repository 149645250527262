import React from 'react';
import Header from './header.jsx'
// import XLSX from 'xlsx';
import XLSX from 'sheetjs-style';
import Modal from './modal.jsx'
import withDispatch from './withDispatch.jsx'

class Report extends React.Component {


    constructor() {
        super();
        this.state = {
            'data': null,
            'error_fields': null,
            'actions': null,
            'filters': null
        }
    }


    componentDidMount() {
        this.props.app.api('GET', '/api/report_info/' + this.props.report.id, {}, (data) => {
            this.setState({'actions': data.actions, 'filters': data.filters});

            $.initializeForm($('form#report'));
        })
    }    

    renderDate(field) {
        return (
            <div className="row" key={field.id}>
                <div className="col-md-4">
                    <div className="text-field-holder">
                        <label>{field.title}</label>
                        <input type="text" id={field.id} className="text-field hasDatepicker form-field" readOnly/>
                        <i className="ico icon-calendar"></i>
                    </div>
                </div>
            </div>

        )
    }

    updateConnected(field) {
        let currentValue = $("select#" + field).val();


        $("select[data-parent=" + field).each((idx, item) => {
            let data = "\x3coption value=''\x3eВсе\x3c/option\x3e";

            if (currentValue !== "") {
                let options = JSON.parse(item.getAttribute('data-options'));
                for (var i = 0; i < options.length; i++) {
                    if (options[i].parent === currentValue) {
                        data += "\x3coption value='" + options[i].id + "'\x3e" + options[i].title + '\x3c/option\x3e';
                    }
                }
            }
            // console.log(data);

            item.innerHTML = data;
            jcf.getInstance(item).refresh();
        });
    }

    renderSelect(field, emptyOption) {
        let clName = "select-field-holder text-active"
        if (this.state.error_fields) {
            if (this.state.error_fields.includes(field.id)) {
                clName += ' text-error'
            }
        }
        return (
            <div className={clName} key={field.id}>
                <label>{field.title}</label>
                <select className="custom-select form-field" id={field.id}
                        onChange={this.updateConnected.bind(this, field.id)}>
                    {emptyOption ? (<option value="">Все</option>) : null}
                    {field.params[0].map((opt) => <option value={opt.id} key={opt.id}>{opt.title}</option>)}
                </select>
            </div>
        );

    }

    renderSubselect(field) {
        let clName = "select-field-holder text-active"
        if (this.state.error_fields) {
            if (this.state.error_fields.includes(field.id)) {
                clName += ' text-error'
            }
        }

        return (
            <div className={clName} key={field.id}>
                <label>{field.title}</label>
                <select className="custom-select form-field" id={field.id}
                        onChange={this.updateConnected.bind(this, field.id)} data-parent={field.params[0]}
                        data-options={JSON.stringify(field.params[1])}>
                    <option value="">Все</option>
                </select>
            </div>
        );
    }

    renderCheckbox(field) {
        return (
            <div className="check-wrap" key={field.id}>
                <input type="checkbox" className="checkbox" id={field.id}/>
                <label htmlFor={field.id} className="checkbox-label">{field.title}</label>
            </div>
        );
    }

    renderFilters() {
        if (this.state.filters == null)
        {
            return null;
        }
        return this.state.filters.map((f) => {
            if (f.type == 'date') {
                return this.renderDate(f);
            } else if (f.type == 'select') {
                return this.renderSelect(f, true);
            } else if (f.type == 'select-no-empty') {
                return this.renderSelect(f, false);
            } else if (f.type == 'subselect') {
                return this.renderSubselect(f);
            } else if (f.type == 'checkbox') {
                return this.renderCheckbox(f);
            }
        });
    }

    renderHeader() {
        if (this.state.data.data.length == 0) {
            return null;
        }
        return this.state.data.data[0].map((cell, idx) => {
            return (
                <th className="col-table-2" key={idx}>{cell}</th>
            );
        })
    }

    openLink(link) {
        window.open(link, '_blank');
    }

    renderData() {
        return this.state.data.data.slice(1, this.state.data.data.length).map((row, idx) => {
            if (this.state.data.link_base) {
                return (
                    <tr style={{cursor: 'pointer'}} key={idx}
                        onClick={this.openLink.bind(this, this.state.data.link_base + row[this.state.data.link_column])}>
                        {row.map((cell, idx) => {
                            return <td key={idx}>{cell}</td>
                        })}
                    </tr>
                );

            } else {
                return (
                    <tr key={idx}>
                        {row.map((cell, idx) => {
                            return <td key={idx}>{cell}</td>
                        })}
                    </tr>
                );
            }
        })
    }

    renderTable() {
        if (this.state.data == null || this.state.data.data.length == 0) {
            return null;
        }

        let tableClass = 'table';
        if (this.state.data.link_base) {
            tableClass += ' table-hover'
        }
        return (
            <div className="table-responsive x-scrollable">
                <table className={tableClass}>
                    <thead>
                    <tr>
                        {this.renderHeader()}
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderData()}
                    </tbody>
                </table>
            </div>
        );


    }

    processAction(action_id) {

        let filter = {};
        for (var i = 0; i < this.state.filters.length; i++) {
            let f = this.state.filters[i];
            if (f.type == 'date') {
                let dt = $('#' + f.id).val();
                if (dt) {
                    filter[f.id] = dt;
                }
            } else if (f.type == 'checkbox') {
                filter[f.id] = $('#' + f.id).prop('checked');
            } else {
                filter[f.id] = $('#' + f.id).val();
            }
        }

        let req_data = new FormData();
        req_data.append('report', this.props.report.id);
        req_data.append('filter', JSON.stringify(filter));
        req_data.append('action', action_id);

        this.props.app.api_post('/api/reports', req_data, (data) => {
            if (data.action === 'xlsx') {
                let currentData = data.data;
                if (data.link_base && !isNaN(data.link_column)) {
                    let origin = location.origin;
                    currentData = currentData.map((line, i) => {
                        if( i < 2)
                        {
                            return line;
                        }
                        else if (i == 2) {
                            line[data.link_column] = data.link_title;
                            return line
                        } else {
                            line[data.link_column] = origin + data.link_base + line[data.link_column];
                            return line;
                        }
                    })
                }
                if(!(currentData[0] instanceof Array))
                {
                    for(var x = 0; x < currentData.length; x++)
                    {
                        currentData[x] = currentData[x]['item'];
                    }
                }
                var worksheet = XLSX.utils.aoa_to_sheet(currentData);

                if(data.style) {
                    for (var key in data.style.format) 
                    {
                        var hasLetter = key.search(/[A-Z]+/) != -1;
                        var hasNumber = key.search(/[0-9]+/) != -1;

                        if(hasLetter && hasNumber) {
                            var cell = worksheet[key];
                            if(cell)
                            {
                                cell.s = Object.assign({}, data.style.format[key], cell.s);
                            }

                        }
                        else if(hasLetter && !hasNumber) {
                            for(var r = 3; r <= data.data.length; r++) 
                            {
                                var cell = worksheet[key.concat(String(r))];
                                if(cell)
                                {
                                    cell.s = Object.assign({}, data.style.format[key], cell.s);
                                }
                            }
                        }
                        else if(!hasLetter && hasNumber) {
                            var r = parseInt(key);
                            for(var c = 1; c <= data.data[2].length; c++) {
                                var cellref = XLSX.utils.encode_cell({c:c-1, r:r-1});
                                var cell = worksheet[cellref];
                                if(cell)
                                {
                                    cell.s = Object.assign({}, data.style.format[key], cell.s);
                                }
                            }
                        }
                    }
                    for (var key in data.style.link)
                    {
                        var hasLetter = key.search(/[A-Z]+/) != -1;
                        var hasNumber = key.search(/[0-9]+/) != -1;

                        if(hasLetter && hasNumber) {
                            var cell = worksheet[key];
                            if(cell)
                            {
                                if(cell.v && cell.v != '')
                                    if(cell.v.includes(":href:"))
                                    {
                                        var v = cell.v.substring(cell.v.indexOf(":href:")+6, cell.v.length);
                                        v = v.replace("<origin>", location.origin);
                                        cell.l = { Target: v };
                                        cell.v = cell.v.substring(0, cell.v.indexOf(":href:"));
                                    }
                                    else { 
                                        cell.l = { Target: cell.v };
                                    }
                            }

                        }
                        else if(hasLetter && !hasNumber) {
                            for(var r = 3; r <= data.data.length; r++) 
                            {
                                var cell = worksheet[key.concat(String(r))];
                                if(cell)
                                {
                                    if(cell.v && cell.v != '')
                                        cell.l = { Target: cell.v };
                                }
                            }
                        }
                        else if(!hasLetter && hasNumber) {
                            var r = parseInt(key);
                            for(var c = 1; c <= data.data[2].length; c++) {
                                var cellref = XLSX.utils.encode_cell({c:c-1, r:r-1});
                                var cell = worksheet[cellref];
                                if(cell)
                                {
                                    if(cell.v && cell.v != '')
                                        cell.l = { Target: cell.v };
                                }
                            }
                        }                        
                    } 

                    if(data.style.width)
                        worksheet["!cols"] = data.style.width;
                }

                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, worksheet, "SheetJS");
                XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'base64'});
                XLSX.writeFile(wb, data.filename);
                this.setState({'data': null})
                this.props.dispatch({type: 'MODAL_DISMISS'})
            } else {
                if (data.action === 'json') {
                    this.setState({'data': data})
                    this.props.dispatch({type: 'MODAL_DISMISS'})
                } else {
                    if (data.action === 'error') {
                        this.setState({'data': null, 'error_fields': data.fields})
                        this.props.dispatch({
                            type: 'MODAL_SHOW',
                            modal: {
                                message: ['Произошла ошибка при создании отчета:', data.message]
                            },
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        });

                    }

                }
            }
        }, () => {
            this.setState({'data': null})
            this.props.dispatch({
                type: 'MODAL_SHOW',
                modal: {
                    message: ['Произошла ошибка при создании отчета'],
                    buttons: [
                        {name: 'ok', title: 'OK', default: true}
                    ]
                },
            });
        });
        this.setState({'data': null})
        this.props.dispatch({
            type: 'MODAL_SHOW',
            modal: {
                message: ['Подождите, идёт обработка'],
                hideDismiss: true
            }
        })
    }

    renderActions() {
        if (this.state.actions == null)
        {
            return null;
        }

        return this.state.actions.map((action) => {
            return (
                <a key={action.id} onClick={this.processAction.bind(this, action.id)}
                   className="btn btn--black">{action.title}</a>
            );

        })
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-7">
                    <form action="" id="report">
                        {this.renderFilters()}
                        <div className="btn-group">
                            {this.renderActions()}
                        </div>
                    </form>
                </div>
                {this.renderTable()}
            </div>

        );

    }

}

export default withDispatch(Report);
