import React from 'react';
import Header from './header.jsx';
import PracticeRouteAssign from './practice_routes_assign.jsx';
import PracticeScaleAssign from './practice_scale_assign.jsx';
import PracticeScaleRouteAssign from './practice_scale_routes_assign.jsx';
import ResponsibleAssign from './responsible_assign.jsx';
import PracticeStatusAssign from './practice_status_assign.jsx';
import PracticeScaleComplete from './practice_scale_complete.jsx';
import Comments from './comments.jsx';
import Attachments from './attachments.jsx';
import Markup from './markup.jsx';
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
import Like from './like.jsx';
import Points from './points.jsx';
import {Link} from 'react-router-dom';
import Workflow from './workflow.jsx';
import ScaleProgress from './scale_progress.jsx';

class Practice extends React.Component {
    constructor() {
        super();
        this.state = {
            'object': null,
            'action': null,
            'text': false,
            'ts': null,
            'routes': false,
            'scale_routes': false,
            'responsible': false,
            'scale_responsible': false,
            'scale': false,
            'show_scale_stats': false
        }
    }

    loadObject(props) {
        props.app.api('GET', '/api/practices/' + props.match.params.id, {}, (data) => {
            this.setState({'object': data, 'ts': new Date().getTime()});
        }, () => {
            console.log('error')
        })
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        this.loadObject(new_props);
    }

    componentDidMount() {
        $.initializeForm($('.comments__item comment-add'));
    }

    actionById(actionId) {
        let action = this.state.object.Actions2.filter((item) => {
            return item.id == actionId
        });
        if (action.length == 0) {
            return null;
        }
        return action[0];
    }

    processAction(actionId) {
        let action = this.actionById(actionId);

        console.log('Press: ')
        console.log(action)

        if (action.id === 'routes') {
            this.setState({'routes': true})
            return
        }
        if (action.id === 'scale_routes') {
            this.setState({'scale_routes': true})
            return
        }

        if (action.id === 'responsible') {
            this.setState({'responsible': true})
            return
        }
        if (action.id === 'scale_responsible') {
            this.setState({'scale_responsible': true})
            return
        }

        if (action.id === 'scale') {
            this.setState({'scales': true})
            return
        }

        if (action.id === 'accept_for_review') {
            this.setState({'accept_for_review': true})
            return
        }

        // if (action.id.startsWith('make_')) {
        //     let self = this;
        //     console.log(action.id)
        //     this.props.app.api_post_json('/api/practices/' + this.state.object._id, {
        //         'Action': action.id
        //     }, () => {
        //         self.loadObject(self.props);
        //     }, (e) => {
        //         console.log(e)
        //     });
        //     return
        // }

        if (action.id === "consider") {
            this.savePracticeStatus(action.id, "", false)
            return;
        }
        if (action.id === "scale_worked") {
            this.savePracticeStatus(action.id, "", false)
            return;
        }
        if (action.id === "scale_complete") {
            this.setState({'action': action})
            this.setState({'scale_complete': true})
            return;
        }


        if (action != null) {
            this.setState({'action': action})
            this.setState({'text': true})
        }
    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    sendComment(response) {
        let text = $('#comment_text').val();
        if (text == "") {
            $('#comment_text').parent().toggleClass('text-error', true);
            return;
        }
        if (response) {
            text = response + text;
        }
        let data = new FormData();
        data.append('practice_id', this.state.object._id);
        data.append('text', text);
        $('form#comment_form img.file-image').each((idx, elem) => {
            let filedata = JSON.parse(elem.attributes.data.value);
            data.append('file', this.dataURItoBlob(filedata.data), filedata.name);
        });
        let self = this;
        this.props.app.api_post('/api/practice_comment', data, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);

        }, (data) => {
            if (data.responseJSON.message) {
                self.props.dispatch({
                    type: 'MODAL_SHOW',
                    modal: {
                        header: 'Ошибка отправки',
                        message: [data.responseJSON.message],
                        buttons: [
                            {name: 'ok', title: 'OK', default: true}
                        ]
                    }
                })
            }
        });
    }

    toggleFavorite() {
        let self = this;
        let method = this.state.object.Favorite ? 'DELETE' : 'POST';
        this.props.app.api(method, '/api/practices/' + this.state.object._id + '/fav', {}, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);
        })
    }

    renderFavoriteButton() {
        return (
            <a key="favorite" className="edit-button" onClick={(e) => {
                e.preventDefault();
                this.toggleFavorite();
            }}>
                {this.state.object.Favorite ?
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star_a.png" alt=''/> :
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star.png" alt=''/>}
            </a>
        )
    }

    renderEditButton() {
        if (this.state.object.CanEdit) {
            return (
                <a key="edit" className='edit-button' onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/practices/edit/" + this.state.object._id);
                }}>
                    <img src="/static/img/edit.svg" className='edit-image' alt=''/>
                </a>
            )
        }
    }


    renderAssignAction() {
        let group_btn = [];
        let scale_group_btn = [];
        let status_group_btn = []
        let approve_group_btn = [];
        let link = this.state.object.Actions2.map((action) => {
            const respondBtns = ["accept", "make_worked", "make_postponed", "accept", "work", "postpone"]
            const approveBtns = ["scale", "accept_for_review"]
            let item = (
                <a key={action.id} onClick={this.processAction.bind(this, action.id, null)}
                   className="btn btn--black">{action.title} <Points action={'practice_'+action.id} app={this.props.app}/></a>
            );
            if (action.id === 'responsible' || action.id === 'routes') {
                group_btn.push(item)
            } else if (action.id === 'scale_responsible' || action.id === 'scale_routes') {
                scale_group_btn.push(item)
            } else if (respondBtns.includes(action.id)) {
                if (action.id.includes('accept')) {
                    item = (
                        <a key={action.id} style={{fontSize: '12px'}}
                           onClick={this.processAction.bind(this, action.id, null)}
                           className="btn btn--black">{action.title} <Points action={'practice_'+action.id} app={this.props.app}/></a>
                    );
                }
                status_group_btn.push(item)
            } else if (approveBtns.includes(action.id)) {
                approve_group_btn.push(item)
            } else {
                return item
            }
        });

        if (group_btn.length > 0 || status_group_btn.length > 0 || approve_group_btn.length > 0) {
            return (
                <div className='btn-group'>
                    {link}
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={group_btn.length === 0 ? {display: 'none'} : {}} id="route">Назначить</a>
                        <div className="dropdown-content" id="route-content">
                            {group_btn}
                        </div>
                    </div>
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={status_group_btn.length === 0 ? {display: 'none'} : {}}  id="respond">Ответить</a>
                        <div className="dropdown-content" id="respond-content">
                            {status_group_btn}
                        </div>
                    </div>
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={approve_group_btn.length === 0 ? {display: 'none'} : {}}  id="approve">Утвердить</a>
                        <div className="dropdown-content" id="approve-content">
                            {approve_group_btn}
                        </div>
                    </div>

                </div>
            )
        } else if (scale_group_btn.length > 0) {
                return (
                    <div className='btn-group'>
                        {link}
                        <div className="dropdown">
                            <a className="btn btn--black"
                               style={scale_group_btn.length === 0 ? {display: 'none'} : {}} id="route">Назначить для масштабирования</a>
                            <div className="dropdown-content" id="route-content">
                                {scale_group_btn}
                            </div>
                        </div>
                    </div>
                )
        } else {
            return (
                <div className='btn-group'>
                    {link}
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={status_group_btn.length === 0 ? {display: 'none'} : {}} id="respond">Ответить</a>
                        <div className="dropdown-content">
                            {status_group_btn}
                        </div>
                    </div>
                </div>
            )
        }

    }

    savePracticeStatus(actionId, text, fromWttFlag) {
        let self = this;

        let data = new FormData();
        data.append('practice_id', this.state.object._id);
        data.append('action', actionId);
        data.append('from_wtt', fromWttFlag)
        data.append('text', text)
        console.log(data)

        this.props.app.api_post('/api/practice_comment', data, (data) => {
            self.loadObject(self.props);
            self.setState({'action': null, 'text': false});
        }, (e) => {
            console.log(e)
        });
    }

    savePracticeStatusScaleComplete(actionId, text, formData) {
        let self = this;

        let data = formData;
        data.append('practice_id', this.state.object._id);
        data.append('action', actionId);
        data.append('text', text)
        console.log(data);

        this.props.app.api_post('/api/practice_comment', data, (data) => {
            self.loadObject(self.props);
            self.setState({'action': null, 'scale_complete': false});
        }, (e) => {
            console.log(e)
        });
    }    

    saveRoutes(routes) {
        let self = this;
        console.log(routes)
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {
            'Routes': routes
        }, () => {
            self.loadObject(self.props);
            self.setState({'routes': false});
        }, (e) => {
            console.log(e)
        });
    }
    saveScaleRoutes(routes) {
        let self = this;
        console.log(routes)
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {
            'ScaleRoutes': routes
        }, () => {
            self.loadObject(self.props);
            self.setState({'scale_routes': false});
        }, (e) => {
            console.log(e)
        });
    }

    saveResponsible(responsible) {
        let self = this;
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {'Responsible': responsible}, () => {
            self.loadObject(self.props);
            self.setState({'responsible': false});
        }, (e) => {
            console.log(e)
        });
    }
    saveScaleResponsible(responsible) {
        let self = this;
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {'ScaleResponsible': responsible}, () => {
            self.loadObject(self.props);
            self.setState({'scale_responsible': false});
        }, (e) => {
            console.log(e)
        });
    }

    saveScale(division, comment) {
        let self = this;
        console.log(division);
        console.log(comment);
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {
            'Scales': division,
            'comment': comment
        }, () => {
            self.loadObject(self.props);
            self.setState({'scales': false});
        }, (e) => {
            console.log(e)
        });
    }
    saveScaleReview(division, comment) {
        let self = this;
        console.log(division);
        console.log(comment);
        this.props.app.api_post_json('/api/practices/' + this.state.object._id, {
            'ScalesReview': division,
            'comment': comment
        }, () => {
            self.loadObject(self.props);
            self.setState({'accept_for_review': false});
        }, (e) => {
            console.log(e)
        });
    }
    
    renderPracticeStatusModal() {
        if (this.state.text) {
            return <PracticeStatusAssign app={this.props.app}
                                     action={this.state.action}
                                     onSave={this.savePracticeStatus.bind(this)}
                                     onClose={() => this.setState({'action': null, 'text': false})}/>;
        }
    }

    // Назначить для функцию для принятия решения о масштабировании
    renderRouteModal() {
        if (this.state.routes) {
            return <PracticeRouteAssign app={this.props.app}
                                routes={this.state.object.Routes}
                                onSave={this.saveRoutes.bind(this)}
                                onClose={() => this.setState({'routes': false})}
                            />;
        }
    }
    // Назначить на функцию для реализации практики
    renderScaleRouteModal() {
        if (this.state.scale_routes) {
            console.log(this.state.object.Division);
            console.log(this.state.object.ScaleRoutes);
            return <PracticeScaleRouteAssign app={this.props.app}
                                region={this.state.object.Region}
                                division={this.state.object.Division}
                                routes={this.state.object.ScaleRoutes}
                                onSave={this.saveScaleRoutes.bind(this)}
                                onClose={() => this.setState({'scale_routes': false})}
                            />;
        }
    }

    renderResponsibleModal() {
        if (this.state.responsible) {
            return <ResponsibleAssign app={this.props.app}
                                      responsible={this.state.object.Responsible}
                                      onSave={this.saveResponsible.bind(this)}
                                      onClose={() => this.setState({'responsible': false})}/>;
        }
    }
    renderScaleResponsibleModal() {
        if (this.state.scale_responsible) {
            return <ResponsibleAssign app={this.props.app}
                                      responsible={this.state.object.ScaleResponsible}
                                      onSave={this.saveScaleResponsible.bind(this)}
                                      onClose={() => this.setState({'scale_responsible': false})}/>;
        }
    }
    
    // назначить подразделение для которого применимо масштабирование
    renderScaleModal() {
        if (this.state.scales) {
            return <PracticeScaleAssign app={this.props.app}
                                division={this.state.object.Division}
                                review={false}
                                onSave={this.saveScale.bind(this)}
                                onClose={() => this.setState({'scales': false})}
                            />;
        }
    }

    // назначить подразделение для которого применимо масштабирование
    renderScaleReviewModal() {
        if (this.state.accept_for_review) {
            return <PracticeScaleAssign app={this.props.app}
                                division={this.state.object.Division}
                                review={true}
                                onSave={this.saveScaleReview.bind(this)}
                                onClose={() => this.setState({'accept_for_review': false})}
                            />;
        }
    }

    // масштабирование реализовано
    renderScaleCompleteModal() {
        if (this.state.scale_complete) {
            return <PracticeScaleComplete app={this.props.app}
                                     action={this.state.action}
                                     onSave={this.savePracticeStatusScaleComplete.bind(this)}
                                     onClose={() => this.setState({'action': null, 'scale_complete': false})}/>;
                            
        }
    }    

    renderMap() {
        if (this.state.object.MapImage) {
            return (
                <img width="100%" src={this.state.object.MapImage}/>
            )
        }
    }

    sendLike(direction) {
        this.props.app.api('POST', '/api/practices/' + this.props.match.params.id + '/like/' + direction, {}, (data) => {
            this.setState({'object': data, 'ts': new Date().getTime()});
        }, () => {
            console.log('error')
        })
    }

    renderLike() {
        return (<div className="likes_new">
                <Like kind="up" value={this.state.object.Likes.up} disabled={this.state.object.Likes.current_count <= 0}
                      allowClick={!this.state.object.Likes.on} onClick={this.sendLike.bind(this, 'up')}/>
                <Like kind="down" value={this.state.object.Likes.down}
                      disabled={this.state.object.Likes.current_count >= 0} allowClick={!this.state.object.Likes.on}
                      onClick={this.sendLike.bind(this, 'down')}/>
            </div>
        )

    }

    renderAuthor() {
        return [this.state.object.Author].concat(this.state.object.Coauthors).map((x) => {
            return (
                <Link style={{marginRight: '10px'}} key={x._id} to={"/user/" + x._id}>{x.Name}</Link>
            )
        });
    }
    renderWorkflow() {
        if (this.state.object.Workflow) {
            return <Workflow app={this.props.app}
                                     workflow={this.state.object.Workflow}/>;
        }
    }
    

    showScaleStats(range) {
        // console.log(range);
        if(range == 'МР')
            this.setState({'show_scale_stats_mr': true})
        else
        this.setState({'show_scale_stats_rf': true})
    }
    hideScaleStats() {
        this.setState({'show_scale_stats_mr': false})
        this.setState({'show_scale_stats_rf': false})
    }

    renderScalesProgress() {
        // console.log(this.props.app.user_data());

        if (this.state.object.PracticeSubstatus) {
            return <ScaleProgress app={this.props.app}
                                     substatuses={this.state.object.PracticeSubstatus}
                                     regions={this.props.app.storage.regions}
                                     teams={this.props.app.storage.teams}
                                     progressOnly={true}
                                     onClick={this.showScaleStats.bind(this)}
                                     />;
        }
    }

    renderScalesStats() {
        // console.log(this.props.app.user_data());

        if (this.state.show_scale_stats_mr) {
            return <ScaleProgress app={this.props.app}
                                     substatuses={this.state.object.PracticeSubstatus}
                                     regions={this.props.app.storage.regions}
                                     teams={this.props.app.storage.teams}
                                     progressOnly={false}
                                     statRange={'mr'}
                                     onClose={this.hideScaleStats.bind(this)}
                                     />;
        }
        else if (this.state.show_scale_stats_rf) {
            return <ScaleProgress app={this.props.app}
                                     substatuses={this.state.object.PracticeSubstatus}
                                     regions={this.props.app.storage.regions}
                                     teams={this.props.app.storage.teams}
                                     progressOnly={false}
                                     statRange={'rf'}
                                     onClose={this.hideScaleStats.bind(this)}
                                     />;
        }
    }

    renderButtons() {
        return (
            <div className="btn-group">
                {this.renderAssignAction()}
            </div>
        )
    }

    renderPractice() {
        if (this.state.object == null) {
            return null;
        } else {
            let formatDate = (iso_date) => {
                let date = new Date(iso_date);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();

                if (dt < 10) {
                    dt = '0' + dt;
                }
                if (month < 10) {
                    month = '0' + month;
                }

                return dt + '.' + month + '.' + year
            }
            return (
                <div className="container">
                    <ol className="breadcrumb">
                        <li><Link to="/practices">Практики</Link></li>
                        <li><a href="#">{this.state.object.Title}</a></li>
                    </ol>
                    <div className="page__head">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-with-likes">
                                    <h1 className="page__head-title">{this.state.object.Title}</h1>
                                    <div className="button_box">
                                        {this.renderEditButton()}
                                        {this.renderFavoriteButton()}
                                    </div>
                                </div>

                                <div className="page__head-desc">{this.state.object.PracticeStatus}</div>
                            </div>
                        </div>
                    </div>
                    <div className="idea__meta">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="idea__meta-item">
                                        <span>Год практики:</span> {this.state.object.Year}</div>
                                <div className="idea__meta-item"><span>Автор:</span> {this.renderAuthor()}</div>
                                {/* <div className="idea__meta-item"><span>Категория: </span>
                                    <a href="#">{this.state.object.Route.Title}</a></div> */}
                                {/* <div className="idea__meta-item"><span>Тип:</span> {this.state.object.PracticeTypeTitle}
                                </div> */}
                                <div className="idea__meta-item"><span>Регион:</span> {this.state.object.Region.Title}
                                </div>
                                <div className="idea__meta-item"><span>Подразделение:</span> {this.state.object.Division.Title}
                                </div>
                                <div className="idea__meta-item">
                                    <span>Создана:</span> {formatDate(this.state.object.Created)}</div>
                                <div className="idea__meta-item">
                                    <span>Изменена:</span> {formatDate(this.state.object.Modified)}</div>
                            </div>
                            <div className="col-md-6">
                                {this.renderScalesProgress()}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="idea__meta-item idea__meta-map">
                                    {this.renderMap()}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <h2>Практика предполагает дополнительные затраты</h2>
                    <Markup className="pgr">{this.state.object.Budget}</Markup>

                    <h2>Бизнес-ситуация/предпосылки для реализации/проблема которую решит практика</h2>
                    <Markup className="pgr">{this.state.object.Preconditions}</Markup>
                    <h2>Цели, которые позволяет достичь практика</h2>
                    <Markup className="pgr">{this.state.object.Goals}</Markup>
                    <h2>KPI, на которые влияет практика</h2>
                    <Markup className="pgr">{this.state.object.KPIs}</Markup>
                    <h2>Механика практики/какой использовался бюджет/ общие затраты на привлечение или удержание абонента (SAC)</h2>
                    <Markup className="pgr">{this.state.object.Mechanics}</Markup>
                    <h2>Результаты практики</h2>
                    <Markup className="pgr">{this.state.object.Results}</Markup>
                    {/* {this.renderSimilarPractices()} */}
                    <div className="idea__imgs">
                        <Attachments attachments={this.state.object.Attachments}/>
                    </div>
                    {this.renderButtons()}
                    {this.renderWorkflow()}
                    {this.renderLike()}
                    <Comments object={this.state.object} onSendComment={this.sendComment.bind(this)} app={this.props.app} />
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">Лучшие практики</div>
                    </div>
                </header>
                <section className="wrapper">
                    {this.renderPractice()}
                </section>
                {this.renderPracticeStatusModal()}
                {this.renderRouteModal()}
                {this.renderResponsibleModal()}
                {this.renderScaleRouteModal()}
                {this.renderScaleResponsibleModal()}
                {this.renderScaleModal()}
                {this.renderScaleReviewModal()}
                {this.renderScalesStats()}
                {this.renderScaleCompleteModal()}
            </div>
        );
    }
}

export default withDispatch(withFiles(Practice));