import React from 'react';
import Attachments from './attachments.jsx';
import Markup from './markup.jsx';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

class Comment extends React.Component {

	render() {
		if (this.props.comment == null) {
			return null;
		}
    let comment = this.props.comment;
			let role = '';
			let cls = 'comments__item';
			let span_class = '';
			if (comment.Role == 'author') {
					role = 'Автор '
					cls += ' author'

			} else if (comment.Role == 'moderator_cf') {
					role = 'Модератор ЦФ '
					cls += ' moderator'
					span_class = 'moderator_comment_cf'
			}
			else if (comment.Role == 'moderator_mr') {
					role = 'Модератор МР '
					cls += ' moderator'
					span_class = 'moderator_comment_mr'
			}
			// else if (comment.Role == 'moderator') {
			// 		role = 'Модератор '
			// 		cls += ' moderator'
			// 		span_class = 'moderator_comment'
			// }
			return (
				<div className={cls} key={comment.ID}>
				<div className="comments__item-ava"><i className="ico icon-user-drop"/></div>
				<div className="comments__item-content">
					<div className="comments__item-username"><Link to={"/user/" + comment.AuthorId}>{comment.AuthorName}</Link></div>
					<div className="comments__item-data"><span className={span_class}>{role}</span><span className='comment_date'>{(new Date(comment.Created)).toLocaleString()}</span></div>
					<Markup className="comments__item-text">{comment.Text}</Markup>
					<div className="comment_response" onClick={this.onResponse.bind(this)}>Ответить</div>
				</div>
				<div className="idea__imgs">
						<Attachments attachments={comment.Attachments} onShowFile={this.props.onShowFile}/>
				</div>
				</div>
			);
	}

	onResponse() {
			this.props.setResponse(this.props.comment.AuthorId, this.props.comment.AuthorName);
	}
}

const mapDispatchToProps = dispatch => {
  return {
			setResponse: (user_id, user_name) => { dispatch({type:'SET_RESPONSE', response: {id: user_id, name: user_name}})}
	}
}

export default connect(()=> {return {}}, mapDispatchToProps)(Comment);
