import React from 'react';
import Modal from './modal.jsx';

class PracticeScaleRouteAssign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'region': {...props.region},
            'division': {...props.division},
            'routes': [...props.routes, {
                function_title: '',
                can_delete: true
            }]
        }

    }

    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));
    }

    functionOptions(region_id, division) {
        // console.log(this.props.app.storage.user_functions);
        // console.log(region_id);
        // console.log(division);
        if (!region_id)
            return


        return this.props.app.storage.user_functions[region_id].map((func, idx) => {
            // if(func.Title.includes(division))
                return (
                    <option key={idx} value={func.ID}>{func.Title}</option>
                );
        });
    }


    functionChange(target) {
        let routes = this.state.routes;
        routes[0].function_id = $(target).val();
        routes[0].function_title = $(target).find('option:selected').text();
        this.setState({'routes': routes})
    }


    saveRoutes() {
        this.props.onSave(this.state.routes.filter((elem) => !elem.removed))
    }

    removeRoute(idx) {
        let routes = this.state.routes;
        routes[idx].removed = true;
        this.setState({'routes': routes});
    }

  
    render() {
        if (this.state.routes == null) {
            return null;
        }
        // console.log(this.state.division)
        if (this.state.division == null) {
            return null;
        }
        if (this.state.region == null) {
            return null;
        }
        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        Назначить отдел для реализации практики
                    </div>
                    <div className="select-route-table">
                        <div key='route0' className="select-route-row">
                            <div className="select-route-cell">
                                <div className="select-field-holder text-active">
                                    <label>Функции:</label>
                                    <select className="route-select custom-select"
                                            onChange={(e) => this.functionChange(e.target)}>
                                        <option/>
                                        {this.functionOptions(this.state.region.ID, this.state.division.Title)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveRoutes.bind(this)}>Сохранить</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default PracticeScaleRouteAssign;
