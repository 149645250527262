import React from 'react';
import Header from './header.jsx'
import withDispatch from './withDispatch.jsx'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import RatingPlaces from './rating_places.jsx';

let monthes = ['январь', 'февраль','март','апрель','май','июнь','июль','август','сентябрь','октябрь','ноябрь','декабрь']

let formatMonth = (month) => {
    if (month < 10) {
        return "0" + month.toString();
    } else {
        return month.toString();
    }
}

let formatPeriod = (month, year) => {
  if (month == 10) {
      return 'IV квартал ' + year;
  } else if (month == 7) {
      return 'III квартал ' + year;
  } else if (month == 4) {
      return 'II квартал ' + year;
  } else if (month == 1) {
      return 'I квартал ' + year;
  }
}

let getQ = (month) => {
    if (month >= 10) {
        return 10;
    } else if (month >= 7) {
        return 7;
    } else if (month >= 4) {
        return 4;
    } else {
        return 1;
    }
 }



class CityRatings extends React.Component {

    constructor() {
      super();
      this.state = {
          rating: {},
          month: getQ((new Date()).getMonth() + 1),
          year: (new Date()).getYear() + 1900,
          data: []
      }
    }

    buildState(props) {
      let state = {
        rating: {},
        month: getQ((new Date()).getMonth() + 1),
        year: (new Date()).getYear() + 1900
      }
      if (props.match.params.city) {
          state.rating = props.app.storage.teams.filter((item) => item._id == props.match.params.city)[0];
      } else {
          state.rating = {}
      }
      if (props.match.params.period) {
        let period = props.match.params.period.split("-")
        state.year = parseInt(period[0])
        state.month = parseInt(period[1]);
      }
      this.setState(state)
      this.loadObject(state)
    }


    componentWillMount() {
      this.buildState(this.props)
    }
    componentDidMount() {
        $.initializeForm($('.container'));
        //this.loadObject();
    }
    componentWillReceiveProps(new_props) {
      this.buildState(new_props)
    }

    loadObject(state) {
        if (state.rating._id) {
          this.props.app.api('GET', '/api/city_rating/'+state.rating._id, {date: state.year + "-" + formatMonth(state.month) + '-01'}, (data) => {this.setState({data: data})})
        } else {
          this.props.app.api('GET', '/api/city_rating', {date: state.year + "-" + formatMonth(state.month) + '-01'}, (data) => {this.setState({data: data})})
        }
    }

    title() {
        let name = '';
        if (this.state.rating.name) {
            name = this.state.rating.name
        } else {
            name = 'Рейтинг городов'
        }
        return name + " " + formatPeriod(this.state.month, this.state.year);
    }

    nextMonth() {
        let now = ((new Date).getYear() + 1900).toString() + "-" + formatMonth(getQ((new Date).getMonth() + 1))
        let period = this.state.year.toString() + "-" + formatMonth(this.state.month);
        if (period >= now) {
            return null;
        }
        else {
            let nextM = this.state.month + 3;
            let nextY = this.state.year;
            if (nextM > 12) {
                nextY++;
                nextM = nextM - 12;
            }
            let nextPeriod = nextY.toString() + "-" + formatMonth(nextM);
            return (
              <li>
                <Link to={"/city_ratings/"+nextPeriod+(this.state.rating._id ? "/" + this.state.rating._id : '')}>{formatPeriod(nextM, nextY)}</Link>
              </li>
            )

        }
    }

    prevMonth() {
      let prevM = this.state.month - 3;
      let prevY = this.state.year;
      if (prevM < 1) {
          prevM = prevM + 12;
          prevY--;
      }
      let period = prevY.toString() + "-" + formatMonth(prevM)
      return (
        <li>
          <Link to={"/city_ratings/"+period + (this.state.rating._id ? "/" + this.state.rating._id : '')  }>{formatPeriod(prevM, prevY)}</Link>
        </li>
      )
    }

    changeRating(rating) {
        console.log(rating);
        this.props.history.push("/city_ratings/" + this.state.year + "-"+ formatMonth(this.state.month) + "/" + rating);
    }

    renderRatingOptions() {
        return this.props.app.storage.ratings.map( (item) => {
            return (<option key={item.ID} value={item.ID}>{item.Title}</option>)
        });
    }

    renderData() {
      if (this.state.data.rating) {
        let places = this.state.data.places.map((elem, idx, arr)=> {
            return arr.slice(0, idx + 1).reduce((accumulator, currentValue) => accumulator + currentValue);
        });
        let backgrounds = ["#ffd700", "#c0c0c0", "#cd7f32"];

        let winStyle = (idx) => {
            for (let i = 0; i< places.length; i++) {
                if (idx <= places[i]) {
                  return {
                    background: backgrounds[i],
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    display: 'block',
                    textAlign: 'center',
                    lineHeight: '30px'
                  }
                }
            }
              return {
                width: '30px',
                height: '30px',
                display: 'block',
                textAlign: 'center',
                lineHeight: '30px'
              }
        }
            return this.state.data.rating.map((item, idx) => {
              return (
                  <div key={item.ID} onClick={() => this.changeRating(item.ID)}>
                      <div ><span style={winStyle(idx+1)}>{idx + 1}</span></div>
                      <div> {item.Name}</div>
                      <div> {Math.round(item.Points*100)/100}</div>
                  </div>
              )
            });
      } else {

      }
    }

    render() {
      return (
      <div>
        <header>
          <Header app={this.props.app}/>
          <div className="header__title">
            <div className="container">
            Рейтинг постройки города
          </div>
          </div>
        </header>
        <section className="wrapper">
        <div className="current-section-menu">
          <div className="current-section-menu-inner">
            <div className="section-menu-line">
                	<div className="container">


                  <ul>
                    { this.prevMonth() }
                    { this.nextMonth() }
                  </ul>
                  </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="page__head">
            <div className="row">
                <h1>{this.title()}</h1>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row">
            <RatingPlaces places={this.state.data.places} rewards={this.state.data.rewards}/>
        </div>

        <div className="table-responsive">
                  <div className={this.state.rating._id ? "table" : "table table-hover"}>
                      <div>
                          <div>
                              <div className="col-table-2">Место</div>
                              <div className="col-table-4">
                                { this.state.rating._id ? 'Пользователь' : 'Команда'}
                              </div>
                              <div className="col-table-2">Очки</div>
                          </div>
                      </div>
                      <div>
                          { this.renderData() }
                      </div>
                  </div>

               </div>
</div>
        </section>

      </div>);
    }
}

export default CityRatings;
