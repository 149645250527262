import React from 'react';
import Header from './header.jsx'
import MarketHelp from './market_help.jsx';
import { Route, Redirect } from 'react-router'
import {withRouter} from 'react-router-dom'
import { Link } from 'react-router-dom';
import MarketPrize from './market_prize.jsx'
import withDispatch from './withDispatch.jsx'


class MarketIndex extends React.Component {

  constructor() {
    super();
    this.state = {
      list: []
    }
  }


  componentDidMount() {
    let path = this.props.kind == 'my' ? '/api/market/purchases' : '/api/market/prizes';
  	this.props.app.api('GET', path, {}, (data) => {
      let compare = (a, b) => {
            if (a.CanBuy !== b.CanBuy) {
                return a.CanBuy ? -1 : 1
            } else {
                return a.ID < b.ID ? -1 : 1
            }
      }


  		this.setState({'list': data.sort(compare)})

  	})
  }


  renderItems() {
      if (this.props.kind == 'my' && this.state.list.length == 0) {
          return (
              <div className="no_prizes">
                    <div className="caption">У вас пока нет призов</div>
                    <div className="hint">Покупайте призы в специальном разделе в обмен на ценные ресурсы</div>
                    <Link to="/market" className="prize_button">В лавку призов</Link>
              </div>
          )
      }

      return this.state.list.map((item)=> {
          let action = this.props.kind == 'my' ? 'details' : (item.CanBuy ? 'buy' : 'no-funds');
          return <MarketPrize key={item.ID} kind={this.props.kind} prize={item} action={action} onActionClick={this.actionClick.bind(this)}/>

      })
  }

  buyItem(item) {
      this.props.dispatch({type: 'MODAL_DISMISS'});
      this.props.app.api('POST', '/api/market/purchase/' + item.ID, {}, (data) => {
          this.props.history.push("/prizes/" + data.purchase);
      })
  }

  actionClick(item, action) {
    if (action == "buy") {
        this.props.dispatch({
            type: 'MODAL_SHOW',
            modal: {
                header: 'Подтвердите покупку приза',
                message: [item.Name],
                buttons: [{name: 'buy', title: 'Купить', default: true, onClick: () => this.buyItem(item)}, {name: 'price', title: item.Cost, price: true}]
            }
        })
    } else if (action == 'details') {
        this.props.history.push("/prizes/" + item.ID)
    }
  }

  render() {

    return (
        <div>
          <header>
            <Header app={this.props.app}/>
            <div className="header__title">
              <div className="container">
                {this.props.kind == 'my' ? 'Мои призы' : 'Каталог призов'}
              </div>
            </div>
          </header>
          <section className="wrapper">
            <div className="container">
                <MarketHelp/>
                { this.renderItems() }
            </div>
          </section>
        </div>

    );
}


}


export default withDispatch(withRouter(MarketIndex));
