import React from 'react';

class SelectBox extends React.Component {

  constructor() {
    super();
    this.state = {
        open: false,
        hint: ''
    }
  }

  renderOptions() {
      if (this.state.hint == undefined || this.state.hint == '') {
        return Object.entries(this.props.options).filter((value) => value[1].toLowerCase()).map((value)=>(
          <div key={value[0]} className="select-option" onClick={()=> {this.setState({open: false, hint: ''}); this.props.onChange(value[0])}}>{value[1]}</div>
      ));
      }
      let substr  = this.state.hint.toLowerCase();
      return Object.entries(this.props.options).filter((value) => value[1].toLowerCase().includes(substr)).map((value)=>(
          <div key={value[0]} className="select-option" onClick={()=> {this.setState({open: false, hint: ''}); this.props.onChange(value[0])}}>{value[1]}</div>
      ));
  }

  render() {

      if (this.state.open) {
          return (<div className="select-input">
            <input className="text-field" defaultValue={this.state.hint} onChange={(e)=> { e.preventDefault(); this.setState({hint: e.target.value}); }}
              onBlur={(e)=>{
                // console.log('onBlur');
                setTimeout(() => { this.setState({open: false}); }, 150);                
              }}
           
            />
            <div className="select-options-box">
                  {this.renderOptions()}
            </div>
          </div>);
      } else {
        // console.log(this.props.defaultValue);
        
        return (<div>
            <input className="text-field" readOnly value={this.props.options[this.props.defaultValue]} 
            onClick={
               () => {
                // console.log('onClick');
                this.setState({open: true}); //, hint: this.props.options[this.props.defaultValue]
              }}
              />

        </div>);
      }
  }


}

export default SelectBox;
