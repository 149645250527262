import React from 'react';
import Header from './header.jsx'
import {withRouter} from 'react-router-dom'
import UploadFile from './upload_file.jsx'

import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
// import XLSX from 'xlsx';
import XLSX from 'sheetjs-style';

import TestsSurveyCreateForm from './tests_survey_create_form.jsx';
import Select from './select.jsx';

import RouteTestAssign from './routes_test_assign.jsx';
import ResponsibleTestAssign from './responsible_test_assign.jsx';
import Points from './points.jsx';


class TestForm extends React.Component {

    constructor() {
        super();
        this.state = {
            'object': {'Survey':[], 'Routes':[], 'Responsibles':[], 'confidential': false}, 
            'survey': false,
            'assignResponsibles': false,
            'assignRoutes': false,
            'userList':null
        }
    }

	componentDidMount() {
        $.initializeForm($('form#test'));
	}

    routesOptions() {
        if(!this.props.app.storage.user_functions_plain)
            return
        return this.props.app.storage.user_functions_plain.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )

        })

    }

	responsibleChange(responsible) {
		console.log(responsible);
		// this.setState({'responsible': responsible })
	}


    getFormattedDate(ts) {
        var date = new Date(ts * 1000);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

        return [day + "." + month + "." + date.getFullYear(), hour + ":" + min];
    }

    loadObject(props) {
        this.props.setFiles('test_form', []);
        if (props.match.params.id) {
            props.app.api('GET', '/api/tests/' + props.match.params.id, {}, (data) => {
                //this.setState({'object': data, 'ts': new Date().getTime()});
                console.log('Test data', data);
                let form = $("form#test");
                let setField = (name, value) => {
                    let item = form.find("[name='" + name + "']");
                    // console.log(name);
                    item.parent().addClass('text-active');
                    if ((item[0].nodeName.toLowerCase() == 'input') && (item[0].type == 'checkbox')) {
                        item.prop('checked', value)
                        return;
                    }
                    item.val(value);
                    if (item[0].nodeName.toLowerCase() == 'select') {
                        jcf.getInstance(item).refresh();
                    }
                };

                setField('title', data.Title);
                setField('description', data.Description);
                setField('conditions', data.Conditions);
                let start = this.getFormattedDate(data.Start);
                setField('start-date', start[0]);
                setField('start-time', start[1]);
                let end = this.getFormattedDate(data.End);
                setField('end-date', end[0]);
                setField('end-time', end[1]);
                // setField('member_count', data.MemberCount);
                // setField('select-route', data.Functions.map((x) => x.id));
                // setField('regions', data.Regions.map((x) => x.id));
                setField('hide_comments', data.HideComments);
                setField('hide_issue', data.HideIssue);

                // setField('survey', data.Survey);
                this.state.object.Responsibles = data.Responsibles;
                this.state.object.confidential = data.Confidential == true ? 'on' : '';
                this.state.object.Survey = JSON.parse(data.Survey);
                this.state.object.Routes = data.Routes;



                //			setField('region_id', data.Region.ID);
                //			setField('idea_type', data.IdeaType);
                //			setField('user_benefit', data.UserBenefit);
                //			setField('company_benefit', data.CompanyBenefit);
                //			setField('effect', data.Effect);
                let addFile = (data, name) => {
                    let files = this.props.files['test_form'] || []
                    this.props.setFiles('test_form', [...files, {data: data, name: name}]);
                }
                data.Attachments.forEach((item, i) => {
                    let path = item.split('/');
                    let name = path[path.length - 1];
                    $.ajax({
                        url: item,
                        xhrFields: {responseType: 'blob'},
                        success: (blobData) => {
                            let reader = new FileReader();
                            reader.readAsDataURL(blobData);
                            reader.onloadend = () => {
                                addFile(reader.result, name)
                            };
                        }
                    })

                });


            }, () => {
            })
        }
    }

    componentWillMount() {
        this.loadObject(this.props);

        this.props.app.api('GET', '/api/users', {}, (data)=> {
            let options = {};
            data.forEach((item, i) => {
                    options[item.ID] = item.Title;
            });
            this.setState({'userList': options});

            // console.log('Userlist');
            // console.log(this.state.userList);
        });
    }


    renderRouteModal() {
        if (this.state.assignRoutes) {
            return <RouteTestAssign app={this.props.app} routes={this.state.object.Routes}
                                onSave={this.saveRoutes.bind(this)} onClose={() => this.setState({'assignRoutes': false})}/>;
        }
    }

    saveRoutes(routes, route_id) {
        this.setState({'assignRoutes': false});
        this.state.object.Routes = routes;
        console.log(this.state.object.Routes);

    }

    saveResponsible(responsibles, confidential) {
        console.log('Conf', confidential);
        this.setState({'assignResponsibles': false})
        this.state.object.Responsibles = responsibles;
        this.state.object.confidential = (confidential == 'on' ? true : false);
        console.log(this.state.object.Responsibles);
    }    

    renderResponsibleModal() {
        if (this.state.assignResponsibles) {
            return <ResponsibleTestAssign app={this.props.app}
                                      responsibles={this.state.object.Responsibles}
                                      confidential={this.state.object.confidential}
                                      users={this.state.userList}
                                      onSave={this.saveResponsible.bind(this)}
                                      onClose={() => this.setState({'assignResponsibles': false})}/>;
        }
    }



    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    save() {
        let getTime = (prefix) => {
            let datestr = $('#' + prefix + '-date').val()
            let timestr = $('#' + prefix + '-time').val()
            let dateCmp = datestr.split('.');
            let timeCmp = timestr.split(':')
            let date = new Date(dateCmp[2], dateCmp[1] - 1, dateCmp[0], timeCmp[0], timeCmp[1])
            return date.getTime() / 1000;
        };


        let required = ['title', 'description', 'conditions', 'start-date', 'end-date'];
        let bool_required = ['hide_comments', 'hide_issue']
        let optional = ['access_by_link']
        let form = $("form#test");
        let formDataArray = form.serializeArray();
        let errorInfo = {};
        let isError = false;
        let data = {};
        required.forEach((key) => {
            errorInfo[key] = false
        });
        formDataArray.forEach((item) => {
            if (required.includes(item.name)) {
                if (item.value == "") {
                    errorInfo[item.name] = true;
                    isError = true;
                } else {
                    data[item.name] = item.value;
                }
            }
            if (bool_required.includes(item.name)) {
                data[item.name] = true;
            }
            if (optional.includes(item.name)) {
                data[item.name] = true;
            }
        });

        required.forEach((key) => {
            $('[name=' + key + "]").parent().toggleClass('text-error', errorInfo[key]);
        });


        if (isError) {
            return;
        }
        let formData = new FormData();

        required.forEach((item, i) => {
            formData.append(item, data[item]);
        });
        bool_required.forEach((item) => {
            formData.append(item, !!data[item]);
        });
        optional.forEach((item) => {
            formData.append(item, !!data[item]);
        });


        if (this.props.match.params.id) {
            formData.append('id', this.props.match.params.id);
        }
        formData.append('start', getTime('start'));
        formData.append('end', getTime('end'));
        formData.append('routes', JSON.stringify(this.state.object.Routes));
        formData.append('responsibles', JSON.stringify(this.state.object.Responsibles));
        formData.append('confidential', this.state.object.confidential);
        formData.append('survey', JSON.stringify(this.state.object.Survey));

        $('form#test img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });


        // Display the values
        for (const value of formData.values()) {
            console.log(value);
        }
        
        this.props.app.api_post('/api/tests', formData, (data) => {
            this.props.setFiles('test_form', []);
            this.props.history.push("/tests/show/" + data.id);
        }, (data) => {
            if (data.responseJSON) {
                if (data.responseJSON.error) {
                    this.props.dispatch({
                        type: 'MODAL_SHOW',
                        modal: {
                            header: 'Ошибка отправки',
                            message: [data.responseJSON.error],
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        }
                    })
                }
            }
        });
    }


    timeOptions() {
        let result = []
        let h = 0;
        let m = 0;
        let pad = (n) => {
            if (n < 10) {
                return '0' + n.toString();
            } else {
                return n.toString();
            }
        }

        while (h < 24) {
            result.push(pad(h) + ':' + pad(m));
            m += 10;
            if (m >= 60) {
                h += 1;
                m = 0;
            }
        }
        return result.map(t => <option key={t}>{t}</option>);

    }

    saveSurvey(survey) {
        this.setState({'survey': false});
        //creating of copy
        this.state.object.Survey = JSON.parse(JSON.stringify(survey));
        console.log(this.state.object.Survey);
    }
    uploadSurvey() {
        this.setState({'survey': false});
		console.log('addFile')
		$('#survey-selector').trigger('click')
    }


    parseSurvey(event) {
        const convertJsonData = (item) => {
            var radio_items = [null];
            var check_items = [null];
            var combo_items = [null];
            var min_range = 1;
            var max_range = 5;
            var min_title = '';
            var max_title = '';
            var type = item["Тип вопроса"];

            if(type == 'Один вариант') {
                var ss = item["Опции для вопросов определенных типов"];
                if(ss.length>0){ 
                    radio_items = ss.split(';').map((x) => {
                        return {
                            'text': x,
                            'removed': false
                        }
                    });
                }
            }
            else if(type == 'Несколько вариантов') {
                var ss = item["Опции для вопросов определенных типов"];
                if(ss.length>0){ 
                     check_items = ss.split(';').map((x) => {
                        return {
                            'text': x,
                            'removed': false
                        }
                    });
                }
            }
            else if(type == 'Выподающий список') {
                var ss = item["Опции для вопросов определенных типов"];
                if(ss.length>0){ 
                     combo_items = ss.split(';').map((x) => {
                        return {
                            'text': x,
                            'removed': false
                        }
                    });

                }
            }
            else if(type == 'Оценка по шкале') {
                var ss = item["Опции для вопросов определенных типов"];
                if(ss.length>0){ 
                    var values = ss.split(';');
                    var mm = values[0].split('-');
                    
                    min_range = mm[0];
                    max_range = Math.min(mm[1], 10);

                    min_title = values[1];
                    max_title = values[2];
                }
            }
            return {
                // dont forget to change in xls parser
                question_title: item["Текст вопроса"],
                question_type: type,
                radio_items: radio_items,
                check_items: check_items,
                combo_items: combo_items,
                min_range: min_range,
                max_range: max_range,
                min_title: min_title,
                max_title: max_title,
                is_required: item["Обязательный вопрос"] == 1,
                removed: false
            };
        }

        const readExcel = (file) => {
            const promise = new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
        
              fileReader.onload = (e) => {
                const workbook = XLSX.read(e.target.result, { type: "buffer" });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        
                resolve(XLSX.utils.sheet_to_json(worksheet));
              };
        
              fileReader.onerror = (error) => {
                reject(error);
              };
            });
        
            promise.then((jsonData) => {

                this.state.object.Survey = [];

                jsonData.map((item) => {
                    // console.log(item);
                    this.state.object.Survey.push(convertJsonData(item))
                    
                })

                console.log(this.state.object.Survey);
            });
          };

        readExcel(event.target.files[0]);
    }

    downloadSurway() {
        let surveyTemplate = [
            {
              "Текст вопроса": 'Вопрос с коротким ответом',
              "Тип вопроса": 'Короткий текст',
              "Опции для вопросов определенных типов": '',
              "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос с развернутым',
                "Тип вопроса": 'Длинный текст',
                "Опции для вопросов определенных типов": '',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос выбором одного из вариантов',
                "Тип вопроса": 'Один вариант',
                "Опции для вопросов определенных типов": 'Вариант 1;Вариант 2',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос выбором нескольких вариантов',
                "Тип вопроса": 'Несколько вариантов',
                "Опции для вопросов определенных типов": 'Вариант 1;Вариант 2',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос выбором из списка вариантов',
                "Тип вопроса": 'Выподающий список',
                "Опции для вопросов определенных типов": 'Вариант 1;Вариант 2',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос с оценкой по шкале',
                "Тип вопроса": 'Оценка по шкале',
                "Опции для вопросов определенных типов": '1-2;Подсказка для первого значения(опционально);Подсказка для второго значения(опционально)',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос в одним из двух ответов',
                "Тип вопроса": 'Да/Нет',
                "Опции для вопросов определенных типов": '',
                "Обязательный вопрос": 1
            },
            {
                "Текст вопроса": 'Вопрос подразумевающий загрузку файлов/изображений в качестве ответа',
                "Тип вопроса": 'Загрузка файлов',
                "Опции для вопросов определенных типов": '',
                "Обязательный вопрос": 0
            }
  
        ]

        // this.state.object.Survey
        let surveyData = this.state.object.Survey.map((item) => {
            var title = item.question_title;
            var type = item.question_type;
            var required = item.is_required ? 1 : 0;

            var options = '';

            if(type == 'Один вариант') {
                var vv = item.radio_items.map((x)=>{return x.text});
                options = vv.join(';');
            }
            else if(type == 'Несколько вариантов') {
                var vv = item.check_items.map((x)=>{return x.text});
                options = vv.join(';');
            }
            else if(type == 'Выподающий список') {
                var vv = item.combo_items.map((x)=>{return x.text});
                options = vv.join(';');
            }
            else if(type == 'Оценка по шкале') {
                options = item.min_range + "-" + item.max_range + ";" + item.min_title + ";"+ item.max_title;
            }


            return {
                "Текст вопроса": title,
                "Тип вопроса": type,
                "Опции для вопросов определенных типов": options,
                "Обязательный вопрос": required
            }
        })

        var worksheet = null;
        if(surveyData.length > 0){
            worksheet = XLSX.utils.json_to_sheet(surveyData);
        }
        else {
            worksheet = XLSX.utils.json_to_sheet(surveyTemplate);
        }

        worksheet["A1"].s = {									
            font: {
              bold: true,
            },
          };        
        worksheet["B1"].s = {									
            font: {
              bold: true,
            },
          };        
        worksheet["C1"].s = {									
        font: {
            bold: true,
        },
        };        
        worksheet["D1"].s = {									
        font: {
            bold: true,
        },
        };        

        worksheet['!cols'] = [
            {'width' : 100},
            {'width' : 50},
            {'width' : 40},
            {'width' : 20}];
        
        var workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Survey");
        XLSX.writeFile(workbook, "survey.xlsx", { compression: true });

    }


    renderSurveyModal() {
        if (this.state.survey) {
            return <TestsSurveyCreateForm app={this.props.app} surveyData={JSON.parse(JSON.stringify(this.state.object.Survey))}
                                onSave={this.saveSurvey.bind(this)}  onClose={() => this.setState({'survey': false})}/>;
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Тесты
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page__head-title">{this.props.match.params.id ? 'Редактирование теста' : 'Добавление нового теста'}</h1>

                                    <div className='new_item_descr'>В рамках теста/опроса участники совершают определенные действия: проходят опросы, тестируют гипотезы/услугу, совершают шаги по заданной логике и т.д.<br/><br/>Тесты/опросы – ответы на преднастроенные вопросы, ответы в свободной форме, ответы в форме отчёт/скрин/файл.<br/><br/>Варианты создания теста/опроса – простое создание форм через добавление уже существующей формы вопроса и загрузка теста через шаблон Excel.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <form action="" id="test">
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Название</label>
                                        <input type="text" className="text-field" id="someField" name="title"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder text-active">
                                        <label htmlFor="test-description" className='test_placeholder'>Какие цели мы преследуем?</label>
                                        <textarea id="test-description" className="text-field" name="description" placeholder='Пропиши задачи и вопросы, которые хотите закрыть с помощью полученных ответов.'></textarea>
                                    </div>


                                    <div className="text-field-holder text-area-holder  text-active">
                                        <label htmlFor="test-conditions" className='test_placeholder'>Что тебя ждет при прохождении опроса/теста и на что ориентироваться при ответах?</label>
                                        <textarea data-scroll id="test-conditions" className="text-field"
                                                  name="conditions"  placeholder='Опишите формат вопросов, которые будут использоваться. Расскажите на чём акцентировать внимание при его прохождении. Пример: Для прохождения теста тебе понадобится 10-15 минут. Отвечая на вопросы, руководствуйтесь своими профессиональными навыками. В некоторых вопросах применяйте логику.)'></textarea>
                                    </div>

                                    <h2>Тест в формате опроса/анкеты</h2>

                                    {/* <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="hide_comments"
                                               name="hide_comments"/>
                                        <label htmlFor="hide_comments" className="checkbox-label">Проводить тест в формате опроса</label>

                                    </div> */}

                                    <div className='new_item_descr'>Вы можете создать тест в формате «теста» и «опроса».<br/><br/>«Тест» -  по результату прохождения «Теста» могут создаваться «Улучшения» (настраивается в момент создания «Теста»).<br/><br/>«Опрос» - создается анкета на сайте или загружается шаблоном с помощью файла Excel.<br/><br/>* Если вы используете XLS, то откройте файл, добавьте в него вопросы в нужном формате и загрузите обратно. Если анкета добавлена, то тест будет отображаться пользователям в формате "опроса".</div>
                                    <div style={{marginBottom:'10px', marginTop:'10px'}} ></div>
                                    <a className='survey_template_download' onClick={this.downloadSurway.bind(this)}>Выгрузить форму конструктор анкеты</a>
                                    <div style={{marginBottom:'10px', marginTop:'10px'}} ></div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <a className="btn btn--black" onClick={this.uploadSurvey.bind(this)}>Загрузка анкеты</a>
                                            <input id='survey-selector' type="file" style={{'display': 'none'}} onChange={this.parseSurvey.bind(this)} />
                                        </div>
                                        <div className="col-md-3">
                                        </div>
                                        <div className="col-md-3">
                                            <a className="btn btn--black" onClick={()=>this.setState({'survey': true})}>Конструктор анкеты</a>
                                        </div>
                                    </div>

                                    <h2 className='offset'>Время проведения</h2>



                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="text-field-holder">
                                                <label>Начало:</label>
                                                <input type="text" className="text-field hasDatepicker"
                                                       name="start-date" id="start-date"/>
                                                <i className="ico icon-calendar"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select-field-holder text-active">
                                                <label>Время:</label>
                                                <select className="custom-select" id="start-time" name="start-time"
                                                        data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'>
                                                    {this.timeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="text-field-holder">
                                                <label>Конец:</label>
                                                <input type="text" className="text-field hasDatepicker" name="end-date"
                                                       id="end-date"/>
                                                <i className="ico icon-calendar"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select-field-holder text-active">
                                                <label>Время:</label>
                                                <select className="custom-select" id="end-time" name="end-time"
                                                        data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'>
                                                    {this.timeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <h2 className='offset'>Участники</h2>
                                    <label>Если не выбран ни один пункт, то нет ограничений доступа.</label>
                                    <div style={{marginBottom:'10px', marginTop:'20px'}} ></div>


                                    <a onClick={() => this.setState({'assignRoutes': true})} className="btn btn--black offset">Регионы и/или Департаменты</a>
                                    <div style={{marginBottom:'10px'}} >или</div>
                                    <a onClick={() => this.setState({'assignResponsibles': true})} className="btn btn--black">Отдельные участники</a>
                                    <div style={{marginBottom:'10px'}} >или</div>

                                    <div className="check-wrap select-field-holder">
                                        <input type="checkbox" className="checkbox" id="access_by_link"
                                            name="access_by_link"/>
                                        <label htmlFor="access_by_link" className="checkbox-label">Доступ по ссылке</label>
                                    </div>
                                    <div style={{marginBottom:'10px', marginTop:'50px'}} ></div>

                                    <h2 className='offset'>Дополнительные настройки</h2>
                                    <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="hide_issue" name="hide_issue"/>
                                        <label htmlFor="hide_issue" className="checkbox-label">Отключить возможность создавать улучшения</label>
                                    </div>
                                    <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="hide_comments"
                                               name="hide_comments"/>
                                        <label htmlFor="hide_comments" className="checkbox-label">Скрыть комментарии</label>
                                    </div>

                                    <UploadFile formId="test_form"/>

                                    <div className="form-actions">
                                        <a className="btn btn--black"
                                           onClick={this.save.bind(this)}>{this.props.match.params.id ? "Сохранить" : 'Добавить'} <Points style={this.props.match.params.id ? {'display':'none'}: {}} action='test_create' app={this.props.app}/></a>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </section>
                {this.renderSurveyModal()}
                {this.renderRouteModal()}
                {this.renderResponsibleModal()}
            </div>
        );
    }
}

export default withDispatch(withRouter(withFiles(TestForm)));
