import React from 'react';
import Modal from './modal.jsx';
import Select from './select.jsx';

class ResponsibleAssign extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			'responsible': props.responsible,
			'userList': []
		}
	}
	componentDidMount() {
		this.props.app.api('GET', '/api/users', {}, (data)=> {
			let options = {};
			data.forEach((item, i) => {
					options[item.ID] = item.Title;
			});
			this.setState({'userList': options});
		});
	}


	componentDidUpdate() {
		$.initializeForm($('.select-route-table'));
	}

	save() {
		if (this.state.responsible) {
			this.props.onSave(this.state.responsible, this.state.userList[this.state.responsible]);
		} else {
			$('#responsible-select').parent().toggleClass('text-error', true);
		}
	}

	responsibleChange(responsible) {
		console.log(responsible);
		this.setState({'responsible': responsible })
	}

	usersOptions() {
		return this.state.userList.map((user)=> {
			return (
				<option key={user.ID} value={user.ID}>{user.Title}</option>
			)
		});


	}

	render() {
		return (
			<Modal>
				<div className="modal-content">
					<div className="select-route-header">
						{ this.props.title ? this.props.title : "Назначить ответственного" }
					</div>
					<div className="responsible-form">
					<div className="select-field-holder text-active">
								<label>Пользователь:</label>
								<Select options={this.state.userList} defaultValue={this.state.responsible} onChange={this.responsibleChange.bind(this)}/>
						 </div>
					</div>
					<div className="form-actions">
						<a className="btn btn--black" onClick={this.save.bind(this)}>{this.props.buttonTitle ? this.props.buttonTitle : 'Направить'}</a>
					</div>
				</div>
				<a onClick={() => {this.props.onClose()}} className="close ico icon-close"/>
			</Modal>

		);
	}

}

export default ResponsibleAssign;
