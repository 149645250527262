import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
    }

    componentWillMount() {
        $('html').toggleClass('fancybox-lock', true)
    }

    componentDidMount() {
        $('.modal-wrapper').css('top', $(window).scrollTop())
        if (this.props.group) {
            $('.info-modal').toggleClass('group-modal', true)
        }
        $.initializeForm($('.modal-wrapper'));
    }

    componentWillUnmount() {
        $('html').toggleClass('fancybox-lock', false)
    }

    render() {
        return (
            <div className="modal-wrapper">
                <div className="modal-inner">
                    <div className="info-modal">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );

    }

}


export default Modal;
