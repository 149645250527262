import 'react-app-polyfill/ie11';
import 'core-js/es5';
import 'core-js/es6';
import 'core-js/es7';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Login from './login.jsx'
import HomePage from './home_page.jsx'
import Footer from './footer.jsx'
import Improvements from './improvements.jsx'
import Improvement from './improvement.jsx'
import ImprovementForm from './improvement_form.jsx'
import RegionFunction from './region_function.jsx'
import Practices from './practices.jsx';
import PracticeForm from './practice_form.jsx'
import Practice from './practice.jsx';
import Ideas from './ideas.jsx';
import Tests from './tests.jsx';
import Test from './test.jsx';
import Idea from './idea.jsx';
import IdeaForm from './idea_form.jsx'
import TestForm from './test_form.jsx'
import ReportsIndex from './reports_index.jsx';
import CommentList from './comment_list.jsx';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import rootReducer from './reducers'
import ErrorModal from './error_modal.jsx'
import UserConfig from './user_config.jsx'
import Ratings from './ratings.jsx'
import CityRatings from './city_ratings.jsx'
import MarketIndex from './market_index.jsx'
import PrizeDetails from './prize_details.jsx'
import UserDetails from './user_details.jsx'
import Project from './project.jsx';
import MarketDetails from './market_details.jsx'
import Statistics from "./statistics.jsx";



class App extends React.Component {

    constructor() {
        super();
        let keys = ['access_token', 'refresh_token', 'updateRequired', 'userId', 'userName', 'role', 'userRegion', 'userMR'];
        this.state = {'status': 'logoff'};
        this.sessionData = {}
        keys.forEach((item) => {
            this.sessionData[item] = window.localStorage.getItem(item)
        })

        let self = this
        this.app = {
            'storage': {},
            'logout': () => {
                let newState = {};
                keys.forEach((item) => {
                    window.localStorage.removeItem(item)
                });
                self.sessionData = {}
                self.setState({'status': 'logoff'});
            },
            'user_data': () => {
                // console.log(self.sessionData);
                return {
                    'id': self.sessionData.userId,
                    'name': self.sessionData.userName,
                    'role': self.sessionData.role,
                    'region': self.sessionData.userRegion,
                    'macroregion': self.sessionData.userMR
                };
            },
            'api': (method, path, data, success, error) => {
                $.ajax({
                    'url': path,
                    'method': method,
                    'headers': {'Authorization': 'Bearer ' + self.sessionData.access_token},
                    'data': data,
                    'success': success,
                    'statusCode': {
                        401: () => {
                            $.ajax({
                                'url': '/api/refresh',
                                'method': 'POST',
                                'headers': {'Authorization': 'Bearer ' + self.sessionData.refresh_token},
                                'success': (auth_data) => {
                                    self.sessionData['access_token'] = auth_data.access_token;
                                    self.sessionData['refresh_token'] = auth_data.refresh_token;
                                    window.localStorage.setItem('refresh_token', auth_data.refresh_token);
                                    window.localStorage.setItem('access_token', auth_data.access_token);
                                    $.ajax({
                                        'url': path,
                                        'method': method,
                                        'headers': {'Authorization': 'Bearer ' + auth_data.access_token},
                                        'data': data,
                                        'success': success,
                                        'error': error
                                    });
                                },
                                'error': () => {
                                    window.localStorage.removeItem('access_token');
                                    window.localStorage.removeItem('refresh_token');
                                    self.setState({'status': 'logoff'});
                                }
                            });
                        },
                        422: () => {
                            window.localStorage.removeItem('access_token');
                            window.localStorage.removeItem('refresh_token');
                            self.setState({'status': 'logoff'});
                        }
                    },
                    'error': (req) => {
                        //if (req.status != 401 ) {
                        //window.localStorage.removeItem('access_token');
                        //window.localStorage.removeItem('refresh_token');
                        //self.setState({'status': 'logoff'});
                        //}
                    }
                })
            },
            'api_post': (path, data, success, error) => {
                $.ajax({
                    'url': path,
                    'method': 'POST',
                    'headers': {'Authorization': 'Bearer ' + self.sessionData.access_token},
                    'data': data,
                    'success': success,
                    'contentType': false,
                    'processData': false,
                    'statusCode': {
                        401: () => {
                            $.ajax({
                                'url': '/api/refresh',
                                'method': 'POST',
                                'headers': {'Authorization': 'Bearer ' + self.sessionData.refresh_token},
                                'success': (auth_data) => {
                                    window.localStorage.setItem('access_token', auth_data.access_token);
                                    self.sessionData['access_token'] = auth_data.access_token;
                                    self.sessionData['refresh_token'] = auth_data.refresh_token;
                                    window.localStorage.setItem('refresh_token', auth_data.refresh_token);
                                    $.ajax({
                                        'url': path,
                                        'method': 'POST',
                                        'headers': {'Authorization': 'Bearer ' + auth_data.access_token},
                                        'data': data,
                                        'success': success,
                                        'contentType': false,
                                        'processData': false,
                                        'error': error
                                    });
                                },
                                'error': () => {
                                    window.localStorage.removeItem('access_token');
                                    window.localStorage.removeItem('refresh_token');
                                    self.setState({'status': 'logoff'});
                                }
                            })
                        }
                    },
                    'error': (req) => {
                        if (error && req.status !== 401) {
                            error(req);
                        }
                    }
                })
            },
            'api_post_json': (path, data, success, error) => {
                $.ajax({
                    'url': path,
                    'method': 'POST',
                    'headers': {
                        'Authorization': 'Bearer ' + self.sessionData.access_token,
                        'Content-Type': "application/json; charset=utf-8"
                    },
                    'data': JSON.stringify(data),
                    'success': success,
                    'processData': false,
                    'statusCode': {
                        401: () => {

                            $.ajax({
                                'url': '/api/refresh',
                                'method': 'POST',
                                'headers': {'Authorization': 'Bearer ' + self.sessionData.refresh_token},
                                'success': (auth_data) => {
                                    window.localStorage.setItem('access_token', auth_data.access_token);
                                    self.sessionData['access_token'] = auth_data.access_token;
                                    self.sessionData['refresh_token'] = auth_data.refresh_token;
                                    window.localStorage.setItem('refresh_token', auth_data.refresh_token);
                                    $.ajax({
                                        'url': path,
                                        'method': 'POST',
                                        'headers': {
                                            'Authorization': 'Bearer ' + auth_data.access_token,
                                            'Content-Type': "application/json; charset=utf-8"
                                        },
                                        'data': JSON.stringify(data),
                                        'success': success,
                                        'processData': false,
                                        'error': error
                                    });
                                },
                                'error': () => {
                                    window.localStorage.removeItem('access_token');
                                    window.localStorage.removeItem('refresh_token');
                                    self.setState({'status': 'logoff'});
                                }
                            })
                        }
                    },
                    'error': (req) => {
                        if (error && req.status != 401) {
                            error(req);
                        }
                    }
                })
            }
        }
        if (!(this.sessionData.access_token === undefined || this.sessionData.refresh_token === undefined)) {
            if (this.sessionData.updateRequired !== "false") {
                this.state['status'] = 'function'
            } else {
                this.state['status'] = 'loading';
                this.loadData();
            }
        }
    }

    loadData() {
        let self = this;

        this.app.api('GET', '/api/routes', {}, (routes) => {
            this.app.api('GET', '/api/problem_types', {}, (routeTypes) => {
                let routeData = {}
                routes.forEach((r) => {
                    routeData[r['ID']] = r;
                    routeData[r['ID']]['types'] = [];
                })
                routeTypes.forEach((t) => {
                    if (routeData[t['Parent']]) {
                        routeData[t['Parent']]['types'].push(t);
                    }
                })
                self.setState({'status': 'login'});
                self.app.storage['routes'] = routes.map((r) => {
                    return routeData[r['ID']]
                });
            });
        })
        
        this.app.api('GET', '/api/routes_ideas', {}, (idea_routes) => {
            this.app.api('GET', '/api/idea_types', {}, (ideaTypes) => {
                let routeData = {}
                idea_routes.forEach((r) => {
                    routeData[r['ID']] = r;
                    routeData[r['ID']]['types'] = [];
                })
                ideaTypes.forEach((t) => {
                    if (routeData[t['Parent']]) {
                        routeData[t['Parent']]['types'].push(t);
                    }
                })
                self.app.storage['idea_routes'] = idea_routes.map((r) => {
                    return routeData[r['ID']]
                });
            });
        })        

        this.app.api('GET', '/api/profile', {}, (profile) => {
            if (profile.info.region) {
                self.app.storage['defaultRegion'] = profile.info.region.ID;
            }
        });

        this.app.api('GET', '/api/regions', {}, (regions) => {
            self.app.storage['regions'] = regions;
        });

        this.app.api('GET', '/api/mr_regions', {}, (mr_regions) => {
            self.app.storage['mr_regions'] = mr_regions;
        });

        this.app.api('GET', '/api/game/rewards', {}, (rewards) => {
            self.app.storage['rewards'] = rewards;
        });


        this.app.api('GET', '/api/user_functions_by_reg', {}, (user_functions) => {
            self.app.storage['user_functions'] = user_functions;
        })
        // без разбивки по регионам
        this.app.api('GET', '/api/user_functions', {}, (user_functions) => {
            self.app.storage['user_functions_plain'] = user_functions;
        })

        this.app.api('GET', '/api/rating_list', {}, (ratings) => {
            self.app.storage['ratings'] = ratings;
        })

        this.app.api('GET', '/api/game/teams', {}, (teams) => {
            self.app.storage['teams'] = teams;
        })

        this.app.api('GET', '/api/statuses', {}, (statuses) => {
            self.app.storage['statuses'] = statuses;
        })
        this.app.api('GET', '/api/practice_statuses', {}, (statuses) => {
            self.app.storage['practice_statuses'] = statuses;
        })
        this.app.api('GET', '/api/divisions', {}, (div) => {
            self.app.storage['divisions'] = div;
        })
        this.app.api('GET', '/api/departments_by_div', {}, (dep) => {
            self.app.storage['departments'] = dep;
        })
    }

    updateLoginData(data) {
        window.localStorage.setItem('refresh_token', data.refresh_token);
        window.localStorage.setItem('access_token', data.access_token);
        window.localStorage.setItem('updateRequired', 'false');
        this.sessionData['refresh_token'] = data.refresh_token;
        this.sessionData['access_token'] = data.access_token;
        this.sessionData['updateRequired'] = 'false';
        this.setState({'status': 'loading'});
        this.loadData();
    }

    setLoginData(data) {
        let keys = ['access_token', 'refresh_token', 'updateRequired', 'userId', 'userName', 'role', 'userRegion', 'userMR'];
        keys.forEach((item) => {
            window.localStorage.setItem(item, data[item])
        });
        this.sessionData = {}
        keys.forEach((item) => {
            this.sessionData[item] = window.localStorage.getItem(item)
        })
        if (data.updateRequired) {
            this.setState({'status': 'function'})
        } else {
            this.setState({'status': 'loading'});
            this.loadData();
        }
    }

    clearFilters() {
        $('#filters-form').find('.form-field').val('');
        $('#filters-form').find('.custom-select').each((i, sel) => {
            jcf.getInstance($(sel)).refresh();
        });
        $('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
        sessionStorage.setItem("improvements_filters", null);
    }

    renderContent() {
        if (this.state.status === 'logoff') {
            return (
                <Login setLoginData={this.setLoginData.bind(this)}/>
            )
        } else if (this.state.status === 'function') {
            return (
                <RegionFunction updateLoginData={this.updateLoginData.bind(this)} app={this.app}/>
            )
        } else if (this.state.status === 'login') {
            return (
                <div>
                    <Route exact path='/' render={props => <HomePage app={this.app} {...props}/>}/>
                    <Route exact path='/ideas' render={props => <Ideas app={this.app} page="all" {...props}/>}/>
                    <Route exact path='/ideas/decide'
                           render={props => <Ideas app={this.app} page="decide" {...props}/>}/>
                    <Route exact path='/ideas/request'
                           render={props => <Ideas app={this.app} page="request" {...props}/>}/>
                    <Route exact path='/ideas/created'
                           render={props => <Ideas app={this.app} page="created" {...props}/>}/>
                    <Route exact path='/ideas/solved'
                           render={props => <Ideas app={this.app} page="solved" {...props}/>}/>
                    <Route exact path='/ideas/favorite'
                           render={props => <Ideas app={this.app} page="favorite" {...props}/>}/>
                    <Route path='/ideas/show/:id' render={props => <Idea app={this.app} {...props}/>}/>
                    <Route path='/ideas/new' render={props => <IdeaForm app={this.app} {...props}/>}/>
                    <Route path='/ideas/edit/:id' render={props => <IdeaForm app={this.app} {...props}/>}/>
                    <Route exact path='/tests' render={props => <Tests app={this.app} page="available" {...props}/>}/>
                    <Route exact path='/tests/decide'
                           render={props => <Tests app={this.app} page="decide" {...props}/>}/>
                    <Route exact path='/tests/created'
                           render={props => <Tests app={this.app} page="created" {...props}/>}/>
                    <Route exact path='/tests/finished'
                           render={props => <Tests app={this.app} page="finished" {...props}/>}/>
                    <Route exact path='/tests/all'
                           render={props => <Tests app={this.app} page="all" {...props}/>}/>
                    <Route path='/tests/show/:id' render={props => <Test app={this.app} {...props}/>}/>
                    <Route path="/tests/new" render={props => <TestForm app={this.app} {...props}/>}/>
                    <Route path="/tests/edit/:id" render={props => <TestForm app={this.app} {...props}/>}/>
                    <Route exact path='/improvements'
                           render={props => <Improvements app={this.app} page="all" {...props}/>}/>
                    <Route exact path='/improvements/decide'
                           render={props => <Improvements app={this.app} page="decide" {...props}/>}/>
                    <Route exact path='/improvements/created'
                           render={props => <Improvements app={this.app} page="created" {...props}/>}/>
                    <Route exact path='/improvements/favorite'
                           render={props => <Improvements app={this.app} page="favorite" {...props}/>}/>
                    <Route path='/improvements/show/:id' render={props => <Improvement app={this.app} {...props}/>}/>
                    <Route path='/improvements/new' render={props => <ImprovementForm app={this.app} {...props}/>}/>
                    <Route path='/improvements/edit/:id'
                           render={props => <ImprovementForm app={this.app} {...props}/>}/>
                    <Route path="/reports/:id?" render={props => <ReportsIndex app={this.app} {...props}/>}/>
                    <Route path="/comments" render={props => <CommentList app={this.app} {...props}/>}/>
                    <Route path="/config" render={props => <UserConfig app={this.app} {...props}/>}/>
                    <Route path="/ratings/:type?/:period?" render={props => <Ratings app={this.app} {...props}/>}/>
                    <Route path="/city_ratings/:period?/:city?"
                           render={props => <CityRatings app={this.app} {...props}/>}/>
                    <Route exact path="/market" render={props => <MarketIndex app={this.app} {...props}/>}/>
                    <Route exact path="/prizes" render={props => <MarketIndex kind="my" app={this.app} {...props}/>}/>
                    <Route path="/prizes/:id" render={props => <PrizeDetails app={this.app} {...props}/>}/>
                    <Route path="/market/:id" render={props => <MarketDetails app={this.app} {...props}/>}/>
                    <Route path="/user/:id" render={props => <UserDetails app={this.app} {...props}/>}/>
                    <Route path="/projects/:id/:help?" render={props => <Project app={this.app} {...props}/>}/>
                    <Route path="/statistics/:type?/:page?/:assignee_id?/:filter?" render={props => <Statistics app={this.app} {...props}/>}/>
                    <Route exact path='/practices' render={props => <Practices app={this.app} page="all" {...props}/>}/>
                    <Route exact path='/practices/scale'
                           render={props => <Practices app={this.app} page="scale" {...props}/>}/>
                    <Route exact path='/practices/request'
                           render={props => <Practices app={this.app} page="request" {...props}/>}/>
                    <Route exact path='/practices/review'
                           render={props => <Practices app={this.app} page="review" {...props}/>}/>
                    <Route exact path='/practices/worked'
                           render={props => <Practices app={this.app} page="worked" {...props}/>}/>
                    <Route exact path='/practices/created'
                           render={props => <Practices app={this.app} page="created" {...props}/>}/>
                    <Route exact path='/practices/solved'
                           render={props => <Practices app={this.app} page="solved" {...props}/>}/>
                    <Route exact path='/practices/favorite'
                           render={props => <Practices app={this.app} page="favorite" {...props}/>}/>
                    <Route path='/practices/show/:id' render={props => <Practice app={this.app} {...props}/>}/>
                    <Route path='/practices/new' render={props => <PracticeForm app={this.app} {...props}/>}/>
                    <Route path='/practices/edit/:id' render={props => <PracticeForm app={this.app} {...props}/>}/>
                    <Route component={Footer}/>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                {this.renderContent()}
                <ErrorModal/>
            </div>
        )
    }
}

const store = createStore(rootReducer);

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <App/>
            </Switch>
        </BrowserRouter>
    </Provider>
), document.getElementById('app'));
