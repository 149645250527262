import { connect } from 'react-redux'

const mapStateToProps = state => {
  return { }
}

const mapDispatchToProps = dispatch => {
  return {
			dispatch: (action) => { dispatch(action)}
	}
}

export default (cls) => {
    return connect(mapStateToProps, mapDispatchToProps)(cls);
}
