import React from 'react';
import Modal from './modal.jsx';

class ScaleProgress extends React.Component {

	constructor(props) {
		super(props);
		this.props = props
        this.state = {
        }	
		
	}

	onShowStats(range)
	{
		this.props.onClick(range)
	}
	onHideStats()
	{
		this.props.onClose()
	}

	renderPercent(mr, percent, range) {
		if(this.props.progressOnly != true)
			return null;

		if(mr == null && range == 'МР')
			return
		
		return (
			<div>
				<div className='scale-progress-row'>
					<span className="scale-progress-percent-title">Процесс внедрения в <a className="link--underline" style={{cursor: 'pointer'}} onClick={() => {this.onShowStats(range)}}>{range}</a></span>
					<span className="scale-progress-percent-value">{percent}%</span>
				</div>
				<div className='scale-progress-row2'>
					<span className={percent < 100 ? "scale-progress-line-partly" : "scale-progress-line-full"} style={{ width: percent +"%", display: (percent>0) ? 'block': 'none'}}></span>
					<span className={percent > 0 ? "scale-progress-line2-partly" : "scale-progress-line2-full"} style={{ width: (100-percent) +"%", display: (percent<100) ? 'block': 'none'}}></span>
				</div>				
			</div>
		)
	}

	renderStats(mr) {
		if(this.props.progressOnly != false)
			return null;
		if(this.props.statRange == 'rf')
			mr = null		
		return (
			<Modal>
                <div className="modal-content">
					<div className="select-route-header">
                        Прогресс по регионам
                    </div>
					<div className="scale-header-row">
							<span className="scale-header">Макрорегион</span>
							<span className="scale-header">Регион</span>
							<span className="scale-header">Статус</span>
						</div>
					<div className='modal-scrollable'>
						<div className="scale-region-conent">
							{
								this.props.substatuses.map((status, index) => 
								{
									return this.renderRegion(mr, status, index)
								})
							}
						</div>				
					</div>
				</div>
                <a onClick={() => {this.onHideStats()}} className="close ico icon-close"/>

			</Modal>
		)		
	}

	regionById(id) {
		for(var i in this.props.regions)
		{
			if(this.props.regions[i].ID == id)
				return this.props.regions[i]
		}
		return null;
	}
	regionTeamById(id)
	{
		for(var i in this.props.teams)
		{
			if(this.props.teams[i]._id == id)
				return this.props.teams[i]
		}
		return null;
	}

	renderRegion(mr, status, index) {
		var region = this.regionById(status.region_id);
		if(!region)
			return 
		var team = this.regionTeamById(region.teamId);
		if(!team)
			return 
		if(mr != null)
		{
			if(mr != team['_id'])
				return
		}

		var point_type = 'new';

		if(status.Title == 'В работе')
			point_type = 'in-progress';
		else if(status.Title == 'Невозможно реализовать')
			point_type = 'unable';
		else if(status.Title == 'Не актуально')
			point_type = 'no-need';
		else if(status.Title == 'Реализована')
			point_type = 'done';
		else if(status.Title == 'Реализована ранее')
			point_type = 'before';

		return (
			<div className='scale-region-row'>
				<div className='scale-region-column'><span className='scale-region-title' >{team.name}</span></div>
				<div className='scale-region-column'><span className='scale-region-title' >{region.Title}</span></div>
				<div className='scale-region-column2'>
					<div className={'scale-region-status-point-'+point_type} />
					<span className='scale-region-status' >{status.Title}</span></div>
			</div>
		);
	}

	renderProgress() {
		
		let mr = this.props.app.user_data()['macroregion']
		if(this.props.app.user_data()['role'] == 'moderator_cf')
			mr = null		

		if (this.props.substatuses == null || !this.props.substatuses.length) {
			return null;
		}

		// console.log(this.props.app.user_data());


		let currentProgressMR = 0;
		let totalLengthMR = 0;

		let currentProgressRF = 0;
		let totalLengthRF = 0;
		this.props.substatuses.map((status, index) => 
		{
			var region = this.regionById(status.region_id);
			if(!region)
				return 
			
			var team = this.regionTeamById(region.teamId);
			
			if(mr != null) 
			{
				if(mr == team['_id']) 
				{
					totalLengthMR += 1
					if(status.Title == 'Реализована')
						currentProgressMR += 1
				}
			}
			if(status.Title == 'Реализована')
				currentProgressRF += 1
		})
		totalLengthRF = this.props.substatuses.length

		var percentMR = Math.floor(((currentProgressMR/totalLengthMR)*100));
		var percentRF = Math.floor(((currentProgressRF/totalLengthRF)*100));


		return (
			<div className="scale-progress-table">
				<div className="scale-progress-row">
					{ this.renderPercent(mr, percentMR, 'МР') }
				</div>
				<div className="scale-progress-row">
					{ this.renderPercent(mr, percentRF, 'РФ') }
				</div>
				{ this.renderStats(mr)}
			</div>			
		)
	}

	render() {
        return (
			<div>
				{ this.renderProgress() }
			</div>
		)

	}
}

export default ScaleProgress;
