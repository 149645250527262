export default (state = null, action) => {
    switch (action.type) {
      case 'MODAL_DISMISS':
        return null;
      case 'MODAL_SHOW':
        return action.modal;
      default:
        return state;
   }
};
