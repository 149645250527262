import React from 'react';
class Like extends React.Component {

  getSource() {
      if (this.props.kind == "down") {
          return "/static/img/like_down.png";
      } else {
          return "/static/img/like_up.png";
      }
  }

  getClass() {
      let classes = ['like_wrapper', this.props.disabled ? 'disabled' : 'enabled'];
      if (this.props.allowClick) {
          classes.push('active');
      }

      return classes.join(' ');
  }

  render() {
    return (<span className={this.getClass()} onClick={this.props.allowClick ? this.props.onClick : null}>
              <span style={{fontSize: '18px'}}>{this.props.value}</span>
              <img src={this.getSource()} />
           </span>)
  }

}


export default Like;
