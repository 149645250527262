import React from 'react';
import Modal from './modal.jsx';
import withDispatch from './withDispatch.jsx'

class TestsSurveyCreateForm extends React.Component {

    constructor(props) {
        super(props);
        this.nodeTemplate = {
            // dont forget to change in xls parser
            question_title: '',
            question_type: '',
            radio_items: [null],
            check_items: [null],
            combo_items: [null],
            min_range: 1,
            max_range: 5,
            min_title: '',
            max_title: '',
            is_required: true,
            removed: false
        }
        this.state = {
            'surveys': [...props.surveyData]
        }

        if(this.state.surveys.length == 0)
        {
            this.state.surveys.push(JSON.parse(JSON.stringify(this.nodeTemplate)));
        }
    }

    componentDidUpdate() {
        $.initializeForm($('.select-survey-table'));
    }

   

    removeQuestion(idx) {
        // console.log("remove question " + idx);
        let surveys = this.state.surveys;
        surveys[idx].removed = true;
        this.setState({'surveys': surveys});
        // console.log("survey: " + this.state.surveys);
    }

    typeOptions(region_id) {
        let statuses = ['Короткий текст', 'Длинный текст', 'Один вариант', 'Несколько вариантов', 'Выподающий список', 'Оценка по шкале', 'Да/Нет','Загрузка файлов' ]
        return statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    rangeStartOptions(region_id) {
        let statuses = ['0', '1']
        return statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    rangeEndOptions(region_id) {
        let statuses = ['2', '3', '4', '5', '6', '7', '8', '9', '10']
        return statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    typeChange(target, idx) {

        let surveys = this.state.surveys;
        surveys[idx].question_type = $(target).val();
        // console.log("typeChange " + idx);
        this.setState({'surveys': surveys})
    }

    titleChange(target, idx) {

        let surveys = this.state.surveys;
        surveys[idx].question_title = $(target).val();
        // console.log("typeChange " + idx);
        this.setState({'surveys': surveys})
    }


    saveSurvey() {
        let survey = null;

        let surveys = this.state.surveys.map((survey, idx) => {
            // console.log("render survey " + survey);
            if (survey.removed) {
                return null;
            }
            else if (survey.question_type == 'Один вариант') {
                let items = survey.radio_items.map((item, id) => {
                    if (item && item.removed) {
                        return null;
                    }
        
                    return item;
                });
                survey.radio_items = items.filter((x) => x != null);
                return survey;               
            }
            else if (survey.question_type == 'Несколько вариантов') {
                let items = survey.check_items.map((item, id) => {
                    if (item && item.removed) {
                        return null;
                    }
        
                    return item;
                });
                survey.check_items = items.filter((x) => x != null);
                return survey;           
            }
            else if (survey.question_type == 'Выподающий список') {
                let items = survey.combo_items.map((item, id) => {
                    if (item && item.removed) {
                        return null;
                    }
        
                    return item;
                });
                survey.combo_items = items.filter((x) => x != null);
                return survey;         
            }

            return survey;
        }).filter((x) => x != null);

        this.props.onSave(surveys);
    }

    questionTitle(idx){
        return (
            <div key={"question-tittle-"+idx} className="select-survey-row">
                <div className="select-survey-cell-title">
                <label>Текст вопроса:</label>
                    <div className="select-field-holder text-active">
                        <textarea id="question_title" className="text-field survey_title" defaultValue={this.state.surveys[idx].question_title}
                        onChange={(e) => this.titleChange(e.target, idx)}/>
                        {/* <input type="text" className="text-field" id="question_title" name="title"/> */}
                    </div>
                </div>
                <div className="select-survey-cell-type">
                    <div className="select-field-holder text-active">
                        <label>Тип вопроса:</label>
                        <div style={{paddingTop: '32px'}}></div>
                        <select className="survey-select custom-select" defaultValue={this.state.surveys[idx].question_type}
                                onChange={(e) => this.typeChange(e.target, idx)}>
                            <option/>
                            {this.typeOptions()}
                        </select>
                    </div>
                </div>
                <div className="select-survey-cell">
                    <div style={{paddingTop: '45px'}}></div>
                    <div className="select-field-holder text-active survey_buttons">
                        <a className="survey_change-btn" onClick={this.removeQuestion.bind(this, idx)}>-</a>
                    </div>

                </div>
            </div>            
        );      
    }

    questionRequired(idx) {
        let survey = this.state.surveys[idx];

        let id = "question_required_" + idx;
        return(
            <div className="check-wrap" style={{marginTop:'20px'}}>
                <input type="checkbox" className="checkbox" id={id}
                   name={id} defaultChecked={survey.is_required}/>
                <label htmlFor={id} className="checkbox-label">Обязательный вопрос</label>
            </div>
        );
    }    
    questionShortAnswer() {
        return(
            <div className='surver-short-answer-preview'>
                Краткий ответ участника опроса (одна строка)
            </div>
        );
    }
    questionLongAnswer() {
        return(
            <div className='surver-long-answer-preview'>
                    Развернутый ответ участника (несколько строк)
            </div>
        );
    }

    questionFileAnswer() {
        return(
            <div className='surver-file-answer-preview'>
                    Возможность прикрепить фото, файл, документ.
            </div>
        );
    }

    changeCheckboxItem(target, idx, id) {
        let surveys = this.state.surveys;
        if(surveys[idx].check_items[id] == null)
        {
            surveys[idx].check_items.push(null);
        }
        surveys[idx].check_items[id] = {
            'text': target.value,
            'removed': false
        };
        this.setState({'surveys': surveys});
    }


    removeCheckboxItem(idx, itemId) {
        let surveys = this.state.surveys;
        // surveys[idx].radio_items.splice(itemId, 1);
        surveys[idx].check_items[itemId].removed = true;
        this.setState({'surveys': surveys});
    }    


    questionCheckboxAnswer(idx){
        let survey = this.state.surveys[idx];
        let items = survey.check_items.map((item, id) => {
            if (item && item.removed) {
                return null;
            }

            let removeBtn='';
            if( id != survey.check_items.length-1)
                removeBtn = (<a className="survey_change-btn-small" onClick={this.removeCheckboxItem.bind(this, idx, id)}>х</a>);
            let index = "question_"+idx+"_check_"+id;
            return (
                <div className="check-wrap">
                    <div className="survey_checkbox_preview"></div>
                    <input type="text" className="text-field survey_checkbox_title" 
                        id={index} name={index} 
                        placeholder={item === null ? 'Добавить вариант' : 'Вариант ответа'} 
                        defaultValue={item == null ? '' : item.text} 
                        onChange={(e) => this.changeCheckboxItem(e.target, idx, id)}/>
                    {removeBtn}
                    
                </div>

            );
        })
        return(
            <div>
                {items}
            </div>
        );        
    }

    
    changeRadioItem(target, idx, id) {
        let surveys = this.state.surveys;
        if(surveys[idx].radio_items[id] == null)
        {
            surveys[idx].radio_items.push(null);
        }
        surveys[idx].radio_items[id] = {
            'text': target.value,
            'removed': false
        };
        this.setState({'surveys': surveys});
    }

    removeRadioItem(idx, itemId) {
        let surveys = this.state.surveys;
        // surveys[idx].radio_items.splice(itemId, 1);
        surveys[idx].radio_items[itemId].removed = true;
        this.setState({'surveys': surveys});
    }    

    questionRadioAnswer(idx){
        let survey = this.state.surveys[idx];
        let items = survey.radio_items.map((item, id) => {
            if (item && item.removed) {
                return null;
            }

            let removeBtn='';
            if( id != survey.radio_items.length-1)
                removeBtn = (<a className="survey_change-btn-small" onClick={this.removeRadioItem.bind(this, idx, id)}>х</a>);
            let index = "question_"+idx+"_radio_"+id;
            return (
                <div className="check-wrap">
                    <div className="survey_radio_preview"></div>
                    <input type="text" className="text-field survey_radio_title" 
                        id={index} name={index} 
                        placeholder={item === null ? 'Добавить вариант' : 'Вариант ответа'} 
                        defaultValue={item == null ? '' : item.text} 
                        onChange={(e) => this.changeRadioItem(e.target, idx, id)}/>
                    {removeBtn}
                    
                </div>

            );
        })
        return(
            <div>
                {items}
            </div>
        );
    }

    questionYesNoAnswer(idx){
        let survey = this.state.surveys[idx];
        let radio_items = [{'text':'Да', 'removed': false}, {'text':'Нет', 'removed': false}];

        let items = radio_items.map((item, id) => {
            if (item && item.removed) {
                return null;
            }

            let index = "question_"+idx+"_radio_"+id;
            return (
                <div className="check-wrap">
                    <div className="survey_radio_preview"></div>
                    <label className="survey_yesno_title">{item.text}</label>
                </div>

            );
        })
        return(
            <div>
                {items}
            </div>
        );
    }    

    changeComboItem(target, idx, id) {
        let surveys = this.state.surveys;
        if(surveys[idx].combo_items[id] == null)
        {
            surveys[idx].combo_items.push(null);
        }
        surveys[idx].combo_items[id] = {
            'text': target.value,
            'removed': false
        };
        this.setState({'surveys': surveys});
    }


    removeComboItem(idx, itemId) {
        let surveys = this.state.surveys;
        // surveys[idx].radio_items.splice(itemId, 1);
        surveys[idx].combo_items[itemId].removed = true;
        this.setState({'surveys': surveys});
    }       

    questionComboAnswer(idx) {
        let survey = this.state.surveys[idx];
        let removed = 0;
        let items = survey.combo_items.map((item, id) => {
            if (item && item.removed) {
                removed = removed +1;
                return null;
            }

            let removeBtn='';
            if( id != survey.combo_items.length-1)
                removeBtn = (<a className="survey_change-btn-small" onClick={this.removeComboItem.bind(this, idx, id)}>х</a>);
            let index = "question_"+idx+"_combo_"+id;
            return (
                <div className="check-wrap">
                    <label>{id+1-removed}. </label>
                    <input type="text" className="text-field survey_combo_title" 
                        id={index} name={index} 
                        placeholder={item === null ? 'Добавить вариант' : 'Вариант ответа'} 
                        defaultValue={item == null ? '' : item.text} 
                        onChange={(e) => this.changeComboItem(e.target, idx, id)}/>
                    {removeBtn}
                    
                </div>

            );
        })
        return(
            <div>
                {items}
            </div>
        );
    }

    changeStartRange(target, idx) {
        let surveys = this.state.surveys;
        surveys[idx].min_range = target.value;
        this.setState({'surveys': surveys});
    }
    changeEndRange(target, idx) {
        let surveys = this.state.surveys;
        surveys[idx].max_range = target.value;
        this.setState({'surveys': surveys});
    }

    changeStartRangeTitle(target, idx) {
        let surveys = this.state.surveys;
        surveys[idx].min_title = target.value;
        this.setState({'surveys': surveys});
    }
    changeEndRangeTitle(target, idx) {
        let surveys = this.state.surveys;
        surveys[idx].max_title = target.value;
        this.setState({'surveys': surveys});
    }


    questionRangeAnswer(idx){
        let survey = this.state.surveys[idx];
        return (
            <div>
                <div className="check-wrap">
                    Диапазон:
                </div>
                <div className="check-wrap">
                    <div className="col-md-2">
                        <div className='select-field-holder'>
                            <select className="custom-select" id="start-time" name="start-time"
                                                            data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'
                                                            onChange={(e) => this.changeStartRange(e.target, idx)}
                                                            defaultValue={survey.min_range}>
                                                        {this.rangeStartOptions()}
                                                    </select>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className='select-field-holder'>
                            <select className="custom-select" id="start-time" name="start-time"
                                                            data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'
                                                            onChange={(e) => this.changeEndRange(e.target, idx)}
                                                            defaultValue={survey.max_range}>
                                                        {this.rangeEndOptions()}
                                                    </select>
                        </div>
                    </div>
                </div>
                <div className="check-wrap">
                    <label>{survey.min_range}. </label>
                    <input type="text" className="text-field survey_range_title" name="min_title" placeholder='Подпись (необязательно)'
                    defaultValue={survey.min_title}
                    onChange={(e) => this.changeStartRangeTitle(e.target, idx)}/>
                </div>
                <div className="check-wrap">
                <label>{survey.max_range}. </label>
                    <input type="text" className="text-field survey_range_title" name="max_title" placeholder='Подпись (необязательно)'
                    defaultValue={survey.max_title}
                    onChange={(e) => this.changeEndRangeTitle(e.target, idx)}/>
                </div>                
            </div>
        );        
    }

    renderQuestion(idx) {
        // console.log("renderQuestion " + idx);
        let survey = this.state.surveys[idx];
        let preview = '';
        // 'Короткий текст', 'Длинный текст', 'Один вариант', 'Несколько вариантов', 'Выподающий список', 'Оценка по шкале', 'Да/Нет','Загрузка файлов' ]
        if (survey.question_type == 'Короткий текст') {
            preview = this.questionShortAnswer();
        }
        else if (survey.question_type == 'Длинный текст') {
            preview = this.questionLongAnswer();
        }
        else if (survey.question_type == 'Один вариант') {
            preview = this.questionRadioAnswer(idx);
        }
        else if (survey.question_type == 'Несколько вариантов') {
            preview = this.questionCheckboxAnswer(idx);
        }
        else if (survey.question_type == 'Выподающий список') {
            preview = this.questionComboAnswer(idx);
        }
        else if (survey.question_type == 'Оценка по шкале') {
            preview = this.questionRangeAnswer(idx);
        }
        else if (survey.question_type == 'Да/Нет') {
            preview = this.questionYesNoAnswer(idx);
        }
        else if (survey.question_type == 'Загрузка файлов') {
            preview = this.questionFileAnswer(idx);
        }

        return (
            <div id={"question-"+idx} className='surver-question-block'>
                {this.questionTitle(idx)}
                {preview}
                {this.questionRequired(idx)}
            </div>
        );
    }

    renderQuestions() {
        // console.log("Begin render " + this.state.surveys);
        let surveys = this.state.surveys.map((survey, idx) => {
            // console.log("render survey " + survey);
            if (survey.removed) {
                return null;
            }
            return this.renderQuestion(idx);
        })
        return surveys;

    }
    

    render() {
        if (this.state.surveys == null) {
            return null;
        }

        return (
            <Modal>
                <div className="title-holder">
                    <div className="title-frame">
                        <h2>Конструктор анкеты</h2>
                    </div>
                </div>                
                <div className="modal-content">
                    <div className="select-survey-table">
                        {this.renderQuestions()}
                        <a className="survey_change-btn-add" onClick={() => {
                            let surveys = this.state.surveys;
                            surveys.push(JSON.parse(JSON.stringify(this.nodeTemplate)));
                            this.setState({'surveys': surveys});
                        }}>Добавить вопрос</a> 
                    </div>

                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveSurvey.bind(this)}>Сохранить</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default withDispatch(TestsSurveyCreateForm);
// export default withRouter(withScrollHandler(TestsSurveyCreateForm, 'test'));