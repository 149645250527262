import React from 'react';
import Modal from './modal.jsx';

class Workflow extends React.Component {

	constructor(props) {
		super(props);
		this.props = props
        this.state = {
            open: {}
        }		
	}

	renderStepColumn(step, index, currentIndex){
		let state = 'done';
		if(index == currentIndex)
			state = 'current';
		else if(index > currentIndex)
			state = 'next';

		return (
			<div className='workflow-title-row'>
				<span className={"workflow-state-"+state}>{step}</span>
			</div>
		)
}
	renderStepIcons(step, index, currentIndex, total){
		let state = 'done';
		if(index == currentIndex)
		{
			if(index < total - 1)
			{
				state = 'current';
			}
			else 
			{
				state = 'done';
			}
		}
		else if(index > currentIndex)
			state = 'next';
		return (
			<div className='workflow-icons-row'>
				<span className={"workflow-icon-"+state}></span>
				<span className={'workflow-line-'+state} style={{display: (index < total -1 )? 'block' :'none' }}></span>
				<span className={'workflow-point-'+state} style={{display: (index < total -1 )? 'block' :'none' }}></span>
				<span className={'workflow-line-'+state} style={{display: (index < total -1 )? 'block' :'none' }}></span>
			</div>
		)
	}

	renderResponsibleItem(item, index) {
		if(item['Department'] == null) 
			return null

		return (
			<div>
				<div className='workflow-responsible-func'
					style={{display: item['Department'] != '-' ? 'block' :'none' }}
					>{item['Department']}</div>
				{
					item['Users'].map((user, index2) => 
					{
						let role = '';
						let roleClass = '';

						if(user['role'] == 'moderator_cf') {
							role = 'ЦФ'
							roleClass = 'moderator_comment_cf'
						}
						else if(user['role'] == 'moderator_mr') {
							role = 'МР'
							roleClass = 'moderator_comment_mr'
						}
						// else if(user['role'] == 'moderator') {
						// 	role = 'М'
						// 	roleClass = 'moderator_comment'
						// }
	
						return <div className='workflow-responsible-name'>
								{user['name']}
								<span 
									style={{display: roleClass != '' ? 'block' :'none' }}
								 	className={roleClass}>
										{role}
								</span>
						</div>
					})
				}
			</div>
		)
	}

	renderResponsibleColumn(resp, index) {
		function onClick(pThis, index) {
			let state = pThis.state.open;
			state[index] = !state[index];
			pThis.setState({open: state}); 
			var element = document.getElementById('step_drop'+index)
			if(state[index])
			{
				element.style.transform = "rotate(180deg)";
			}
			else {
				element.style.transform = "rotate(0deg)";
			}

		}
		return (
			<div>
				{
					resp['Main'].map((item, index2) => {
						return (<div> { this.renderResponsibleItem(item) }</div>)
					})
				}
				<span className='workflow-responsible-title'
					onClick={() => { 
						let state = this.state.open;
						state[index] = !state[index];
						this.setState({open: state}); 
						var element = document.getElementById('step_drop'+index)
						if(state[index])
						{
							element.style.transform = "rotate(180deg)";
						}
						else {
							element.style.transform = "rotate(0deg)";
						}
						// console.log();
					}}
				
				style={{display: resp.Other.length>0? 'block' :'none' }}>Дополнительно 
				<span className='workflow-responsible-drop'>
					<img id={'step_drop'+index} src='/static/img/step_down.svg' />
				</span>
				</span>
				{
					resp['Other'].map((item, index2) => {
						if( this.state.open[index]) {
							return (<div> { this.renderResponsibleItem(item) }</div>)
						}
					})
				}
			</div>
		)
	}

	renderResponsible(resp, index) {
		return (
			<div className='workflow-responsible-row'>
				<span className='workflow-responsible-title' style={{display: resp.Main.length>0? 'block' :'none' }}>Список ответственных</span>
				{this.renderResponsibleColumn(resp, index)}
			</div>
		);
	}

	renderSteps() {
		if (this.props.workflow == null) {
			return null;
		}
		let currentIndex = 100;
		this.props.workflow.Steps.map((step, index) => 
		{
			if(step == this.props.workflow.Current)
				currentIndex = index;
		})

		return (
			<div className="workflow-table">
				<div className="workflow-row">
						{
							this.props.workflow.Steps.map((step, index) => 
							{
								return this.renderStepColumn(step, index, currentIndex)
							})
						}
				</div>
				<div className="workflow-row-1">
					{
						this.props.workflow.Steps.map((step, index) => 
						{
							return this.renderStepIcons(step, index, currentIndex, this.props.workflow.Steps.length)
						})
					}
				</div>
				<div className="workflow-row-2">
					{ 
						this.props.workflow.Steps.map((step, index) => 
						{
							return this.renderResponsible(this.props.workflow.Responsible[step], index)
						})
					
					}
				</div>
			</div>			
		)
	}

	render() {
        return (
			<div>
				{ this.renderSteps() }
			</div>
		)

	}
}

export default Workflow;
