import React from 'react';
import Attachments from './attachments.jsx';
import CommentForm from './comment_form.jsx';
import Markup from './markup.jsx';
import Comment from './comment.jsx';

class Comments extends React.Component {

	renderComments() {
		if (this.props.object == null) {
			return null;
		}
		return this.props.object.Comments.map((comment, index) => {
			return (<Comment key={index} comment={comment}/>);
		});
	}

	render() {
		console.log(this.props.object.CanComment);
		return (
				<div className="comments" id="comments" style={!(this.props.object.CanComment || this.props.object.CanComment == undefined) ? {'display':'none'} : {}}>
					<h2>Комментарии</h2>

					<div className="comments__items">
						{ this.renderComments() }
						<div className="comments__item comment-add">
							<div className="comments__item-ava"><i className="ico icon-user-drop"/></div>
							<div className="comments__item-content" >
								<CommentForm app={this.props.app} onClick={this.props.onSendComment}/>
							</div>
						</div>

					</div>
				</div>
		)
	}
}

export default Comments;
