import React from 'react';
import Modal from './modal.jsx';
import SelectBox from './select_box.jsx';


class ResponsibleTestAssign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            'responsibles': [...props.responsibles, {
                user_title: '',
                can_delete: true
            }],
			'userList': props.users,
            'confidential': props.confidential ? 'on' : ''
		}
        console.log('state', this.state);
	}


    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));

    }

    removeResponsible(idx) {
        let responsibles = this.state.responsibles;
        responsibles[idx].removed = true;
        this.setState({'responsibles': responsibles});
    }

    responsibleOptions() {
		if(!this.state.userList)
			return {}

        return this.state.userList;
    }

    responsibleChange(target, idx) {
        var options = this.responsibleOptions();
        let responsibles = this.state.responsibles;
        responsibles[idx].user_id = target;
        responsibles[idx].user_title = options[target];
        this.setState({'responsibles': responsibles})
		console.log(this.state.responsibles);

    }


	save() {
        console.log('state on save', this.state);
		this.props.onSave(this.state.responsibles.filter((elem) => !elem.removed && 'user_id' in elem), this.state.confidential);
	}


    render() {
        console.log(this.state.responsibles);
        if (this.state.responsibles == null) {
            return null;
        }
        let responsibles = this.state.responsibles.map((responsible, idx) => {
            let btn = null;
            if (responsible.removed) {
                return null;
            }
            if (responsible.can_delete) {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell-wide">
                            <div className="select-field-holder text-active">
							<label>Пользователь:</label>
								<SelectBox options={this.responsibleOptions()} defaultValue={responsible.user_id} onChange={(e) => this.responsibleChange(e, idx)}/>

                            </div>							
                        </div>
                        <div className="select-route-delete-btn" style={{'cursor': 'pointer'}}
                             onClick={this.removeResponsible.bind(this, idx)}>Удалить
                        </div>
                    </div>
                );
            } else {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            {responsibles.user_title}
                        </div>
                        <div className="select-route-delete-btn" style={{'visibility': 'hidden'}}>Удалить</div>
                    </div>

                );
            }
        })

        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        Пользователи участники
                    </div>
                    <div className="select-route-table">
                        {responsibles}
                    </div>
                    <div className="form-actions">
                        <a className="btn" onClick={() =>
                            this.setState({
                                'responsibles': [
                                    ...this.state.responsibles,
                                    {
                                        user_title: '',
                                        can_delete: true
                                    }]
                            })}>Добавить</a>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.save.bind(this)}>Сохранить</a>
                    </div>
                    <div className="check-wrap select-field-holder">
                        <input type="checkbox" className="checkbox" id="access_confidential"
                            name="access_confidential" defaultChecked={this.state.confidential} onChange={(e) => this.setState({'confidential': e.target.checked ? 'on': ''})}/>
                        <label htmlFor="access_confidential" className="checkbox-label" >Конфиденциально - Тест/опрос виден и доступен для прохождения только выбранным участникам. Если не устанавлена данная опция, то тест/опрос виден всем в общем списке «Все», но доступен для прохождения только выбранным участникам.</label>
                    </div>
                    <div style={{marginBottom:'10px', marginTop:'50px'}} ></div>

                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default ResponsibleTestAssign;
