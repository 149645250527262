import React from 'react';
import Header from './header.jsx'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import TestsContent from './tests_content.jsx';
import Modal from './modal.jsx'

class Tests extends React.Component {
	constructor() {
		super();
		let filters = sessionStorage.getItem("test_filters");
		let objFilters = {}
		if (filters) {
			objFilters = JSON.parse(filters);
		}
        let sort = sessionStorage.getItem("test_sort");
        if (!sort) {
            sort = 'title_desc';
        }

		this.state = {
			'filter_window': false,
            'sort': sort,
			'filters': objFilters,
			'ts': new Date().getTime(),
            'selected_column': {
                'by_title': '',
                'by_startdate': '',
                'by_enddate': ''
            }

		}
	}

	reloadChildren() {
		this.setState({'ts': new Date().getTime()})
	}

    changeSort(value) {
        if(value.tagName == 'SPAN') {
            value = value.parentElement;
        }
        value = value.id;
        value = value.replace('h_', '');
        console.log(value);
        sessionStorage.setItem("test_sort", value);
        
        let direction = sessionStorage.getItem("test_sort_direction");
        if (!direction) {
            direction = 'desc';
        }
        else {
            if(direction == 'desc') {
                direction = 'asc';
            } else {
                direction = 'desc'
            }
        }
        sessionStorage.setItem("test_sort_direction", direction);

        this.setState({"sort": value.replace('by_','')+"_"+direction});
        console.log(value+"_"+direction);


        for(var key in this.state.selected_column) {
            this.state.selected_column[key] = '';
        }

        this.state.selected_column[value] = 'sortable_column_active';
    
    }	

	regionOptions() {
		return this.props.app.storage.regions.map((r)=> {
			return (
				<option value={r.ID} key={r.ID}>{r.Title}</option>
			);
		})

	}

	title() {
		if (this.props.page == 'decide')
			return "Тесты/опросы в работе";
		else if (this.props.page == 'all')
			return "Все активные тесты/опросы";
		else if (this.props.page == 'finished')
			return "Завершенные тесты/опросы";
		else if (this.props.page == 'created')
			return 'Созданные мной тесты/опросы';
		else
			return "Доступно для участия";
	}

	page() {
		if (this.props.page == 'decide')
			return "Decide";
		else if (this.props.page == 'created')
			return "Create";
		else if (this.props.page == 'finished')
			return "Finished";
		else if (this.props.page == 'all')
			return "All";
		else
			return "Available";
	}

	filterDisplay() {
		if (this.page() == 'All' || this.page() == 'Finished') {
			return {}
		} else {
			return {'display': 'none'}
		}

	}

	openFilters() {
		this.setState({'filter_window': true})
	}

	closeFilters() {
		this.setState({'filter_window': false})
	}

	saveFilters() {
		let data = $('form#filters-form').serializeArray();
		let filterObj = {}
		data.forEach((item) => {
			if (item.value != "") {
				filterObj[item['name']] = item['value'];
			}
		});

		this.setState({'filter_window': false, 'filters': filterObj})
		sessionStorage.setItem('test_filters', JSON.stringify(filterObj));

	}

	clearFilters() {
		$('#filters-form').find('.form-field').val('');
		$('#filters-form').find('.custom-select').each((i, sel) => {
			jcf.getInstance($(sel)).refresh();
		});
		$('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
		sessionStorage.setItem('test_filters', null);
	}

	filtersCounter() {
		let size = 0;
    	for (var key in this.state.filters) {
        	if (this.state.filters.hasOwnProperty(key)) size++;
    	}
    	if (size > 0) {
    		return (
    			<em className="state-indicator state-indicator--red">{size}</em>
    		)
    	} else {
    		return null;
    	}
	}

	renderFilters() {
		if (this.state.filter_window)
			return (
				<Modal>
				  <div className="title-holder">
				     <div className="title-frame">
				        <h2>Фильтр</h2>
				     </div>
				  </div>
				  <div className="modal-content">
				     <form action="" id="filters-form">
				     	<div className="row">
				     		<div className="col-md-4">
				     			<div className="text-field-holder">
				     			 <label>Дата создания, от</label>
				     			 <input type="text" name="date_from" className="text-field hasDatepicker form-field" defaultValue= {this.state.filters['date_from']} readOnly/>
				     			 <i className="ico icon-calendar"></i>
				     			</div>
				     		</div>
				     		<div className="col-md-4">
				     			<div className="text-field-holder">
				     			 <label>Дата создания, до</label>
				     			 <input type="text" name="date_to" className="text-field hasDatepicker form-field" defaultValue={this.state.filters['date_to']} readOnly/>
				     			 <i className="ico icon-calendar"></i>
				     			</div>
				     		</div>
				     	</div>
						<div className="check-wrap" style={{'display': 'none'}}>
				     	  <input type="checkbox" className="checkbox" id="check10"/>
				     	  <label htmlFor="check10" className="checkbox-label">Показать только важные</label>
				     	</div>
				     	<div className="select-field-holder text-active">
				     	 <label>Регион:</label>
				     	 <select className="custom-select form-field" name="region_id" defaultValue={this.state.filters['region_id']}>
				     	 	<option value="">Все</option>
				     	   { this.regionOptions() }
				     	 </select>
				     	</div>
				     	<div className="text-field-holder" style={{'display':'none'}}>
				     	    <label htmlFor="someField">Автор</label>
				     	    <input type="text" className="text-field" id="someField" name="nameField"/>
				     	</div>
				     </form>
				  </div>
				  <div className="modal-actions">
				  		<a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
				        <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
				        <a className="link--underline modal-reset pull-right" onClick={this.clearFilters.bind(this)}>Сбросить всё</a>
				    </div>
				  <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
				</Modal>
			);
		else
			return null;
	}

	render() {
		return (
			<div>
				<header>
					<Header app={this.props.app} ts={this.state.ts}/>
					<div className="header__title">
						<div className="container">
						Тесты
						<div className="text-wrap header__search" style={{'display': 'none'}}>
							<input type="text" className="text" id="search-text" placeholder="Поиск по тестам" autoComplete="off"/>
							<i className="ico icon-search"/>
						</div>
					</div>
					</div>
				</header>
				<section className="wrapper">
					<div className="current-section-menu">
						<div className="current-section-menu-inner">
							<div className="section-menu-line">
								<div className="container">
									<ul>
										<li>
											<Link id="test_all" to="/tests/all">Все</Link>
										</li>
										<li>
											<Link id="test_available" to="/tests">Доступные</Link>
										</li>
										<li>
											<Link id="test_created" to="/tests/created">Мой вклад</Link>
										</li>
										<li>
											<Link id="test_decide" to="/tests/decide">Участвую</Link>
										</li>
										<li>
											<Link id="test_finished" to="/tests/finished">Завершенные</Link>
										</li>

										<li className="create_test">
											<Link id="test_new"  to="/tests/new"><i className="ico icon-add"/>Создать тест	</Link>
										</li>
									</ul>
									<div className="select-field-holder text-active" style={{display:'none'}}>
									 <label>Сортировать:</label>
									 <select className="custom-select">
									   <option value="1">Сначала новые</option>
									   <option value="2">Сначала старые</option>
									 </select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="page__head">
							<div className="row">
								<div className="col-md-7">
									<h1>{this.title()}</h1>
								</div>
								<div className="col-md-7">
									<ul className="page__filter">
										<li className="page__filter-item page__filter-important filter-on">
											<span className="page__filter-ico"><img src="static/img/star.svg" alt=""/></span>
											<a className="js-check-important" href="#">Отметить важное</a>
										</li>
										<li className="page__filter-item page__filter-remove filter-on">
											<span className="page__filter-ico"><img src="static/img/trash.svg" alt=""/></span>
											<a href="#">Удалить</a>
										</li>
										{/*
										<li className="page__filter-item page__filter-excel">
											<span className="page__filter-ico"><i className="ico icon-download"/></span>
											<a href="#">Экспортировать в Excel</a>
										</li>
										*/}
										<li className="page__filter-item page__filter-filter" style={this.filterDisplay()}>
											<span className="page__filter-ico"><i className="ico icon-filter"/></span>
											<a className="fancy" onClick={this.openFilters.bind(this)}>Фильтры {this.filtersCounter()}</a>
											<i className="ico icon-expander-arrow"/>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="table-responsive">
			                <div className="table table-hover">
			                    <div>
			                        <div>
                                        <div className={"col-table-4 sortable_column " + this.state.selected_column['by_title']} 
                                            id="h_by_title" onClick={(e) => this.changeSort(e.target)}>
                                                Название 
                                            <img style={this.state.selected_column['by_title'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("test_sort_direction") + ".png"} 
                                                id="by_title" alt=''/>
                                        </div>                                        
                                        <div className={"col-table-4 sortable_column " + this.state.selected_column['by_startdate']} 
                                            id="h_by_startdate" onClick={(e) => this.changeSort(e.target)}>
                                                Начало 
                                            <img style={this.state.selected_column['by_startdate'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("test_sort_direction") + ".png"} 
                                                id="by_startdate" alt=''/>
                                        </div>                                        

                                        <div className={"col-table-4 sortable_column " + this.state.selected_column['by_enddate']} 
                                            id="h_by_enddate" onClick={(e) => this.changeSort(e.target)}>
                                                Окончание 
                                            <img style={this.state.selected_column['by_enddate'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("test_sort_direction") + ".png"} 
                                                id="by_enddate" alt=''/>
                                        </div>                                        
			                            <div className="col-table-1_5 ">Участники</div>
			                        </div>
			                    </div>
			                    <TestsContent app={this.props.app} sort={this.state.sort} page={this.page()} filters={this.state.filters}/>
			                </div>
			             </div>
			        </div>
				</section>
				{ this.renderFilters() }
			</div>
			);

	}

}


export default Tests;
