import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withRouter} from 'react-router-dom'
import withScrollHandler from './withScrollHandler.jsx';

class ImprovementsContent extends React.Component {
	constructor() {
		super();
		this.state = {
			'improvements': []
		};
	}

	queryParams(props, last_id) {
		let params = { 'pageSelected': props.page, 'count': 10, 'last_id': last_id };
		for (var key in props.filters) {
        	if (props.filters.hasOwnProperty(key)) {
        		params[key] = props.filters[key];
        	}
    	}
    	console.log(params);
    	return params;
	}

	loadData(props, last_id, scrollBack) {
		this.props.app.api('GET', '/api/problems', this.queryParams(props, last_id), (data)=> {
			if (last_id) {
				this.setState({'improvements': this.state.improvements.concat(data)});
			} else {
				this.setState({'improvements': data});
			}
			if (scrollBack) {
					scrollBack();
			}
		}, ()=> { console.log('error')})
	}

	onScrolledToEnd(scrollBack) {
				if (this.state.improvements.length > 0) {
					let last_id = this.state.improvements[this.state.improvements.length - 1]._id;
					this.loadData(this.props, last_id, scrollBack);
			}
  }


	componentWillMount() {
		this.loadData(this.props)
	}



	componentWillReceiveProps(new_props) {
		if (new_props.page != this.props.page || new_props.filters != this.props.filters || new_props.page != this.props.page) {
			this.loadData(new_props)
		}
	}

    toggleFavorite(item) {
        let method = item.Favorite ? 'DELETE' : 'POST';
        this.props.app.api(method, '/api/problems/' + item._id + '/fav', {}, (data) => {
            item.Favorite = method !== 'DELETE'
        })
    }

    renderFavoriteButton(item) {
        return (
            <a className="edit-button" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggleFavorite(item);
                e.target.src = item.Favorite ? "/static/img/star.svg" : "/static/img/star_a.png"
            }}>
                <img key={item._id} style={{marginBottom: "-10px", marginLeft: "25px", display: "table-cell"}} src="/static/img/star_a.png" alt=''/>
            </a>
        )
    }	

	rows() {

		let formatDate = (iso_date) => {
			let date= new Date(iso_date);
			let year = date.getFullYear();
			let month = date.getMonth()+1;
			let dt = date.getDate();

			if (dt < 10) {
			  dt = '0' + dt;
			}
			if (month < 10) {
			  month = '0' + month;
			}

			return dt + '.' + month + '.' + year
		}
		return this.state.improvements.map((item)=> {
			return (
				<a href={"/improvements/show/" + item._id} key={item._id} className="ideas__item" onClick={(e) => {e.preventDefault(); this.props.history.push("/improvements/show/" + item._id); }}>
                    <div>
                    	<div className="check-wrap" style={{display:'none'}}>
                    	  <input type="checkbox" className="checkbox ideas-checkbox" id="check01"/>
                    	  <label htmlFor="check01" className="checkbox-label"></label>
                    	</div>
						<div className="ideas__name">{item.Title}</div>
                    </div>
                    <div>{item.Route.Title}</div>
                    <div>{item.Address.Region.Title}</div>
                    <div>{formatDate(item.Created)}</div>
                    <div>
                    	<div className="ideas__status">{item.ProblemStatus}</div>
                    	<div className="ideas__performer">{item.Author.Name}</div>
                    	{/* <div className="check-wrap check-favourites" style={{display:'none'}}>
                    	  <input type="checkbox" className="checkbox favour-checkbox" id="favour01"/>
                    	  <label htmlFor="favour01" className="checkbox-label"></label>
                    	</div> */}
                    </div>
					<object style={this.props.page === 'Favorite' ? {} : {display: 'none'}} type="owo/uwu">
							{this.renderFavoriteButton(item)}
					</object>
				</a>
			);
		});
	}


	render() {
		return (
		<div>
			{ this.rows() }

		</div>
		);
	}

}

export default withRouter(withScrollHandler(ImprovementsContent, 'improvements'));
