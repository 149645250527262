import { combineReducers } from 'redux'
import files from './files'
import modal from './modal'
import comment from './comment'
//import visibilityFilter from './visibilityFilter'

export default combineReducers({
    files,
    modal,
    comment
//  todos,
//  visibilityFilter
})
