import React from 'react';

import Telecoin from './telecoin.jsx'

class RatingPlaces extends React.Component {

  render() {
      if (this.props.places && this.props.rewards) {
        let backgrounds = ["#ffd700", "#c0c0c0", "#cd7f32"];
        let places = this.props.places.map((elem, idx, arr)=> {
            return arr.slice(0, idx + 1).reduce((accumulator, currentValue) => accumulator + currentValue);
        });
        let content = this.props.places.filter((x) => x != 0).map((x, index) => {
            let prevPlace = index == 0 ? 1 : places[index - 1] + 1
            let placesDescr = prevPlace == places[index] ? `За ${prevPlace} место` : `За ${prevPlace} - ${places[index]} места`
            return (
              <div key={index} className="rating_reward">
                 <div className="reward tele2short" style={{color: backgrounds[index]}}>
                    <span>{this.props.rewards[index]}</span>
                    <Telecoin background={backgrounds[index]}/>
                 </div>
                 <div className="places">
                    {placesDescr}
                 </div>

              </div>
            )
        });
        return (
          <div className = "rating_places">
              {content}
          </div>)

      } else {
          return null;
      }
  }


}

export default RatingPlaces;
