import React from 'react';

class Points extends React.Component {

    renderIcon(){
      return(<img style={{width: '19px', height: '19px', marginLeft: '5px', marginRight: '5px', marginBottom: '-4px'}} src="/static/img/coin.png"/>);
    }
    renderReward(reward){
      return(<span>{reward}</span>);
    }
    render() {
      // console.log(this.props.action);
      if(!this.props.app.storage.rewards.hasOwnProperty(this.props.action))
        return null;
      let reward = this.props.app.storage.rewards[this.props.action];
      if (!reward)
        return null;
      return(
          <span style={this.props.style}>
            {this.renderIcon()}
            {this.renderReward(reward)}
          </span>
        );
    }

}

export default Points;