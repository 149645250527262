import React from 'react';
import Modal from './modal.jsx';
import withDispatch from './withDispatch.jsx'
import UploadFile from './upload_file.jsx'
import Points from './points.jsx';

class TestsSurveyForm extends React.Component {

    constructor(props) {
        super(props);

        let answers = props.surveyData.map((survey, idx) => {
            return {
                'question_title': survey.question_title,
                'question_type': survey.question_type,
                'answer': null
            }
        })

        let surveys = props.surveyData.map((survey, idx) => {
            if (survey.question_type == 'Да/Нет') {
                survey.question_type = 'Один вариант';
                survey.radio_items = [{'text':'Да', 'removed': false}, {'text':'Нет', 'removed': false}];
            }
    
            return survey;
        })


        this.state = {
            'surveys': surveys,
            'answers': answers
        }
    }

    componentDidUpdate() {
        $.initializeForm($('.select-survey-table'));
    }
   
    saveSurvey() {
        let errors = [];
        let surveys = this.state.surveys;

        console.log(this.state.answers);
        let answers = this.state.answers.map((answer, idx) => {
            if (answer.question_type == 'Загрузка файлов') {
                let id = "survey_files_"+idx;

                let files = []

                $('form#'+id+ ' img.file-image').each((idx, elem) => {
                    let data = JSON.parse(elem.attributes.data.value);
                    files.push({
                        'data': data.data,
                        'name': data.name
                    });
                });
                answer.answer = files;
            }
            
            if(surveys[idx].is_required && answer.answer == null) {
                errors.push(idx);
            }
    
            return answer;
        })

        console.log(answers);
        if(errors.length == 0) {
            this.props.onSave(answers);
        } else {
            this.state.surveys.map((survey, id) => {
                if(errors.includes(id)) {
                    this.state.surveys[id].error = true;
                }
                else {
                    this.state.surveys[id].error = false;
                }
            }); 

            //to refresh page
            this.setState({'answers': this.state.answers});
        }
    }

    questionTitle(idx){
        return (
            <div key={"question-tittle-"+idx} className="select-survey-row">
                <div className="select-survey-cell-title">
                    <h3>{this.state.surveys[idx].question_title}</h3>
                </div>
            </div>            
        );      
    }

    questionRequired(idx) {
        let survey = this.state.surveys[idx];

        let id = "question_required_" + idx;
        return(
            <div className='survey_required' style={survey.error ? {'display': 'block'}:{'display': 'none'}}>
                <label htmlFor={id}>Обязательный вопрос</label>
            </div>
        );
    }    

    changeTextAnswer(target, idx) {
        let answers = this.state.answers;
        answers[idx].answer = target.value;
        this.state.surveys[idx].error = false;
        this.setState({'answers': answers});
    }

    questionShortAnswer(index) {
        return(
            <input type="text" className="text-field survey_checkbox_title" 
                                    id={index} name={index} 
                                    placeholder='Введите ответ' 
                                    defaultValue=''
                                    onChange={(e) => this.changeTextAnswer(e.target, index)}/>
        );
    }
    questionLongAnswer(index) {
        return(
            <textarea className="text-field survey_checkbox_title survey_longanswer" 
                id={index} name={index} placeholder='Введите ответ' 
                onChange={(e) => this.changeTextAnswer(e.target, index)}>
            </textarea>                        
        );
    }

    questionFileAnswer(index) {
        let id = "survey_files_"+index;
        return(
            <form action="" id={id}>
                <UploadFile formId={id}/>
            </form>
        );
    }

    changeCheckboxItem(target, idx, id) {
        function removeItem(arr, value) {
            var index = arr.indexOf(value);
            if (index > -1) {
              arr.splice(index, 1);
            }
            return arr;
        }
    
        let answers = this.state.answers;
        let surveys = this.state.surveys;


        if(answers[idx].answer == null)
        {
            answers[idx].answer = [];
        }
        var val = surveys[idx].check_items[id].text;
        if(answers[idx].answer.includes(val))
        {
            removeItem(answers[idx].answer, val)
        }
        else {
            answers[idx].answer.push(val);
        }
        this.state.surveys[idx].error = false;
        this.setState({'answers': answers});
        console.log(this.state.answers);
    }


    questionCheckboxAnswer(idx){
        let survey = this.state.surveys[idx];
        let items = survey.check_items.map((item, id) => {
            let index = "question_"+idx+"_check_"+id;
            let group = "question_"+idx+"_radio";
            return (
                <div className="check-wrap">
                    <input type="checkbox" className="checkbox" id={index} name={group}
                        onChange={(e) => this.changeCheckboxItem(e.target, idx, id)}/>
                    <label htmlFor={index} className="checkbox-label">{item.text}</label>
                </div>

            );
            // return (
            //     <div className="check-wrap">
            //         <input type="checkbox" className="checkbox" id="hide_comments"
            //             name="hide_comments"/>
            //         <label htmlFor="hide_comments" className="checkbox-label">Скрыть комментарии</label>
            //     </div>                    
            // );
        })
        return(
            <div>
                {items}
            </div>
        );        
    }

    
    changeRadioItem(target, idx, id) {
        let answers = this.state.answers;

        let survey = this.state.surveys[idx];

        answers[idx].answer = survey.radio_items[id].text;
        this.state.surveys[idx].error = false;
        this.setState({'answers': answers});
        // console.log(this.state.answers);
    }

    questionRadioAnswer(idx){
        let survey = this.state.surveys[idx];
        let items = survey.radio_items.map((item, id) => {

            let index = "question_"+idx+"_radio_"+id;
            let group = "question_"+idx+"_radio";
            return (
                <div className="check-wrap">
                    <input type="radio" className="radio" 
                        id={index} name={group} 
                        onChange={(e) => this.changeRadioItem(e.target, idx, id)}/>
                    <label htmlFor={index} className="radio-label">{item.text}</label>
                </div>
            );
        })
        return(
            <div>
                {items}
            </div>
        );
    }

    changeComboItem(target, idx ) {
        let answers = this.state.answers;

        if(target.value != null && target.value != '')
        {
            answers[idx].answer = target.value;
            this.state.surveys[idx].error = false;
        }
        else {
            answers[idx].answer = null;
        }

        this.setState({'answers': answers});
        // console.log(this.state.answers);
    }


    questionComboAnswer(idx) {
        let survey = this.state.surveys[idx];
        let items = survey.combo_items.map((item, id) => {
            let index = "question_"+idx+"_combo_"+id;
            return (
                <option value={item.text} key={index}>{item.text}</option>

            );
        })
        return(
            <div className="select-field-holder text-active">
                <select className="custom-select" name="route_id" id="select-route"
                        // onChange={this.updateTypes.bind(this)}
                        onChange={(e) => this.changeComboItem(e.target, idx)}>
                <option value={null} key={"question_"+idx+"_combo_def"}></option>
                {items}
                </select>
            </div>
        );
    }

    changeRangeSelector(idx, value ) {
        let answers = this.state.answers;

        answers[idx].answer = value;
        this.state.surveys[idx].error = false;
        this.setState({'answers': answers});
        // console.log(this.state.answers);
    }

    questionRangeAnswer(idx){
        let survey = this.state.surveys[idx];
        let answers = this.state.answers;

        let items = [];
        for (let i = survey.min_range; i <= survey.max_range; i++) {
            let selected = answers[idx].answer === i;
            let classes = "surver-range-selector_item";
            if(selected) {
                classes += " surver-range-selector_item_selected";
            }
            items.push(<li className={classes} onClick={this.changeRangeSelector.bind(this, idx, i)}>{i}</li>);
        }
        return (
            <div className="surver-range-container">
                <div className="surver-range-selector surver-range-selector_black">
                    <div className='surver-range-start-title'>{survey.min_title}</div>
                    <ul className="surver-range-selector_list">
                        {items}
                    </ul>
                    <div className='surver-range-end-title'>{survey.max_title}</div>
                </div>
            </div> 
        );        
    }

    renderQuestion(idx) {
        // console.log("renderQuestion " + idx);
        let survey = this.state.surveys[idx];
        let preview = '';
        // 'Короткий текст', 'Длинный текст', 'Один вариант', 'Несколько вариантов', 'Выподающий список', 'Оценка по шкале', 'Да/Нет','Загрузка файлов' ]
        if (survey.question_type == 'Короткий текст') {
            preview = this.questionShortAnswer(idx);
        }
        else if (survey.question_type == 'Длинный текст') {
            preview = this.questionLongAnswer(idx);
        }
        else if (survey.question_type == 'Один вариант') {
            preview = this.questionRadioAnswer(idx);
        }
        else if (survey.question_type == 'Несколько вариантов') {
            preview = this.questionCheckboxAnswer(idx);
        }
        else if (survey.question_type == 'Выподающий список') {
            preview = this.questionComboAnswer(idx);
        }
        else if (survey.question_type == 'Оценка по шкале') {
            preview = this.questionRangeAnswer(idx);
        }
        else if (survey.question_type == 'Загрузка файлов') {
            preview = this.questionFileAnswer(idx);
        }

        return (
            <div id={"question-"+idx} className='surver-question-block'>
                {this.questionTitle(idx)}
                {preview}
                {this.questionRequired(idx)}
            </div>
        );
    }

    renderQuestions() {
        // console.log("Begin render " + this.state.surveys);
        let surveys = this.state.surveys.map((survey, idx) => {
            // console.log("render survey " + survey);
            if (survey.removed) {
                return null;
            }
            return this.renderQuestion(idx);
        })
        return surveys;

    }
    

    render() {
        if (this.state.surveys == null) {
            return null;
        }

        return (
            <Modal>
                <div className="title-holder">
                    <div className="title-frame">
                        <h2>Пройдите опрос</h2>
                    </div>
                </div>                
                <div className="modal-content">
                    <div className="select-survey-table">
                        {this.renderQuestions()}
                    </div>

                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveSurvey.bind(this)}>Отправить <Points action='survey' app={this.props.app}/></a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default withDispatch(TestsSurveyForm);
