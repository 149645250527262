import React from 'react';
import Header from './header.jsx'
import { Route, Redirect } from 'react-router'
import {withRouter} from 'react-router-dom'
import { Link } from 'react-router-dom';
import MarketPrize from './market_prize.jsx'
import withDispatch from './withDispatch.jsx'


class PrizeDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      obj: {}
    }
  }

  componentDidMount() {
  	this.props.app.api('GET', '/api/market/purchases/' + this.props.match.params.id, {}, (data) => {
  		this.setState({'obj': data})

  	})
  }

  doCancel() {
    this.props.dispatch({type: 'MODAL_DISMISS'});
    this.props.app.api('DELETE', '/api/market/purchases/' + this.props.match.params.id, {}, () => {
      this.props.history.push("/prizes")
    })
  }

  cancelClick() {
    this.props.dispatch({
        type: 'MODAL_SHOW',
        modal: {
            header: 'Хотите отменить покупку?',
            message: [this.state.obj.Name],
            buttons: [{name: 'cancel', title: 'Отменить покупку', default: true, onClick: this.doCancel.bind(this)}, {name: 'close', title: 'Закрыть'}]
        }
    })

  }

  renderContent() {
      if (this.state.obj.CanCancel) {
          return (<div className="prize_details_status">
              <div className="caption">Идет подготовка к отправке</div>
              <div className="description">Вы можете отменить заказ в течение часа</div>
              <div className="prize_button" onClick={this.cancelClick.bind(this)}>Отменить заказ</div>
          </div>);
      } else {
        return (<div className="prize_details_status">
            <div className="caption">Заказ передан в отправку</div>
            <div className="description">Обменивайте больше ресурсов на ценные призы</div>
        </div>);

      }
  }

  renderData(){
      if (!this.state.obj.ID) {
          return null;
      }

      return (
        <div className="prize_details_content">
          <div className="image">
            <img src={"/api/market/prizes/"+this.state.obj.PrizeID+"/image.png"} />
          </div>
          {this.renderContent()}
        </div>
      )
  }

  render() {
    return (
        <div>
          <header>
            <Header app={this.props.app}/>
            <div className="header__title">
              <div className="container">
                Призы
              </div>
            </div>
          </header>
          <section className="wrapper">
            <div className="container">
                <div className="prize_details_caption">{this.state.obj.Name}</div>
                {this.renderData()}
            </div>
          </section>
        </div>

    );

  }

}

export default withDispatch(withRouter(PrizeDetails));
