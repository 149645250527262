import React from 'react';
import Modal from './modal.jsx';
import SelectBox from './select_box.jsx';


class RouteTestAssign extends React.Component {

    

    constructor(props) {
        super(props);
        this.state = {
            'routes': [...props.routes, {
                function_title: '',
                region_title: '',
                can_delete: true
            }]
        }

        function unique(value, index, array) {
            return array.indexOf(value) === index;
        }

        this.allFunctions = []

        let region = this.props.app.storage.regions;
        let functions = [];

        region.forEach((item) => {
            // console.log(item);
            var fn = this.props.app.storage.user_functions[item.ID];
            if(fn){
                functions.push(...fn);
            }
        });

        this.allFunctions = functions.filter(unique);
        // console.log(functions);        
    }

    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));

    }

    removeRoute(idx) {
        let routes = this.state.routes;
        routes[idx].removed = true;
        this.setState({'routes': routes});
    }

    functionOptions(region_id) {
        let functions = []
        if (region_id)
        {
            functions = this.props.app.storage.user_functions[region_id];
        }
        else {
            functions = this.allFunctions;
        }


        let options = {};
        functions.forEach((func, idx) => {
            options[func.ID] = func.Title;
        });

        // console.log(options);
        return options;
    }

    regionOptions() {
        let region = this.props.app.storage.regions


        let options = {};
        region.forEach((reg, i) => {
            if (reg.ID in this.props.app.storage.user_functions)
                options[reg.ID] = reg.Title;
        });

        return options;
    }

    functionChange(target, idx) {
        let routes = this.state.routes;
        var functions = this.functionOptions();

        routes[idx].function_id = target;
        routes[idx].function_title = functions[target];
        this.setState({'routes': routes})

    }

    regionChange(target, idx) {
        var regions = this.regionOptions();
        let routes = this.state.routes;
        routes[idx].region_id = target;
        routes[idx].region_title = regions[target];
		// console.log(routes);
        this.setState({'routes': routes})

    }

    saveRoutes() {
        this.props.onSave(this.state.routes.filter((elem) => !elem.removed), $('#assign-select-route').val())
    }

    render() {
        if (this.state.routes == null) {
            return null;
        }
        let routes = this.state.routes.map((route, idx) => {
            let btn = null;
            if (route.removed) {
                return null;
            }
            if (route.can_delete) {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Регион:</label>
       						    <SelectBox options={this.regionOptions()} defaultValue={route.region_id} onChange={(e) => this.regionChange(e, idx)}/>

                            </div>
                        </div>
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Департамент:</label>
								<SelectBox options={this.functionOptions(route.region_id)} defaultValue={route.function_id} onChange={(e) => this.functionChange(e, idx)}/>

                            </div>
                        </div>
                        <div className="select-route-delete-btn" style={{'cursor': 'pointer'}}
                             onClick={this.removeRoute.bind(this, idx)}>Удалить
                        </div>
                    </div>
                );
            } else {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            {route.region_title}
                        </div>
                        <div className="select-route-cell">
                            {route.function_title}
                        </div>
                        <div className="select-route-delete-btn" style={{'visibility': 'hidden'}}>Удалить</div>
                    </div>

                );
            }
        })

        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        Регионы и/или департаменты участники
                    </div>
                    <div className="select-route-table">
                        {routes}
                    </div>
                    <div className="form-actions">
                        <a className="btn" onClick={() =>
                            this.setState({
                                'routes': [
                                    ...this.state.routes,
                                    {
                                        function_title: '',
                                        region_title: '',
                                        can_delete: true
                                    }]
                            })}>Добавить</a>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveRoutes.bind(this)}>Сохранить</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default RouteTestAssign;
