import React from 'react';
import Header from './header.jsx'
import {Route, Redirect} from 'react-router'
import {Link} from 'react-router-dom';
import Report from './report.jsx'


class ReportsIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            'list': []
        }
    }

    componentDidMount() {
        this.props.app.api('GET', '/api/reports', {}, (data) => {
            this.setState({'list': data})
        })
    }


    renderItems(items) {
        return items.map((item) => {
            return (
                <div key={item.id} className="row">
                    <div className="col-md-1"/>
                    <div className="col-md-11">
                        <Link to={"/reports/" + item.id}>{item.title}</Link>
                    </div>
                </div>
            );
        })
    }


    renderCategories() {
        return this.state.list.map((cat) => {
            return (
                <div key={cat.title}>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="page__head-title">{cat.title}</h2>
                        </div>
                    </div>
                    {this.renderItems(cat.items)}
                </div>
            );
        })
    }


    render() {
        // console.log(this.props.app.user_data()['role'])
        if (this.props.app.user_data()['role'] === 'user')  {
            return (
                <Redirect to="/"/>
            )
        }

        if (this.props.match.params.id) {
            let report_id = this.props.match.params.id;

            for (var i = 0; i < this.state.list.length; i++) {
                for (var j = 0; j < this.state.list[i].items.length; j++) {
                    if (this.state.list[i].items[j].id == report_id) {
                        let report = this.state.list[i].items[j];
                        return (
                            <div>
                                <header>
                                    <Header app={this.props.app}/>
                                    <div className="header__title">
                                        <div className="container">
                                            {report.title}
                                        </div>
                                    </div>
                                </header>
                                <section className="wrapper">
                                    <div className="container">
                                        <Report report={report} app={this.props.app}/>
                                    </div>
                                </section>
                            </div>

                        )
                    }
                }
            }
        }

        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Отчеты
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        {this.renderCategories()}
                    </div>
                </section>
            </div>

        );

    }

}

export default ReportsIndex;
