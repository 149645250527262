import React from 'react';
import Header from './header.jsx'
import {withRouter} from 'react-router-dom'
import UploadFile from './upload_file.jsx'
import MapSelect from './mapselect.jsx';
import queryString from 'query-string';
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'

import FormDataSmart from './form_data_smart.jsx'


import Modal from './modal.jsx';


class ImprovementForm extends React.Component {

    constructor() {
        super();
        this.state = {
            files: [],
            location: null
        }
    }

    loadObject(props) {
        this.props.setFiles('improvement_form', []);
        if (props.match.params.id) {
            props.app.api('GET', '/api/problems/' + props.match.params.id, {}, (data) => {
                //this.setState({'object': data, 'ts': new Date().getTime()});
                let form = $("form#improvement");
                let setField = (name, value) => {
                    let item = form.find("[name='" + name + "']");
                    item.val(value);
                    item.parent().addClass('text-active');
                    if (item[0].nodeName.toLowerCase() == 'select') {
                        jcf.getInstance(item).refresh();
                    }
                };

                setField('title', data.Title);
                setField('description', data.Description);
                setField('route_id', data.Route.ID);
                this.updateTypes();
                setField('problem_type_id', data.ProblemType.ID);
                setField('test_id', data.TestId);
                setField('address[region_id]', data.Address.Region.ID);
                //setField('address[address]', data.Address.address);
                let lat = parseFloat(data.Address.geo.Lat);
                let lng = parseFloat(data.Address.geo.Long);
                if (!isFinite(lat)) {
                    lat = 55
                }
                if (!isFinite(lng)) {
                    lng = 37
                }
                this.setState({address: data.Address.address, location: [lat, lng]})
                let addFile = (data, name) => {
                    let files = this.props.files['improvement_form'] || []
                    this.props.setFiles('improvement_form', [...files, {data: data, name: name}]);
                }
                data.Attachments.forEach((item, i) => {
                    let path = item.split('/');
                    let name = path[path.length - 1];
                    $.ajax({
                        url: item,
                        xhrFields: {responseType: 'blob'},
                        success: (blobData) => {
                            let reader = new FileReader();
                            reader.readAsDataURL(blobData);
                            reader.onloadend = () => {
                                addFile(reader.result, name)
                            };
                        }
                    })

                });


            }, () => {
                console.log('error')
            })
        }
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        //this.loadObject(new_props);
    }

    componentDidMount() {
        $.initializeForm($('form#improvement'));
        //$("#file-1").fileinput({
        //    theme: 'fas',
        //    language: 'ru',
        //    uploadUrl: '#', // you must set a valid URL here else you will get an error
        //    allowedFileExtensions: ['jpg', 'png', 'gif'],
        //    overwriteInitial: false,
        //    maxFileSize: 1000,
        //    maxFilesNum: 10,
        //    //allowedFileTypes: ['image', 'video', 'flash'],
        //    slugCallback: function (filename) {
        //        return filename.replace('(', '_').replace(']', '_');
        //    }
        //});
    }

    routesOptions() {
        return this.props.app.storage.routes.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )

        })

    }

    getRoute(id) {
        let currentRoute = null;
        this.props.app.storage.routes.forEach((r) => {
            if (r.ID == id) {
                currentRoute = r;
            }
        });
        return currentRoute;
    }


    routeTypesOptions() {
        if (this.props.app.storage.routes.length == 0) {
            return null;
        }

        return this.props.app.storage.routes[0].types.map((t) => {
            return (
                <option value={t.ID} key={t.ID}>{t.Title}</option>
            );

        })
    }

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    onSetAddress(address) {
        document.getElementById('addressField').value = address;
    }

    updateTypes() {
        let selectedRoute = $('#select-route').val();
        let currentRoute = this.getRoute(selectedRoute);
        let selectTypes = $('#select-type');
        let data = "";
        if (currentRoute != null) {
            currentRoute.types.forEach((t) => {
                data += "\x3coption value='" + t.ID + "'\x3e" + t.Title + "\x3c/option\x3e";
            });
        }
        selectTypes.html(data);
        jcf.getInstance(selectTypes).refresh();

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    save() {
        let required = ['title', 'description', 'route_id', 'problem_type_id'];
        let form = $("form#improvement");
        let formData = new FormDataSmart(form, required);
        let [errorInfo, isError] = formData.validate();
        required.forEach((key) => {
            $('[name=' + key + "]").parent().toggleClass('text-error', errorInfo[key]);
        });
        if (isError) {
            return;
        }
        //let formData = new FormData(form[0]);

        $('form#improvement img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });

        let params = queryString.parse(this.props.location.search)
        if (params.test_id) {
            formData.set("test_id", params.test_id);
        }

        if (this.props.match.params.id) {
            formData.set('id', this.props.match.params.id);
        }

        this.props.app.api_post('/api/problems', formData.serialize(), (data) => {
            this.props.setFiles('improvement_form', []);
            this.props.history.push("/improvements/show/" + data.id);
        }, (data) => {
            if (data.responseJSON) {
                if (data.responseJSON.error) {
                    this.props.dispatch({
                        type: 'MODAL_SHOW',
                        modal: {
                            header: 'Ошибка отправки',
                            message: [data.responseJSON.error],
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        }
                    })
                }
            }


        });


    }


    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Улучшения
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page__head-title">{this.props.match.params.id ? 'Редактирование улучшения' : 'Добавление нового улучшения'}</h1>
                                    <div className='new_item_descr'>С помощью Улучшений сотрудники могут сообщать об ошибках и проблемах, которые возникают в точках контакта абонента, или о других проблемах, которые требуют внимания сотрудников. Улучшение используется для устранения конкретной проблемы.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <form action="" id="improvement">
                                    <input type="hidden" name="test_id" value=""/>
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Название</label>
                                        <input type="text" className="text-field" id="someField" name="title"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield01">Описание</label>
                                        <textarea id="somefield01" className="text-field" name="description"></textarea>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Категория:</label>
                                        <select className="custom-select" name="route_id" id="select-route"
                                                onChange={this.updateTypes.bind(this)}>
                                            {this.routesOptions()}
                                        </select>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Тип:</label>
                                        <select className="custom-select" name="problem_type_id" id="select-type">
                                            {this.routeTypesOptions()}
                                        </select>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Регион:</label>
                                        <select className="custom-select form-field" name="address[region_id]"
                                                defaultValue={this.props.app.storage.defaultRegion}>
                                            {this.regionOptions()}
                                        </select>
                                    </div>
                                    <MapSelect onSetAddress={this.onSetAddress.bind(this)}
                                               location={this.state.location} address={this.state.address}/>

                                    <UploadFile formId="improvement_form"/>

                                    <div className="form-actions">
                                        <a className="btn btn--black"
                                           onClick={this.save.bind(this)}>{this.props.match.params.id ? 'Сохранить' : 'Добавить'}</a>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        );
    }
}

export default withDispatch(withFiles(withRouter(ImprovementForm)));
