export default (state = {}, action) => {
    switch (action.type) {
      case 'SET_FILES':
        let new_state = {...state}
        new_state[action.form_id] = action.files;
        return new_state;
        break;
      default:
        return state;
    }

}
