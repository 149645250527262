import React from 'react';
import { Link } from "react-router-dom";

class Markup extends React.Component {


  renderSpan(idx, item) {
      if (this.props.enableHtml) {
          return <span key={idx} dangerouslySetInnerHTML={{ __html: item}}/>
      } else {
          return(<span key={idx}>{item}</span>)
      }
  }

  commentMarkup(text) {
    return text.split(/\n/g).map((x, idx) => {
         const regex = /<color=(#.*?)><link="userId:(.*?)">(.*?)<\/link><\/color>/g;
         let index = -1;
         let tokens = [];
         const f = function(param, color, user, content) {
  	         tokens.push({color: color, user: user, content: content});
             index++;
  	         return "||@" + index + "||"
          }
         const found = x.replaceAll(regex, f);
         const content = found.split("||").map((item, idx) => {
              if (item == "") {
                  return null;
              }
              if (item.startsWith('@')) {
                  const tIdx = parseInt(item.replace("@", ""));
                  if (isNaN(tIdx) || !tokens[tIdx]) {
                      //return <span key={idx}>{item}</span>
                      return this.renderSpan(idx, item);
                  }
                  return <Link style={{color: "#024c17"}} to={"/user/" + tokens[tIdx].user} key={idx}>{tokens[tIdx].content}</Link>
              } else if(item.includes('http')) {
                	var words = item.split(' ');
                	for(var x=0;x< words.length;x++ )
                	{
                		if(words[x].includes('http'))
                			words[x] = "<a href='"+words[x]+"'>"+words[x]+"</a>"
                	}
                	return <span dangerouslySetInnerHTML={{ __html: words.join(' ')}}/>
              
              }
              else {
                  //return <span key={idx}>{item}</span>
                  return this.renderSpan(idx, item);
              }
         }).filter((x) => x != null);

        return (<div key={idx} style={{minHeight: '22px'}}>{content}</div>);

    });

		return {
			__html: text.replace(/\n/g, "<br>")
		}
	}


  render() {
    if (this.props.children == null) {
      return (<div className={this.props.className}/>)
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}} className={this.props.className} style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{this.commentMarkup(this.props.children)}</div>
    );
  }

}

export default Markup;
