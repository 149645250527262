import React from 'react';

class Select extends React.Component {

  constructor() {
    super();
    this.state = {
        open: false,
        hint: ''
    }
  }

  renderOptions() {
      if (this.state.hint == undefined || this.state.hint == '') {
        return null;
      }
      let substr  = this.state.hint.toLowerCase();
      return Object.entries(this.props.options).filter((value) => value[1].toLowerCase().includes(substr)).map((value)=>(
          <div key={value[0]} className="select-option" onClick={()=> {this.setState({open: false, hint: ''}); this.props.onChange(value[0])}}>{value[1]}</div>
      ));
  }

  render() {

      if (this.state.open) {
          return (<div className="select-input">
            <input className="text-field" defaultValue={this.state.hint} onChange={(e)=> { e.preventDefault(); this.setState({hint: e.target.value}); }}/>
            <div className="select-options">
                  {this.renderOptions()}
            </div>
          </div>);
      } else {
        console.log(this.props.defaultValue);
        return (<div>
            <input className="text-field" readOnly value={this.props.options[this.props.defaultValue]} onClick={ () => this.setState({open: true, hint: this.props.options[this.props.defaultValue]})}/>

        </div>);
      }
  }


}

export default Select;
