import React from 'react';
import Header from './header.jsx'
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import PracticesContent from './practices_content.jsx';
import Modal from './modal.jsx'

class Practices extends React.Component {
    constructor() {
        super();
        let filters = sessionStorage.getItem("practice_filters");
        let objFilters = {}
        if (filters) {
            objFilters = JSON.parse(filters);
        }
        let sort = sessionStorage.getItem("practice_sort");
        if (!sort) {
            sort = 'date_desc';
        }
        this.state = {
            'filter_window': false,
            'practice_filters': objFilters,
            'sort': sort,
            'search': '',
            'ts': new Date().getTime(),
            'forReview': 0,
            'forRequest': 0
        }

    }
    componentWillMount() {
        this.props.app.api('GET', '/api/practices', {'pageSelected': 'Worked'}, (data) => {
            this.setState({'forReview': data.length});
        }, () => {
            this.setState({'forReview': 0});
            // console.log('error')
        })
        this.props.app.api('GET', '/api/practices', {'pageSelected': 'Request'}, (data) => {
            this.setState({'forRequest': data.length});
        }, () => {
            this.setState({'forRequest': 0});
            // console.log('error')
        })
    }


    reloadChildren() {
        this.setState({'ts': new Date().getTime()})
    }

    teamOptions() {
        return this.props.app.storage.teams.map((r) => {
            if(r.name != 'Дистанционный сервис' && r.name != 'Центральная функция')
                return (
                    <option value={r._id} key={r._id}>{r.name}</option>
                );
        })
    }

    updateMacro() {
        let selectedTeam = $('#select-team').val();
        let currentTeam = this.getMacro(selectedTeam);
        let selectRegion = $('#select-region');
        let data = "\x3coption value=''\x3eВсе\x3c/option\x3e";
        if (currentTeam != null) {
            this.props.app.storage.regions.forEach((r) => {
                if(r.teamId == currentTeam._id)
                    data += "\x3coption value='" + r.ID + "'\x3e" + r.Title + '\x3c/option\x3e';
            });
        }
        selectRegion.html(data);
        jcf.getInstance(selectRegion).refresh();
    }    

    getMacro(id) {
        let currentMR = null;
        this.props.app.storage.teams.forEach((mr) => {
            if (mr._id == id) {
                currentMR = mr;
            }
        });
        return currentMR;
    }     

    regionOptions() {
        let currentMacro = this.getMacro(this.state.practice_filters['team_id']);
        if (currentMacro == null) {
            return null;
        }

        return this.props.app.storage.regions.map((r) => {
            if(r.teamId == currentMacro._id)
                return (
                    <option value={r.ID} key={r.ID}>{r.Title}</option>
                );
        })

    }

    statusOptions() {
        return this.props.app.storage.practice_statuses.statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }
    statusScaleOptions() {
        return this.props.app.storage.practice_statuses.sub_statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    divisionOptions() {

        return this.props.app.storage.divisions.map((div, idx) => {
            return (
                <option key={idx} value={div.ID}>{div.Title}</option>
            );
        });
    }

    title() {
        if (this.props.page == 'scale')
            return "К масштабированию";
        else if (this.props.page == 'review')
            return "К ознакомлению";
        else if (this.props.page == 'request')
            return "Требуют уточнения";
        else if (this.props.page == 'created')
            return "Мой вклад";
        else if (this.props.page == 'worked')
            return "Для рассмотрения";
        else if (this.props.page == 'favorite')
            return 'Избранные'
        else
            return "Все практики";
    }

    descr() {
        if (this.props.page == 'scale')
            return "Практики из данного раздела рассмотрены коллегами из Операций и функций и одобрены для масштабирования другими регионами";
        else if (this.props.page == 'review')
            return "В данном разделе представлены практики, которые могут быть интересны другим коллегам для реализации";
        else if (this.props.page == 'request')
            return "Представьте нужную информацию по практикам из этого списка";
        else if (this.props.page == 'created')
            return "Практики созданные вами";
        else if (this.props.page == 'worked')
            return "Рассмотрите практики из этого списка и примите решение о необходимости их дальшейшего масштабировании в компании.";
        else if (this.props.page == 'favorite')
            return 'Практики, за которыми вы наблюдаете'
        else
            return "Лучшие региональные практики - опыт коллег, аккумулированный Операциями. Практики позволят вам подсветить неочевидные моменты в работе департаментов.";
    }    
    page() {
        if (this.props.page == 'scale')
            return "Scale";
        else if (this.props.page == 'worked')
            return "Worked";
        else if (this.props.page == 'review')
            return "Review";
        else if (this.props.page == 'request')
            return "Request";
        else if (this.props.page == 'created')
            return "Create";
        else if (this.props.page == 'favorite')
            return "Favorite";
        else
            return "All";
    }

    filterDisplay() {
        if (this.page() != 'Request' && this.page() != 'Worked') {
            return {}
        } else {
            return {'display': 'none'}
        }
    }

    openFilters() {
        this.setState({'filter_window': true})
    }

    closeFilters() {
        this.setState({'filter_window': false})
    }

    saveFilters() {
        let data = $('form#filters-form').serializeArray();
        let filterObj = {}
        data.forEach((item) => {
            if (item.value != "") {
                filterObj[item['name']] = item['value'];
            }
        });
        console.log(filterObj);
        sessionStorage.setItem('practice_filters', JSON.stringify(filterObj))

        this.setState({'filter_window': false, 'practice_filters': filterObj})

    }

    clearFilters() {
        $('#filters-form').find('.form-field').val('');
        $('#filters-form').find('.custom-select').each((i, sel) => {
            jcf.getInstance($(sel)).refresh();
        });
        $('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
        sessionStorage.setItem("practice_filters", null);
    }

    filtersCounter() {
        let size = 0;
        for (var key in this.state.practice_filters) {
            if (this.state.practice_filters.hasOwnProperty(key)) size++;
        }
        if (size > 0) {
            return (
                <em className="state-indicator state-indicator--red">{size}</em>
            )
        } else {
            return null;
        }
    }

    componentDidMount() {
        $.initializeForm($('.container'));
    }

   


    renderFilters() {
        if (this.state.filter_window)
            return (
                <Modal>
                    <div className="title-holder">
                        <div className="title-frame">
                            <h2>Фильтр</h2>
                        </div>
                    </div>
                    <div className="modal-content">
                        <form action="" id="filters-form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, от</label>
                                        <input type="text" name="date_from"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.practice_filters['date_from']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, до</label>
                                        <input type="text" name="date_to"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.practice_filters['date_to']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="check-wrap" style={{'display': 'none'}}>
                                <input type="checkbox" className="checkbox" id="check10"/>
                                <label htmlFor="check10" className="checkbox-label">Показать только важные</label>
                            </div>

                            <div className="select-field-holder text-active">
                                <label>Макрорегион автора:</label>
                                <select className="custom-select form-field" name="team_id" id="select-team"
                                    onChange={this.updateMacro.bind(this)}
                                    defaultValue={this.state.practice_filters['team_id']}>
                                    <option value="">Все</option>
                                    {this.teamOptions()}
                                </select>
                            </div>
                            <div className="select-field-holder text-active">
                                <label>Регион автора:</label>
                                <select className="custom-select form-field" name="region_id" id="select-region"
                                        defaultValue={this.state.practice_filters['region_id']}>
                                    <option value="">Все</option>
                                    {this.regionOptions()}
                                </select>
                            </div>
                            <div className="select-field-holder text-active" style={this.page() === 'All' || this.page() === 'Favorite' ? {} : {display:'none'}} >
                                <label>Статус:</label>
                                <select className="custom-select form-field" name="status"
                                        defaultValue={this.state.practice_filters['status']}>
                                    <option value="">Все</option>
                                    {this.statusOptions()}
                                </select>
                            </div>
                            <div className="select-field-holder text-active" style={this.page() === 'Scale' ? {} : {display:'none'}} >
                                <label>Статус масштабирования в регионе:</label>
                                <select className="custom-select form-field" name="sub_status"
                                        defaultValue={this.state.practice_filters['sub_status']}>
                                    <option value="">Все</option>
                                    {this.statusScaleOptions()}
                                </select>
                            </div>

                            <div className="select-field-holder text-active">
                                <label>Подразделение:</label>
                                <select className="custom-select form-field" name="division_id" id="select-division">
                                    <option value="">Все</option>
                                    {this.divisionOptions()}
                                </select>
                            </div>

                            <div className="text-field-holder" style={{'display': 'none'}}>
                                <label htmlFor="someField">Автор</label>
                                <input type="text" className="text-field" id="someField" name="nameField"/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-actions">
                        <a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
                        <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
                        <a className="link--underline modal-reset pull-right" onClick={this.clearFilters.bind(this)}>Сбросить
                            всё</a>
                    </div>
                    <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
                </Modal>
            );
        else
            return null;
    }

    changeSort(value) {
        sessionStorage.setItem("practice_sort", value);
        this.setState({"sort": value});
    }

    changeYear(value) {
        let filterObj = {};
        for (var key in this.state.practice_filters) {
            if (this.state.practice_filters.hasOwnProperty(key) && key != 'year') {
                filterObj[key] = this.state.practice_filters[key];
            }
        }
        if (value != "") {
            filterObj['year'] = value
        }
        sessionStorage.setItem('practice_filters', JSON.stringify(filterObj))
        this.setState({'practice_filters': filterObj})
    }

    yearOptions() {
        return this.props.app.storage.practice_statuses.years.map((item) => {
            return (
                <option value={item} key={item}>{item}</option>
            )
        })
    }    


    renderSort() {
        return (
            <div className="select-field-holder text-active">
                <label>Сортировать:</label>
                <select className="custom-select" defaultValue={this.state.sort}
                        onChange={(e) => this.changeSort(e.target.value)}>
                    <option value="date_desc">Сначала новые</option>
                    <option value="date_asc">Сначала старые</option>
                    <option value="title_asc">от А до Я</option>
                    <option value="title_desc">от Я до А</option>
                    <option value="likes_asc">Рейтинг по возрастанию</option>
                    <option value="likes_desc">Рейтинг по убыванию</option>
                </select>
            </div>
        )
    }

    renderContainer() {
        let for_review = this.state.forReview;
        let for_request = this.state.forRequest;
        return (
            <div className="container">
                <ul>
                    <li>
                        <Link to="/practices">Все</Link>
                    </li>
                    <li>
                        <Link to="/practices/request">Требуют уточнения <span className="stateIndicator" style={for_request == 0 ? {display: 'none'} : {}}>{for_request}</span></Link>
                    </li>
                    <li>
                        <Link to="/practices/worked">Для рассмотрения <span className="stateIndicator" style={for_review == 0 ? {display: 'none'} : {}}>{for_review}</span></Link>
                    </li>
                    <li>
                        <Link to="/practices/scale">Для масштабирования</Link>
                    </li>
                    <li>
                        <Link to="/practices/review">Для ознакомления</Link>
                    </li>
                    {/* <li>
                        <Link to="/practices/created">Мой вклад</Link>
                    </li> */}
                    <li>
                        <Link to="/practices/favorite">Избранные</Link>
                    </li>
                    <li className="create_practice">
                        <Link to="/practices/new"><i className="ico icon-add"/>Создать практику </Link>
                    </li>
                </ul>
            
            </div>
        )
    }

    doSearch(value) {
        this.setState({'search': value})
    }

    renderSearch() {
        return (
            <div className="text-wrap header__search"
                 title="Поиск по практикам можно осуществлять по названию, описанию, имени автора">
                <input type="text" className="text" defaultValue={this.state.search} placeholder="Поиск по практикам"
                       autoComplete="off" onChange={(e) => {
                    this.doSearch(e.target.value)
                }}/>
                <i className="ico icon-search"/>
            </div>
        )
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">
                            Практики
                            {this.renderSearch()}
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="current-section-menu">
                        <div className="current-section-menu-inner">
                            <div className="section-menu-line">
                                {this.renderContainer()}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1>{this.title()}</h1>
                                    <div className='title_descr'>{this.descr()}</div>

                                </div>
                                <div className="col-md-12" style={{'margin-top': '20px'}}>
                                    <div className="select-field-holder text-active" style={{float: 'left', width: '150px', 'margin-top': '0px'}}>
                                            <label>Год практики:</label>
                                            <select className="custom-select" defaultValue={this.state.practice_filters['year']}
                                                    onChange={(e) => {
                                                        this.changeYear(e.target.value)
                                                    }}>
                                                <option value="">Все</option>
                                                {this.yearOptions()}
                                            </select>
                                        </div>    

                                    <ul className="page__filter">
                                        <li className="page__filter-item page__filter-important filter-on">
                                            <span className="page__filter-ico"><img src="static/img/star.svg"
                                                                                    alt=""/></span>
                                            <a className="js-check-important" href="#">Отметить важное</a>
                                        </li>
                                        <li className="page__filter-item page__filter-remove filter-on">
                                            <span className="page__filter-ico"><img src="static/img/trash.svg" alt=""/></span>
                                            <a href="#">Удалить</a>
                                        </li>
                                        <li className="page__filter-item page__filter-filter"
                                            style={this.filterDisplay()}>
                                            <span className="page__filter-ico"><i className="ico icon-filter"/></span>
                                            <a className="fancy"
                                               onClick={this.openFilters.bind(this)}>Фильтры {this.filtersCounter()}</a>
                                            <i className="ico icon-expander-arrow"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <div className="table table-hover">
                                <div>
                                    <div>
                                        <div className="col-table-4">
                                            Название
                                        </div>
                                        <div className="col-table-2">Подразделение</div>
                                        <div className="col-table-1_5 ">Регион автора</div>
                                        <div className="col-table-1_5 ">Дата создания</div>
                                        <div className="col-table-1_5 ">Год практики</div>
                                        <div className="col-table-3" style={this.page() != 'Scale' ? {} : {display:'none'}}>Статус/автор</div>
                                        <div className="col-table-3" style={this.page() === 'Scale' ? {} : {display:'none'}}>Статус в регионе</div>
                                        <div className="col-table-1_5">Рейтинг</div>
                                        <div className="col-table-1_5" style={this.page() === 'Favorite' ? {} : {display:'none'}}>Избранное</div>
                                    </div>
                                </div>
                                <PracticesContent app={this.props.app} page={this.page()} filters={this.state.practice_filters}
                                              sort={this.state.sort} search={this.state.search} targetRegion={this.props.app.storage.defaultRegion}/>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderFilters()}
            </div>
        );
    }
}


export default Practices;
