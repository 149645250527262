import React from 'react';
import { Route, Redirect } from 'react-router'
import { Link } from 'react-router-dom';


class MarketPrize extends React.Component {
    renderButton(action, caption) {
        if (action == "prize_details") {
            return <Link key={action} to={"/market/" + this.props.prize.ID}><div className="prize_button">{caption}</div></Link>
        } else {
          return <div key={action} className="prize_button" onClick={() => this.props.onActionClick(this.props.prize, action)}>{caption}</div>
        }
    }

    renderAction() {
        if (this.props.action == "buy") {
              return [this.renderButton("buy", "Купить"), this.renderButton("prize_details", "Подробнее")]
        } else if (this.props.action == "no-funds"){
            return [<div className="no-funds">
                  Недостаточно ресурсов для покупки
            </div>, this.renderButton("prize_details", "Подробнее")]
        } else if (this.props.action == "details"){
            return this.renderButton("details", "Подробнее");
        }
        else {
          return null;
        }
    }

    render() {
        if (this.props.kind == 'my') {
          let date = new Date( parseInt( this.props.prize.ID.substring(0,8), 16 ) * 1000 )
          let captionDate = date.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'})
          return (<div className="market_prize">
                <div className="image">
                    <img src={"/api/market/prizes/"+this.props.prize.PrizeID+"/image.png"} />
                </div>
                <div className="prize_content">
                      <div className="prize_date">{captionDate}</div>
                      <div className="description">
                          {this.props.prize.Name}
                      </div>
                      <div className="prize_status">{this.props.prize.Status}</div>
                      <div className="actions">
                          {this.renderAction()}
                      </div>
                </div>

          </div>);

        } else {
          return (<div className="market_prize">
                <div className="image">
                    <Link to={"/market/" + this.props.prize.ID}><img src={"/api/market/prizes/"+this.props.prize.ID+"/image.png"} /></Link>
                </div>
                <div className="prize_content">
                      <div className="cost">
                          <span>{this.props.prize.Cost}</span>
                          <span className="coin"/>
                      </div>
                      <div className="description">
                          {this.props.prize.Name}
                      </div>
                      <div className="prize_status">Доступно: {this.props.prize.Quantity}</div>
                      <div className="actions">
                          {this.renderAction()}
                      </div>
                </div>

          </div>);
      }
    }

}


export default MarketPrize
