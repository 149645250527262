import React from 'react';
import Header from './header.jsx'
import {withRouter} from 'react-router-dom'
import UploadFile from './upload_file.jsx'
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
import ResponsibleAssign from './responsible_assign.jsx';

class PracticeForm extends React.Component {
    constructor() {
        super();
        this.state = {region_name: '', coauthor_names: [], coauthors: [], files: [], assign_author: false, budget: false};
    }

    loadObject(props) {
        this.props.setFiles('practice_form', []);
        if (props.match.params.id) {
            props.app.api('GET', '/api/practices/' + props.match.params.id, {}, (data) => {
                //this.setState({'object': data, 'ts': new Date().getTime()});
                console.log(data)
                let form = $("form#practice");
                let setField = (name, value) => {
                    let item = form.find("[name='" + name + "']");
                    item.val(value);
                    item.parent().addClass('text-active');
                    if (item[0].nodeName.toLowerCase() == 'select') {
                        jcf.getInstance(item).refresh();
                    }
                };
                console.log(data.Region.Title);
                this.setState({
                    region_name: data.Region.Title,
                    coauthor_names: data.Coauthors.map((x) => x.Name),
                    coauthors: data.Coauthors.map((x) => x._id),
                    budget: data.Budget == 'Да'
                    
                });

                setField('title', data.Title);
                setField('Budget', data.Budget);
                setField('Preconditions', data.Preconditions);
                setField('Year', data.Year);
                setField('Goals', data.Goals);
                setField('KPIs', data.KPIs);
                setField('Mechanics', data.Mechanics);
                setField('Results', data.Results);
                setField('coauthors', data.coauthors);

                let addFile = (data, name) => {
                    let files = this.props.files['practice_form'] || []
                    this.props.setFiles('practice_form', [...files, {data: data, name: name}]);
                }
                data.Attachments.forEach((item, i) => {
                    let path = item.split('/');
                    let name = path[path.length - 1];
                    $.ajax({
                        url: item,
                        xhrFields: {responseType: 'blob'},
                        success: (blobData) => {
                            let reader = new FileReader();
                            reader.readAsDataURL(blobData);
                            reader.onloadend = () => {
                                addFile(reader.result, name)
                            };
                        }
                    })

                });


            }, () => {
            })
        }
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        //this.loadObject(new_props);
        if(this.props.app.storage.defaultRegion == undefined)
        {
            this.props.app.api('GET', '/api/profile', {}, (profile) => {
                if (profile.info.region) {
                    this.props.app.storage['defaultRegion'] = profile.info.region.ID;
                    this.setState({region_name: this.defaultRegion()});
                }
            });
        }
        else
        {
            this.setState({region_name: this.defaultRegion()});
        }
    }

    componentDidMount() {
        $.initializeForm($('form#practice'));
    }

    handleChange(event) {
        console.log(event.target.checked);

        let form = $("form#practice");
        let setField = (name, value) => {
            let item = form.find("[name='" + name + "']");
            item.val(value);
            item.parent().addClass('text-active');
            if (item[0].nodeName.toLowerCase() == 'select') {
                jcf.getInstance(item).refresh();
            }
        };
        setField('Budget', event.target.checked ? 'Да' : 'Нет');
        this.setState({budget: event.target.checked})
    }
 

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    save() {
        let required = ['title', 'Budget', 'Preconditions', 'Goals', 'KPIs', 'Mechanics', 'Results', 'Year'];
        let form = $("form#practice");
        let formDataArray = form.serializeArray();
        let errorInfo = {};
        let isError = false;
        required.forEach((key) => {
            errorInfo[key] = false
        });
        formDataArray.forEach((item) => {
            if (item.value == "" && required.includes(item.name)) {
                errorInfo[item.name] = true;
                isError = true;
            }
        });
        // console.log(errorInfo);
        required.forEach((key) => {
            $('[name=' + key + "]").parent().toggleClass('text-error', errorInfo[key]);
        });
        if (isError) {
            return;
        }
        let formData = new FormData(form[0]);

        $('form#practice img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });
        if (this.props.match.params.id) {
            formData.append('id', this.props.match.params.id);
        }

        $('span.error').text('');
        this.props.app.api_post('/api/practices', formData, (data) => {
            this.props.setFiles('practice_form', []);
            this.props.history.push("/practices/show/" + data.id);
        }, (data) => {
            if (data.responseJSON) {
                if (data.responseJSON.error) {
                    this.props.dispatch({
                        type: 'MODAL_SHOW',
                        modal: {
                            header: 'Ошибка отправки',
                            message: [data.responseJSON.error],
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        }
                    })
                }
                if (data.responseJSON.message) {
  			  let error = data.responseJSON.message;
  				Object.keys(error).forEach((item, i) => {
  						let parent = $('[name=' + item + "]").parent();
  						parent.toggleClass('text-error', true);
  						parent.find('span.error').text(error[item]);

  				});
        }
            }

        });


    }

    defaultRegion() {
        let r = this.props.app.storage.regions.filter((x) => x.ID == this.props.app.storage.defaultRegion)
        if (r.length == 0) {
            return "";
        } else {
            return r[0].Title;
        }
    }

    addAuthor() {
        this.setState({'assign_author': true})
    }


    saveAuthor(id, title) {
        this.setState({
            coauthor_names: this.state.coauthor_names.concat([title]),
            coauthors: this.state.coauthors.concat([id]),
            assign_author: false
        })
    }

    clearAuthors() {
        this.setState({coauthor_names: [], coauthors: []})
    }


    renderAuthorModal() {
        if (this.state.assign_author) {
            return <ResponsibleAssign app={this.props.app} onSave={this.saveAuthor.bind(this)}
                                      onClose={() => this.setState({'assign_author': false})} title="Добавить автора"
                                      buttonTitle="Добавить"/>;
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Лучшие практики
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page__head-title">{this.props.match.params.id ? 'Редактирование практики' : 'Добавление новой практики'}</h1>
                                    <div className='new_item_descr'>Лучшие региональные практики - опыт коллег, аккумулированный Операциями. Практики позволят вам подсветить неочевидные моменты в работе департаментов.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <form action="" id="practice">
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Название</label>
                                        <input type="text" className="text-field" id="someField" name="title"/>
                                    </div>
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Год практики</label>
                                        <input type="text" className="text-field" id="someField" name="Year"/>
                                    </div>

                                    <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="somefield02" name="Budget" onChange={this.handleChange.bind(this)} checked={this.state.budget}/>
                                        <label htmlFor="somefield02" className="checkbox-label bottom-line">Практика предполагает дополнительные затраты</label>
                                    </div>

                                    {/* <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield01">Описание</label>
                                        <textarea id="somefield01" className="text-field" name="description"/>
                                    </div> */}
                                    {/* <div className="text-field-holder text-area-holder">
                                        <label>Регион:</label>
                                        <input id="region_name" style={{backgroundColor: 'white !important'}}
                                               className="text-field" disabled value={this.state.region_name}/>
                                    </div> */}
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield03">Бизнес-ситуация/предпосылки для реализации/проблема которую решит практика<span
                                            className="error"/></label>
                                        {/* <span className="text-field-hint">Пример: Текущий уровень продаж не позволяет выполнить бюджет, регионом был проведен мозговой штурм по поиску дополнительного GI.</span> */}
                                        <textarea id="somefield03" className="text-field" name="Preconditions" style={{'padding-top': '50px !important'}} placeholder="Пример: Текущий уровень продаж не позволяет выполнить бюджет, регионом был проведен мозговой штурм по поиску дополнительного GI."/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield04">Цели, которые позволяет достичь практика<span className="error"/></label>
                                        {/* <span className="text-field-hint">Опишите коротко, какую цель необходимо достигнуть по итогу реализации практики.</span> */}
                                        <textarea id="somefield04" className="text-field" name="Goals" style={{'padding-top': '50px !important'}} placeholder='Опишите коротко, какую цель необходимо достигнуть по итогу реализации практики.'/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield05">KPI, на которые влияет практика<span className="error"/></label>
                                            {/* <span className="text-field-hint">Перечислите KPI, на которые повлияет реализация практики.</span> */}
                                        <textarea id="somefield05" className="text-field" name="KPIs" style={{'padding-top': '50px !important'}} placeholder='Перечислите KPI, на которые повлияет реализация практики.'/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield06">Механика практики/какой использовался бюджет/ общие затраты на привлечение или удержание абонента (SAC)<span className="error"/></label>
                                        <textarea id="somefield06" className="text-field" name="Mechanics"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield07">Результаты практики<span className="error"/></label>
                                        {/* <span className="text-field-hint">Получение экономического эффекта/выгоды. Уменьшение трудозатрат (ч.ч.\мес.)</span> */}
                                        <textarea id="somefield07" className="text-field" name="Results" style={{'padding-top': '50px !important'}} placeholder='Получение экономического эффекта/выгоды. Уменьшение трудозатрат (ч.ч.\мес.)'/>
                                    </div>
                                    <div className="text-field-with-buttons">
                                        <div className="text-field-holder text-area-holder"
                                             style={{width: 'calc(100% - 30px)'}}>
                                            <label htmlFor="coauthors">Соавторы:</label>
                                            <textarea id="coauthors"
                                                      style={{backgroundColor: 'white !important', zIndex: -1}}
                                                      className="text-field" disabled
                                                      value={this.state.coauthor_names.join(', ')}/>
                                            <input type="hidden" name="coauthors"
                                                   value={this.state.coauthors.join(';')}/>
                                        </div>
                                        <div className="buttons">
                                            <a className="change-btn" onClick={this.addAuthor.bind(this)}>+</a>
                                            <a className="change-btn" onClick={this.clearAuthors.bind(this)}>-</a>
                                        </div>
                                    </div>
                                    <UploadFile formId="practice_form"/>
                                    <div className="form-actions">
                                        <a className="btn btn--black"
                                           onClick={this.save.bind(this)}>{this.props.match.params.id ? "Сохранить" : 'Добавить'}</a>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderAuthorModal()}
            </div>
        );
    }
}

export default withDispatch(withFiles(withRouter(PracticeForm)));
