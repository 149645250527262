import React from 'react';
import Header from './header.jsx'
import withDispatch from './withDispatch.jsx'
import UserDetailsContent from './user_details_content.jsx';
import { Link } from "react-router-dom";

class UserDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            profile: null,
            game_profile: null,
            report_type: 'points'
        }
    }

    componentWillMount() {
        this.loadObject()
    }

    loadObject() {
        this.props.app.api('GET', '/api/profile/' + this.props.match.params.id, {}, (data) => {
            this.setState({profile: data})
        });

        this.props.app.api('GET', '/api/game/profile/' + this.props.match.params.id, {}, (data) => {
            this.setState({'game_profile': data})
        }, (err) => {
            console.log(err)
        });

    }


    getRewardStyle(reward) {
        let backgrounds = ["#ffd700", "#c0c0c0", "#cd7f32"];
        if (reward == 0) {
            return {
                width: '30px',
                height: '30px',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '30px'
            }
        } else {
            return {
                background: backgrounds[reward - 1],
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '30px'
            }
        }


    }

    renderRatings() {
        return this.state.profile.ratings.map((rating) => {
            return (<tr key={rating.ID}>
                <td>
                    <div className="profile_rating_name">Рейтинг: {rating.Name}</div>
                    <div className="profile_rating_place"><span>Место: </span><span
                        style={this.getRewardStyle(rating.Reward)}>{rating.Place == 0 ? "-" : rating.Place}</span></div>
                    <div className="profile_rating_points">{"Очки: " + rating.Points}</div>
                </td>
            </tr>)
        });
    }

    renderProfile() {
        if (!this.state.profile) {
            return null;
        }

        return (<section className="wrapper">
            <div className="container">
                <div className="page__head">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile_title">
                                <img style={{width: '72px', height: '72px'}} src="/static/img/profile.svg"/>
                                <h1 className="page__head-title">{this.state.profile.info.name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="profile_region"><img
                            src="/static/img/region.svg"/><span>Регион: </span><span>{this.state.profile.info.region.Title}</span>
                        </div>
                        <div className="profile_region"><img
                            src="/static/img/function.svg"/><span>Должность: </span><span>{this.state.profile.info.title}</span>
                        </div>
                        <div className="profile_region"><img
                            src="/static/img/function.svg"/><span>Департамент: </span><span>{this.state.profile.info.functions.map(x => x.Title).join(', ')}</span>
                        </div>
                        <div className="profile_region" style={this.state.profile.info.manager == null ? {'display': 'none'} : {}}>
                            <img src="/static/img/function.svg"/><span>Руководитель: </span><span className='idea__meta-item'><a 
                            href={"/user/" + (this.state.profile.info.manager != null ? this.state.profile.info.manager.ID : '')}>{(this.state.profile.info.manager != null ? this.state.profile.info.manager.name : '')} <span className='' style={{display: !(this.state.profile.info.manager != null ? this.state.profile.info.manager.removed : false) ? 'none':'' }}>(не работает)</span></a></span>
                        </div>

                        <div className="profile_region" style={{'visibility': this.state.profile.info.alliance == '' ? 'hidden': 'visible'}}><img
                            src="/static/img/function.svg"/><span>КЦ: </span><span>{this.state.profile.info.alliance}</span>
                        </div>
                    </div>
                </div>
                <div className="row profile_wrapper">
                    <table className="profile_stat_table">
                        <tbody>
                        <tr>
                            <td className="profile_stat_header">Статистика</td>
                            <td className="profile_stat_idea"><img src="/static/img/idea.svg"  alt="Идеи"/></td>
                            <td className="profile_stat_problem"><img src="/static/img/problem.svg" alt="Улучшения"/></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="profile_stat_row">
                            <td>Добавил</td>
                            <td className="profile_stat_idea">{this.state.profile.stats.ideas.add}</td>
                            <td className="profile_stat_problem">{this.state.profile.stats.improvements.add}</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="profile_stat_row">
                            <td>Реализовано</td>
                            <td className="profile_stat_idea">{this.state.profile.stats.ideas.implemented}</td>
                            <td className="profile_stat_problem">{this.state.profile.stats.improvements.implemented}</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="profile_stat_row">
                            <td>Реализовал сам</td>
                            <td className="profile_stat_idea">{this.state.profile.stats.ideas.solved}</td>
                            <td className="profile_stat_problem">{this.state.profile.stats.improvements.solved}</td>
                            <td>&nbsp;</td>
                        </tr>

                        </tbody>
                    </table>
                    <table className="profile_rating_table">
                        <tbody>
                        {this.renderRatings()}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>)
    }


    renderDetails(){
        if (!this.state.profile) {
            return (
                <div className="container">
                    <div style={{'padding-top': '40px'}}>
                    Идет загрузка...
                    </div>
                </div>
            );
        }        

        return (
            <div className="container">
                <div className="profile_stat_header" style={{'padding-left': '20px', 'margin-top':'40px', 'margin-bottom':'20px'}}>
                    История начислений
                </div>
                <div className="profile_stat_header" style={{'padding-left': '20px', 'margin-top':'40px', 'margin-bottom':'20px'}}>
                    <div className="skill__item2">
                        <div className="skill__name">Очки</div>
                        <div className="skill__value">{this.state.game_profile.points}</div>
                    </div>
                    <div className="skill__item2">
                        <div className="skill__name">Телекоины</div>
                        <div className="skill__value">{this.state.game_profile.telecoins}</div>
                    </div>
                </div>

                <div className="table-responsive" style={{'padding-left': '20px'}}>
                    <div className="table table-hover">
                        <UserDetailsContent app={this.props.app}/>
                    </div>
                </div>
            </div>

        )
    }
    renderGameStats() {
        if (this.state.profile == null) {
            return null;
        }
        if (this.state.game_profile == null) {
            return null;
        }

        return (
            <div className="container">
            </div>
        );
    }    

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Пользователь
                        </div>
                    </div>
                </header>
                
                {this.renderProfile()}
                {this.renderGameStats()}
                {this.renderDetails()}
            </div>);
    }
}

export default withDispatch(UserDetails);
