import React from 'react';
import Modal from './modal.jsx';

class RouteAssign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'routes': [...props.routes, {
                function_title: '',
                region_title: '',
                can_delete: true
            }]
        }
    }

    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));
    }

    removeRoute(idx) {
        let routes = this.state.routes;
        routes[idx].removed = true;
        this.setState({'routes': routes});
    }

    functionOptions(region_id) {
        if (!region_id)
            return

        return this.props.app.storage.user_functions[region_id].map((func, idx) => {
            return (
                <option key={idx} value={func.ID}>{func.Title}</option>
            );
        });
    }

    regionOptions() {
        console.log(this.props.app.user_data()['role'])
        let region = this.props.app.storage.mr_regions

        if (this.props.app.user_data()['role'] === 'moderator'){
            region = this.props.app.storage.regions
        }

        if (this.props.app.user_data()['role'] === 'moderator_cf'){
            region = this.props.app.storage.regions
        }


        return region.map((reg, idx) => {
            if (reg.ID in this.props.app.storage.user_functions)
            {
                return (
                    <option key={idx} value={reg.ID}>{reg.Title}</option>
                );
            }
        });
    }

    functionChange(target, idx) {
        let routes = this.state.routes;
        routes[idx].function_id = $(target).val();
        routes[idx].function_title = $(target).find('option:selected').text();
        this.setState({'routes': routes})
    }

    regionChange(target, idx) {
        let routes = this.state.routes;
        routes[idx].region_id = $(target).val();
        routes[idx].region_title = $(target).find('option:selected').text();
        this.setState({'routes': routes})
    }

    saveRoutes() {
        this.props.onSave(this.state.routes.filter((elem) => !elem.removed), $('#assign-select-route').val())
    }

    getIdeaRoute(id) {
        let currentRoute = null;
        this.props.app.storage.idea_routes.forEach((r) => {
            if (r.ID == id) {
                currentRoute = r;
            }
        });
        return currentRoute;
    }   

    updateTypes() {
        let selectedRoute = $('#assign-select-route').val();
        let currentRoute = this.getIdeaRoute(selectedRoute);
        let selectTypes = $('#assign-select-type');
        let data = "";
        if (currentRoute != null) {
            currentRoute.types.forEach((t) => {
                data += "\x3coption value='" + t.ID + "'\x3e" + t.Title + "\x3c/option\x3e";
            });
        }
        selectTypes.html(data);
        jcf.getInstance(selectTypes).refresh();

    }    
    routesOptions() {
        return this.props.app.storage.idea_routes.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )
        })
    }

    routeTypesOptions() {
        if (this.props.app.storage.idea_routes.length == 0) {
            return null;
        }

        return this.props.app.storage.idea_routes[0].types.map((t) => {
            return (
                <option value={t.ID} key={t.ID}>{t.Title}</option>
            );

        })
    }  

    render() {
        if (this.state.routes == null) {
            return null;
        }
        let routes = this.state.routes.map((route, idx) => {
            let btn = null;
            if (route.removed) {
                return null;
            }
            if (route.can_delete) {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Регион:</label>
                                <select className="route-select custom-select" defaultValue={route.region_id}
                                        onChange={(e) => this.regionChange(e.target, idx)}>
                                    <option/>
                                    {this.regionOptions()}
                                </select>
                            </div>
                        </div>
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Департамент:</label>
                                <select className="route-select custom-select" defaultValue={route.function_id}
                                        onChange={(e) => this.functionChange(e.target, idx)}>
                                    <option/>
                                    {this.functionOptions(route.region_id)}
                                </select>
                            </div>
                        </div>
                        {/*<div className="select-route-delete-btn" style={{'cursor': 'pointer'}}*/}
                        {/*     onClick={this.removeRoute.bind(this, idx)}>Удалить*/}
                        {/*</div>*/}
                    </div>
                );
            } else {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            {route.region_title}
                        </div>
                        <div className="select-route-cell">
                            {route.function_title}
                        </div>
                        {/*<div className="select-route-delete-btn" style={{'visibility': 'hidden'}}>Удалить</div>*/}
                    </div>

                );
            }
        })

        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        Назначить маршруты
                    </div>
                    <div className="select-route-table">
                        {routes}
                    </div>
                    <div className="select-field-holder text-active">
                        <label>Категория:</label>
                        <select className="custom-select" name="assign_route_id" id="assign-select-route"
                                defaultValue={this.props.routeId}
                                onChange={this.updateTypes.bind(this)}>
                            {this.routesOptions()}
                        </select>
                    </div>
                    <div className="select-field-holder text-active">
                        <label>Тип:</label>
                        <select className="custom-select" name="idea_type_id" id="assign-select-type">
                            {this.routeTypesOptions()}
                        </select>
                    </div>                                    

                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveRoutes.bind(this)}>Сохранить</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default RouteAssign;
