import React from 'react';
import Modal from './modal.jsx';
import UploadFile from './upload_file.jsx'

class PracticeScaleComplete extends React.Component {

	constructor(props) {
		super(props);
		this.props = props
	}

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }	
	save() {
		let text = $('#action_comment').val();
		if (text === "") {
			$('#action_comment').parent().toggleClass('text-error', true);
			return;
		}
		let form = $("form#scale_complete_form");
        let formData = new FormData(form[0]);

        $('form#scale_complete_form img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });


		
		this.props.onSave(this.props.action.id, text, formData );
	}

	render() {
	  	let check = null;
		let actionStyle = {}
		return (
			<Modal>
				<div className="modal-content">
					<form id="scale_complete_form">
						<div className="text-field-holder text-area-holder">
							<label htmlFor="action_comment">Комментарий</label>
							<textarea id="action_comment" className="text-field"/>
						</div>
						<UploadFile formId="comments_form"/>

						<div className="form-actions" style={actionStyle}>
							{ check }
							<a className="btn btn--black" onClick={this.save.bind(this)}>{this.props.action.title}</a>
						</div>
					</form>
				</div>
				<a onClick={() => {this.props.onClose()}} className="close ico icon-close"/>
			</Modal>
		)

	}
}

export default PracticeScaleComplete;
