import React from 'react';
import Modal from './modal.jsx';

class PracticeScaleAssign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'division': this.props.division,
            'title': !this.props.review ? 'Масштабирование практики применимо для:' : 'Практика рекомендуется к ознакомлению для:'
        }
    }

    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));
    }

    divisionOptions() {

        return this.props.app.storage.divisions.map((div, idx) => {
            return (
                <option key={idx} value={div.ID}>{div.Title}</option>
            );
        });
    }

    divisionChange(target) {
        let division = this.state.division;
        division = $(target).val();
        this.setState({'division': division})
    }

    save() {
		let text = $('#action_comment').val();
        this.props.onSave(this.state.division, text);
    }


  
    render() {

        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        {this.state.title}
                    </div>
                    <div className="select-route-table">
                        <div key='div0' className="select-route-row">
                            <div className="select-route-cell">
                                <div className="select-field-holder text-active">
                                    <label>Подразделение:</label>
                                    <select className="route-select custom-select"
                                            onChange={(e) => this.divisionChange(e.target)}>
                                        <option/>
                                        {this.divisionOptions()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="text-field-holder text-area-holder">
                            <label htmlFor="action_comment">Комментарий</label>
                            <textarea id="action_comment" className="text-field"/>
                        </div>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.save.bind(this)}>{!this.props.review ? 'Утвердить к масштабированию': 'Принять для ознакомления'}</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default PracticeScaleAssign;
