import React from 'react';
import Header from './header.jsx'
import Modal from './modal.jsx'
import RouteImprovementAssign from './routes_improvement_assign.jsx';
import Comments from './comments.jsx';
import Attachments from './attachments.jsx';
import Markup from './markup.jsx'
import Points from './points.jsx'
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
import Workflow from './workflow.jsx';

import {BrowserRouter as Router, Route, Link} from "react-router-dom";

class Improvement extends React.Component {
    constructor() {
        super();
        this.state = {
            'object': null, 
            'comment': null, 
            'ts': null, 
            'routes': false}
    }


    loadObject(props) {
        props.app.api('GET', '/api/problems/' + props.match.params.id, {}, (data) => {
            this.setState({'object': data, 'ts': new Date().getTime()});
            let image = document.getElementById('map-image');
            let imagePath = data.MapImage;
            let retries = 10;

            let createXHR = () => {// Seems like the only way to get access to the xhr object
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                return xhr;
            }

            let processImage = (data) => {
                var a = new FileReader();
                a.onload = (e) => {
                    image.setAttribute('src', e.target.result);
                }
                if (image){
                    a.readAsDataURL(data);
                }

            };

            let retry = () => {
                if (retries <= 0) {
                    return;
                }
                retries = retries - 1;
                window.setTimeout(() => {
                    $.ajax({url: imagePath, success: processImage, error: retry, xhr: createXHR});
                }, 2000)
            }


            $.ajax({url: imagePath, success: processImage, error: retry, xhr: createXHR});

        }, () => {
            console.log('error')
        })

    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        this.loadObject(new_props);
    }

    componentDidMount() {
        $.initializeForm($('.comments__item comment-add'));
    }

    actionById(actionId) {
        let action = this.state.object.Actions.filter((item) => {
            return item.id == actionId
        });
        if (action.length == 0) {
            return null;
        }
        return action[0];
    }

    processAction(actionId, comment) {
        let action = this.actionById(actionId);
        if (action == null) {
            return;
        }

        if (comment == null && action.canComment) {
            this.setState({'comment': actionId})
        } else {
            let self = this;
            let text = comment;
            if (text == null) {
                text = " ";
            }

            let data = new FormData();
            data.append('improvement_id', this.state.object._id);
            data.append('action', actionId);
            data.append('text', text);
                    // console.log('text1')
        console.log(text)
            this.props.app.api_post('/api/improvement_comment', data, (data) => {
                self.loadObject(self.props);
                self.setState({'comment': null});
            }, () => {
                console.log('error')
            });
        }

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    sendComment(response) {
        let text = $('#comment_text').val();
        if (text == "") {
            $('#comment_text').parent().toggleClass('text-error', true);
            return;
        }
        if (response) {
            text = response + text;
        }
        let data = new FormData();
        data.append('improvement_id', this.state.object._id);
        data.append('text', text);
        $('form#comment_form img.file-image').each((idx, elem) => {
            let filedata = JSON.parse(elem.attributes.data.value);
            data.append('file', this.dataURItoBlob(filedata.data), filedata.name);
        });
        let self = this;
        console.log('text')
        console.log(text)
        this.props.app.api_post('/api/improvement_comment', data, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);
        }, (data) => {
            console.log('error');
            if (data.responseJSON.message) {
                self.props.dispatch({
                    type: 'MODAL_SHOW',
                    modal: {
                        header: 'Ошибка отправки',
                        message: [data.responseJSON.message],
                        buttons: [
                            {name: 'ok', title: 'OK', default: true}
                        ]
                    }
                })
            }
        });

    }

    renderAssignAction() {
        if (this.state.object.CanChangeRoutes) {
            return (
                <a onClick={this.assignRoutes.bind(this)} className="btn btn--black">Назначить маршруты <Points action='improvement_change_routes' app={this.props.app}/></a>
            )
        } else {
            return null;
        }
    }

    renderActions() {
        let actions = this.state.object.Actions.map((action) => {
            return (
                <a key={action.id} onClick={this.processAction.bind(this, action.id, null)}
                   className="btn btn--black">{action.title} <Points action={'improvement_'+action.id} app={this.props.app}/></a>
            );

        });
        if (this.state.object.CanEdit) {
            actions.push(<a href={"/improvements/edit/" + this.state.object._id} key="edit" className="btn btn--black"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push("/improvements/edit/" + this.state.object._id);
                            }}>Изменить</a>);
        }
        return actions;

    }

    toggleFavorite() {
        let self = this;
        let method = this.state.object.Favorite ? 'DELETE' : 'POST';
        this.props.app.api(method, '/api/problems/' + this.state.object._id + '/fav', {}, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);
        })
    }

    renderFavoriteButton() {
        return (
            <a key="favorite" className="edit-button" onClick={(e) => {
                e.preventDefault();
                this.toggleFavorite();
            }}>
                {this.state.object.Favorite ?
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star_a.png" alt=''/> :
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star.png" alt=''/>}
            </a>
        )
    }    

    assignRoutes() {
        this.setState({'routes': true})
    }


    saveRoutes(routes) {
        let self = this;
        this.props.app.api_post_json('/api/problems/' + this.state.object._id, {'Routes': routes}, () => {
            self.loadObject(self.props);
            self.setState({'comment': null, 'routes': false});
        })
    }

    renderAssignModal() {
        if (this.state.routes) {
            return <RouteImprovementAssign app={this.props.app} routes={this.state.object.Routes}
                                onSave={this.saveRoutes.bind(this)} onClose={() => this.setState({'routes': false})}/>;
        }
    }


    processActionComment() {
        let text = $('#action_comment').val();
        if (text == "") {
            $('#action_comment').parent().toggleClass('text-error', true);
            return;
        }
        this.processAction(this.state.comment, text);

    }

    renderCommentModal() {
        if (this.state.comment == null) {
            return null;
        }
        let action = this.actionById(this.state.comment);
        if (action == null) {
            return null;
        }
        return (
            <Modal>
                <div className="modal-content">
                    <div className="text-field-holder text-area-holder">
                        <label htmlFor="action_comment">{action.windowTitle}</label>
                        <textarea id="action_comment" className="text-field"></textarea>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.processActionComment.bind(this)}>{action.title}</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.setState({'comment': null})
                }} className="close ico icon-close"></a>

            </Modal>
        )

    }

    renderWorkflow() {
        if (this.state.object.Workflow) {
            return <Workflow app={this.props.app}
                                     workflow={this.state.object.Workflow}/>;
        }
    }
    

    renderMap() {
        if (this.state.object.MapImage) {
            return (
                <img id="map-image" width="100%"/>
            )
        }

    }

    testLink() {
        if (this.state.object.Test) {
            return (
                <div className="idea__meta-item"><span>В рамках теста:</span> <Link
                    to={'/tests/show/' + this.state.object.Test.ID}>{this.state.object.Test.Title}</Link></div>
            )
        } else {
            return null;
        }

    }

    renderImprovement() {
        if (this.state.object == null) {
            return null;
        } else {
            let formatDate = (iso_date) => {
                let date = new Date(iso_date);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();

                if (dt < 10) {
                    dt = '0' + dt;
                }
                if (month < 10) {
                    month = '0' + month;
                }

                return dt + '.' + month + '.' + year
            }
            return (
                <div className="container">
                    <ol className="breadcrumb">
                        <li><Link to="/improvements">Улучшения</Link></li>
                        <li><a href="#">{this.state.object.Title}</a></li>
                    </ol>
                    <div className="page__head">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-with-likes">
                                    <h1 className="page__head-title">{this.state.object.Title}</h1>
                                    <div className="button_box">
                                        {this.renderFavoriteButton()}
                                    </div>
                                </div>

                                <div className="page__head-desc">{this.state.object.ProblemStatus}</div>
                            </div>
                        </div>
                    </div>

                    <div className="idea__meta">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="idea__meta-item"><span>Автор:</span> <Link
                                    to={"/user/" + this.state.object.Author._id}>{this.state.object.Author.Name}</Link>
                                </div>
                                <div className="idea__meta-item"><span>Категория:</span> <a
                                    href="#">{this.state.object.Route.Title}</a></div>
                                <div className="idea__meta-item"><span>Тип:</span> <a
                                    href="#">{this.state.object.ProblemType.Title}</a></div>
                                <div className="idea__meta-item">
                                    <span>Регион:</span> {this.state.object.Address.Region.Title}</div>
                                <div className="idea__meta-item">
                                    <span>Создано:</span> {formatDate(this.state.object.Created)}</div>
                                <div className="idea__meta-item">
                                    <span>Изменено:</span> {formatDate(this.state.object.Modified)}</div>
                                {this.testLink()}

                            </div>
                            <div className="col-md-6">
                                <div className="idea__meta-item"><span>Адрес:</span> {this.state.object.Address.address}
                                </div>
                                <div className="idea__meta-item idea__meta-map">
                                    {this.renderMap()}
                                </div>
                            </div>
                        </div>

                    </div>

                    <h2>Описание</h2>
                    <Markup className="pgr">{this.state.object.Description}</Markup>

                    <div className="idea__imgs">
                        <Attachments attachments={this.state.object.Attachments}/>
                    </div>

                    <div className="btn-group">
                        {this.renderAssignAction()}
                        {this.renderActions()}
                    </div>
                    {this.renderWorkflow()}
                    <Comments object={this.state.object} onSendComment={this.sendComment.bind(this)}
                              ts={this.state.ts} app={this.props.app} />

                </div>


            );
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">Улучшения</div>
                    </div>
                </header>
                <section className="wrapper">
                    {this.renderImprovement()}
                </section>
                {this.renderCommentModal()}
                {this.renderAssignModal()}
            </div>
        );
    }
}


export default withDispatch(withFiles(Improvement));
