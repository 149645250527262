class FormDataSmart {
  constructor(form, required) {
    this.data = {}
    let formDataArray = form.serializeArray();
    formDataArray.forEach((item, i) => {
      if (this.data.value !== '') {
        this.data[item.name] = [{value: item.value}];
      }
    });
    this.required = required;
  }

  set(name, value, item_name) {
      this.data[name] = [{value: value, name: item_name}];
  }

  append(name, value, item_name) {
      if (!this.data[name]) {
        this.data[name] = []
      }
      this.data[name].push({value: value, name: item_name})
  }

  validate() {
    let errorInfo = {};
    let isError = false;
    this.required.forEach((key) => {errorInfo[key] = false});
    Object.keys(this.data).forEach((name) => {
      if (this.data[name] == "" && this.required.includes(name)) {
        errorInfo[name] = true;
        isError = true;
      }
    });

    return [errorInfo, isError];

  }

  serialize() {
      let formData = new FormData();

      Object.keys(this.data).forEach((key) => {
         this.data[key].forEach((item, i) => {
           if (item.name) {
              formData.append(key, item.value, item.name)
           } else {
              formData.append(key, item.value);
            }
         });
      });
      return formData;
  }
}

export default FormDataSmart;
