import React from 'react';
import Header from './header.jsx'
import {withRouter} from 'react-router-dom'
import UploadFile from './upload_file.jsx'
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'


class TestForm extends React.Component {

    componentDidMount() {
        $.initializeForm($('form#test'));
    }

    routesOptions() {
        if(!this.props.app.storage.user_functions_plain)
            return
        return this.props.app.storage.user_functions_plain.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )

        })

    }

    getFormattedDate(ts) {
        var date = new Date(ts * 1000);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

        return [day + "." + month + "." + date.getFullYear(), hour + ":" + min];
    }

    loadObject(props) {
        this.props.setFiles('test_form', []);
        if (props.match.params.id) {
            props.app.api('GET', '/api/tests/' + props.match.params.id, {}, (data) => {
                //this.setState({'object': data, 'ts': new Date().getTime()});
                let form = $("form#test");
                let setField = (name, value) => {
                    let item = form.find("[name='" + name + "']");
                    item.parent().addClass('text-active');
                    if ((item[0].nodeName.toLowerCase() == 'input') && (item[0].type == 'checkbox')) {
                        item.prop('checked', value)
                        return;
                    }
                    item.val(value);
                    if (item[0].nodeName.toLowerCase() == 'select') {
                        jcf.getInstance(item).refresh();
                    }
                };

                setField('title', data.Title);
                setField('description', data.Description);
                setField('conditions', data.Conditions);
                let start = this.getFormattedDate(data.Start);
                setField('start-date', start[0]);
                setField('start-time', start[1]);
                let end = this.getFormattedDate(data.End);
                setField('end-date', end[0]);
                setField('end-time', end[1]);
                setField('member_count', data.MemberCount);
                setField('select-route', data.Functions.map((x) => x.id));
                setField('regions', data.Regions.map((x) => x.id));
                setField('hide_comments', data.HideComments);
                setField('hide_issue', data.HideIssue);
                //			setField('region_id', data.Region.ID);
                //			setField('idea_type', data.IdeaType);
                //			setField('user_benefit', data.UserBenefit);
                //			setField('company_benefit', data.CompanyBenefit);
                //			setField('effect', data.Effect);
                let addFile = (data, name) => {
                    let files = this.props.files['test_form'] || []
                    this.props.setFiles('test_form', [...files, {data: data, name: name}]);
                }
                data.Attachments.forEach((item, i) => {
                    let path = item.split('/');
                    let name = path[path.length - 1];
                    $.ajax({
                        url: item,
                        xhrFields: {responseType: 'blob'},
                        success: (blobData) => {
                            let reader = new FileReader();
                            reader.readAsDataURL(blobData);
                            reader.onloadend = () => {
                                addFile(reader.result, name)
                            };
                        }
                    })

                });


            }, () => {
            })
        }
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    save() {
        let getTime = (prefix) => {
            let datestr = $('#' + prefix + '-date').val()
            let timestr = $('#' + prefix + '-time').val()
            let dateCmp = datestr.split('.');
            let timeCmp = timestr.split(':')
            let date = new Date(dateCmp[2], dateCmp[1] - 1, dateCmp[0], timeCmp[0], timeCmp[1])
            return date.getTime() / 1000;
        };


        let required = ['title', 'description', 'conditions', 'member_count'];
        let bool_required = ['hide_comments', 'hide_issue']
        let form = $("form#test");
        let formDataArray = form.serializeArray();
        let errorInfo = {};
        let isError = false;
        let data = {};
        required.forEach((key) => {
            errorInfo[key] = false
        });
        formDataArray.forEach((item) => {
            if (required.includes(item.name)) {
                if (item.value == "") {
                    errorInfo[item.name] = true;
                    isError = true;
                } else {
                    data[item.name] = item.value;
                }
            }
            if (bool_required.includes(item.name)) {
                data[item.name] = true;
            }
        });

        required.forEach((key) => {
            $('[name=' + key + "]").parent().toggleClass('text-error', errorInfo[key]);
        });

        let regions = $('select#regions').val()
        let regionsOK = regions && regions.length > 0

        if (!regionsOK) {
            regions = []
        }

        let routes = $('select#select-route').val()
        let routesOK = routes && routes.length > 0
        if (!routesOK) {
            routes = []
        }

        //$('select#regions').parent().toggleClass('text-error', !regionsOK);
        //$('select#select-route').parent().toggleClass('text-error', !routesOK);


        if (isError) {// || !regionsOK ) { // || !routesOK) {
            return;
        }
        let formData = new FormData();

        required.forEach((item, i) => {
            formData.append(item, data[item]);
        });
        bool_required.forEach((item) => {
            formData.append(item, !!data[item]);
        });
        //formData.append('hide_issue', data['hide_issue']);
        //formData.append('hide_comments', data['hide_comments']);


        if (this.props.match.params.id) {
            formData.append('id', this.props.match.params.id);
        }
        formData.append('start', getTime('start'));
        formData.append('end', getTime('end'));
        formData.append('regions', regions.join(';'));
        formData.append('functions', routes.join(';'));

        $('form#test img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });

        this.props.app.api_post('/api/tests', formData, (data) => {
            this.props.setFiles('test_form', []);
            this.props.history.push("/tests/show/" + data.id);
        }, (data) => {
            if (data.responseJSON) {
                if (data.responseJSON.error) {
                    this.props.dispatch({
                        type: 'MODAL_SHOW',
                        modal: {
                            header: 'Ошибка отправки',
                            message: [data.responseJSON.error],
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        }
                    })
                }
            }
        });
    }


    timeOptions() {
        let result = []
        let h = 0;
        let m = 0;
        let pad = (n) => {
            if (n < 10) {
                return '0' + n.toString();
            } else {
                return n.toString();
            }
        }

        while (h < 24) {
            result.push(pad(h) + ':' + pad(m));
            m += 10;
            if (m >= 60) {
                h += 1;
                m = 0;
            }
        }
        return result.map(t => <option key={t}>{t}</option>);

    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Тесты
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page__head-title">Добавление нового теста</h1>
                                    <div className='new_item_descr'>Тесты – раздел, используемый для выполнения сотрудниками определенных действий по инструкции автора. В рамках него он совершает определенные действия, взаимодействует с продавцами, тестирует услугу…<br/>Сотрудники могут отправлять отчеты автору, в которых будут описывать свои наблюдения, замечания и рекомендации. Автор может использовать эти отчеты для оценки эффективности текущих процессов и для определения областей, которые нуждаются в улучшении.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <form action="" id="test">
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Название</label>
                                        <input type="text" className="text-field" id="someField" name="title"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield01">Описание</label>
                                        <textarea id="somefield01" className="text-field" name="description"></textarea>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="text-field-holder">
                                                <label>Начало:</label>
                                                <input type="text" className="text-field hasDatepicker"
                                                       name="start-date" id="start-date" readOnly/>
                                                <i className="ico icon-calendar"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select-field-holder text-active">
                                                <label>Время:</label>
                                                <select className="custom-select" id="start-time" name="start-time"
                                                        data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'>
                                                    {this.timeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="text-field-holder">
                                                <label>Конец:</label>
                                                <input type="text" className="text-field hasDatepicker" name="end-date"
                                                       id="end-date" readOnly/>
                                                <i className="ico icon-calendar"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select-field-holder text-active">
                                                <label>Время:</label>
                                                <select className="custom-select" id="end-time" name="end-time"
                                                        data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false}'>
                                                    {this.timeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="test-conditions">Критерии теста</label>
                                        <textarea data-scroll id="test-conditions" className="text-field"
                                                  name="conditions"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="select-field-holder text-active">
                                                <label>Участники:</label>
                                                <input className="text-field" name="member_count" defaultValue="1"
                                                       type="number" min="0" step="1"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="select-field-holder text-active">
                                        <label>Регионы:</label>
                                        <select className="custom-select form-field" name="regions" multiple
                                                data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false, "useCustomScroll": true, "multipleCompactStyle": true}'
                                                id="regions">
                                            {this.regionOptions()}
                                        </select>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Департаменты:</label>
                                        <select className="custom-select" name="select-route" id="select-route" multiple
                                                data-jcf='{"wrapNative": false, "wrapNativeOnMobile": false, "useCustomScroll": true, "multipleCompactStyle": true}'>
                                            {this.routesOptions()}
                                        </select>
                                    </div>
                                    <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="hide_issue" name="hide_issue"/>
                                        <label htmlFor="hide_issue" className="checkbox-label">Не создавать
                                            улучшения</label>
                                    </div>
                                    <div className="check-wrap">
                                        <input type="checkbox" className="checkbox" id="hide_comments"
                                               name="hide_comments"/>
                                        <label htmlFor="hide_comments" className="checkbox-label">Скрыть
                                            комментарии</label>
                                    </div>

                                    <UploadFile formId="test_form"/>

                                    <div className="form-actions">
                                        <a className="btn btn--black"
                                           onClick={this.save.bind(this)}>{this.props.match.params.id ? "Сохранить" : 'Добавить'}</a>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        );
    }
}

export default withDispatch(withRouter(withFiles(TestForm)));
