import React from 'react';
import Header from './header.jsx'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CommentListContent from './comment_list_content.jsx';
import Modal from './modal.jsx'

class CommentList extends React.Component {
	constructor() {
		super();
		let filters = sessionStorage.getItem("improvements_filters");
		let objFilters = {}
		if (filters) {
			objFilters = JSON.parse(filters);
		}
		this.state = {
			'filter_window': false,
			'filters': objFilters,
			'ts': new Date().getTime()
		}
	}

	reloadChildren() {
		this.setState({'ts': new Date().getTime()})
	}


	openFilters() {
		this.setState({'filter_window': true})
	}

	closeFilters() {
		this.setState({'filter_window': false})
	}

	saveFilters() {
		let data = $('form#filters-form').serializeArray();
		let filterObj = {}
		data.forEach((item) => {
			if (item.value != "") {
				filterObj[item['name']] = item['value'];
			}
		});

		this.setState({'filter_window': false, 'filters': filterObj})
		sessionStorage.setItem('comment_filters', JSON.stringify(filterObj))

	}

	clearFilters() {
		$('#filters-form').find('.form-field').val('');
		$('#filters-form').find('.custom-select').each((i, sel) => {
			jcf.getInstance($(sel)).refresh();
		});
		$('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
		sessionStorage.setItem("comment_filters", null);
	}

	filtersCounter() {
		let size = 0;
    	for (var key in this.state.filters) {
        	if (this.state.filters.hasOwnProperty(key)) size++;
    	}
    	if (size > 0) {
    		return (
    			<em className="state-indicator state-indicator--red">{size}</em>
    		)
    	} else {
    		return null;
    	}
	}

	renderFilters() {
		if (this.state.filter_window)
			return (
				<Modal>
				  <div className="title-holder">
				     <div className="title-frame">
				        <h2>Фильтр</h2>
				     </div>
				  </div>
				  <div className="modal-content">
				     <form action="" id="filters-form">
				     	<div className="row">
				     		<div className="col-md-4">
				     			<div className="text-field-holder">
				     			 <label>Дата создания, от</label>
				     			 <input type="text" name="date_from" className="text-field hasDatepicker form-field" defaultValue= {this.state.filters['date_from']} readOnly/>
				     			 <i className="ico icon-calendar"></i>
				     			</div>
				     		</div>
				     		<div className="col-md-4">
				     			<div className="text-field-holder">
				     			 <label>Дата создания, до</label>
				     			 <input type="text" name="date_to" className="text-field hasDatepicker form-field" defaultValue={this.state.filters['date_to']} readOnly/>
				     			 <i className="ico icon-calendar"></i>
				     			</div>
				     		</div>
				     	</div>
				     </form>
				  </div>
				  <div className="modal-actions">
				  		<a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
				        <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
				        <a className="link--underline modal-reset pull-right" onClick={this.clearFilters.bind(this)}>Сбросить всё</a>
				    </div>
				  <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
				</Modal>
			);
		else
			return null;
	}

	render() {
		return (
			<div>
				<header>
					<Header app={this.props.app} ts={this.state.ts}/>
					<div className="header__title">
						<div className="container">
						Комментарии к идеям
					</div>
					</div>
				</header>
				<section className="wrapper">
					<div className="current-section-menu">
						<div className="current-section-menu-inner">
							<div className="section-menu-line">
								<div className="container">
									<div className="select-field-holder text-active" style={{display:'none'}}>
									 <label>Сортировать:</label>
									 <select className="custom-select">
									   <option value="1">Сначала новые</option>
									   <option value="2">Сначала старые</option>
									 </select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="page__head">
							<div className="row">
								<div className="col-md-7">
									<ul className="page__filter">
										<li className="page__filter-item page__filter-important filter-on">
											<span className="page__filter-ico"><img src="static/img/star.svg" alt=""/></span>
											<a className="js-check-important" href="#">Отметить важное</a>
										</li>
										<li className="page__filter-item page__filter-remove filter-on">
											<span className="page__filter-ico"><img src="static/img/trash.svg" alt=""/></span>
											<a href="#">Удалить</a>
										</li>
										{/*
										<li className="page__filter-item page__filter-excel">
											<span className="page__filter-ico"><i className="ico icon-download"/></span>
											<a href="#">Экспортировать в Excel</a>
										</li>
										*/}
										<li className="page__filter-item page__filter-filter">
											<span className="page__filter-ico"><i className="ico icon-filter"/></span>
											<a className="fancy" onClick={this.openFilters.bind(this)}>Фильтры {this.filtersCounter()}</a>
											<i className="ico icon-expander-arrow"/>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div>
									<CommentListContent app={this.props.app} filters={this.state.filters}/>
			        </div>
</div>
				</section>
				{ this.renderFilters() }
			</div>
			);

	}

}


export default CommentList;
