import React from 'react';
import Header from './header.jsx'

import {BrowserRouter as Router, Route, Link} from "react-router-dom";

class HomePage extends React.Component {

    constructor() {
        super()
        this.state = {
            projects: [],
            statistics_region: null,
            statistics_country: null
        }
    }

    componentDidMount() {
        this.props.app.api('GET', '/api/projects', {}, (data) => {
            this.setState({projects: data})
        })

        this.props.app.api('GET', '/api/statistics', {'page': 'index'}, (data) => {
            this.setState({statistics_region: data['Regions']})
            this.setState({statistics_country: data['Country']})
        })
    }

    renderProjects() {
        return (<div className="home_projects">
            {
                this.state.projects.map((item) => {
                    return <Link key={item._id} to={"/projects/" + item._id}
                                 className="btn btn--black">{item.name}</Link>
                })
            }
        </div>)
    }


    renderCountryStatistics() {
        if (this.state.statistics_country == null) {
            return null;
        }

        let data = this.state.statistics_country
        return (
            <table className="statistics">
                <tbody>
                <tr>
                    <th style={{fontSize: '1.4em'}}>{data.Title}</th>
                    {data.ColumnsName.map((item) => {
                        return <th>{item}</th>
                    })}
                </tr>
                {
                    data.Values.map((item) => {
                        return (
                            <tr>
                                <td>
                                    <Link
                                        key={item.item.id}
                                        to={"/statistics/" + item.item.id + "/all/null/null"}
                                        className="statistics-link">
                                        {item.item.title}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={"/statistics/" + item.item.id + "/all/null/day"}
                                        className="statistics-link">
                                        {item.day}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={"/statistics/" + item.item.id + "/all/null/month"}
                                        className="statistics-link">
                                        {item.month}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={"/statistics/" + item.item.id + "/all/null/year"}
                                        className="statistics-link">
                                        {item.year}
                                    </Link>
                                </td>

                            </tr>
                        )
                    })
                }
                </tbody>
            </table>)
    }

    renderRegionsStatistics() {
        if (this.state.statistics_region == null) {
            return null;
        }

        let data = this.state.statistics_region
        return (
            <table className="statistics">
                <tbody>
                <tr>
                    <th style={{fontSize: '1.4em'}}>{data.Title}</th>
                    {data.ColumnsName.map((item) => {
                        return <th>{item}</th>
                    })}
                </tr>
                {
                    data.Values.map((item) => {
                        return (
                            <tr>
                                <td>{item.item.title}</td>
                                <td><Link to={"/statistics/ideas/region/" + item.item._id + '/month'}
                                          className="statistics-link">{item.ideas_count}</Link></td>
                                <td><Link to={"/statistics/improvements/region/" + item.item._id + '/month'}
                                          className="statistics-link">{item.improvements_count}</Link></td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>)
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">Walk The Talk</div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="dashboard">
                            <div className="row">
                                <div className="dashboard__items">
                                    <div className="dashboard__item col-md-3">
                                        <Link to="/ideas">
                                            <div className="dashboard__ico"><img src="static/img/idea-icon.svg" alt=""/>
                                            </div>
                                            <div className="dashboard__name">Идеи</div>
                                        </Link>
                                        <Link to="/ideas/new" className="dashboard__add">
                                            <div className="entity-icon__circle"><i className="ico icon-add"/></div>
                                        </Link>
                                    </div>
                                    <div className="dashboard__item col-md-3">
                                        <Link to="/improvements">
                                            <div className="dashboard__ico"><img src="static/img/improvement-icon.svg"
                                                                                 alt=""/></div>
                                            <div className="dashboard__name">Улучшения</div>
                                        </Link>
                                        <Link to="/improvements/new" className="dashboard__add">
                                            <div className="entity-icon__circle"><i className="ico icon-add"/></div>
                                        </Link>
                                    </div>
                                    <div className="dashboard__item col-md-3">
                                        <Link to="/tests">
                                            <div className="dashboard__ico" style={{'padding-top':'5px'}}><img src="static/img/test.svg" alt=""/>
                                            </div>
                                            <div className="dashboard__name">Тесты</div>
                                        </Link>
                                        <Link to="/tests/new" className="dashboard__add">
                                            <div className="entity-icon__circle"><i className="ico icon-add"/></div>
                                        </Link>
                                    </div>
                                    <div className="dashboard__item col-md-3">
                                        <Link to="/practices">
                                            <div className="dashboard__ico"><img src="static/img/practices.svg" style={{'padding-top':'5px'}} alt=""/>
                                            </div>
                                            <div className="dashboard__name">ЛРП</div>
                                        </Link>
                                        <Link to="/practices/new" className="dashboard__add">
                                            <div className="entity-icon__circle"><i className="ico icon-add"/></div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {this.renderProjects()}
                            <div className="">
                                {this.renderCountryStatistics()}
                                {this.renderRegionsStatistics()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default HomePage;
