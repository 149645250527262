import { connect } from 'react-redux'

const mapStateToProps = state => {
  return { files: state.files }
}

const mapDispatchToProps = dispatch => {
  return {
			setFiles: (form_id, files) => { dispatch({type:'SET_FILES', form_id: form_id, files: files})}
	}
}



export default (cls) => {
    return connect(mapStateToProps, mapDispatchToProps)(cls);
}
