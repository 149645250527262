import React from 'react';
import Header from './header.jsx'
import Modal from './modal.jsx'
import Comments from './comments.jsx';
import Attachments from './attachments.jsx';
import Markup from './markup.jsx';
import withDispatch from './withDispatch.jsx'
import TestsSurveyForm from './tests_survey_form.jsx';
import Points from './points.jsx';
import XLSX from 'sheetjs-style';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Test extends React.Component {
	constructor() {
		super();
		this.state = { 
            'survey': false,
			'object': null, 
			'comment': null, 
			'ts': null 
		}
	}

	loadObject(props) {
		props.app.api('GET', '/api/tests/' + props.match.params.id, {}, (data)=> {
			this.setState({'object': data, 'ts': new Date().getTime()});
			console.log(this.state.object);
		}, ()=> { console.log('error')})
	}

	componentWillMount() {
		this.loadObject(this.props)
	}

	componentWillReceiveProps(new_props) {
		this.loadObject(new_props);
	}

	componentDidMount() {
    	$.initializeForm($('.comments__item comment-add'));
	}

	actionById(actionId) {
		let action = this.state.object.Actions.filter((item) => {return item.id == actionId});
		if (action.length == 0) {
			return null;
		}
		return action[0];
	}


	dataURItoBlob(dataURI) {
	    // convert base64/URLEncoded data component to raw binary data held in a string
	    var byteString;
	    if (dataURI.split(',')[0].indexOf('base64') >= 0)
	        byteString = atob(dataURI.split(',')[1]);
	    else
	        byteString = unescape(dataURI.split(',')[1]);

	    // separate out the mime component
	    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	    // write the bytes of the string to a typed array
	    var ia = new Uint8Array(byteString.length);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }

	    return new Blob([ia], {type:mimeString});
	}

	joinTest() {
		let self = this;
		this.props.app.api_post('/api/tests/' + this.state.object._id + '/join', {}, (data) => {
				self.loadObject(self.props);
		}, ()=> { console.log('error')});
	}


	doSurvey() {
        this.setState({'survey': true});
	}

	getSurvey() {
		let self = this;
		this.props.app.api('GET', '/api/test_get_survey/' + this.state.object._id, {}, (surveyReportData)=> {
			console.log('get survey',surveyReportData);

			var worksheet = null;
			worksheet = XLSX.utils.json_to_sheet(surveyReportData.header,
			{
				skipHeader:true,
				origin: "A1"
			});
			XLSX.utils.sheet_add_json(worksheet,
				surveyReportData.results.data,			
				{
				  origin: surveyReportData.results.origin
				}
			  );


			surveyReportData.styles.forEach((item) => {
				worksheet[item.cell].s = item.style;        
			});

			surveyReportData.styles.forEach((item) => {
				worksheet[item.cell].s = item.style;        
			});

			worksheet['!cols'] = surveyReportData.widths;			  

	
			var workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Survey");
			XLSX.writeFile(workbook, "survey_results.xlsx", { compression: true })

		}, ()=> { console.log('error')})
	}


    saveSurvey(answers) {
        this.setState({'survey': false});
        // //creating of copy
        // this.state.object.Survey = JSON.parse(JSON.stringify(survey));
        // console.log(this.state.object.Survey);
		console.log(answers);

        let data = new FormData();
        data.append('test_id', this.state.object._id);

        // $('form#comment_form img.file-image').each((idx, elem) => {
        //     let filedata = JSON.parse(elem.attributes.data.value);
        //     
        // });

		for(var i = 0; i < answers.length;i++) {
			let a = answers[i];
			if(a.question_type != 'Загрузка файлов')
				continue
			// console.log(a.answer);
			for(var j = 0; j < a.answer.length; j++) {
				let f = a.answer[j];
				data.append('file', this.dataURItoBlob(f.data), 'answer'+i+'_'+f.name)
				f.data = null;
			}
		}

        data.append('answers', JSON.stringify(answers));


        let self = this;
        this.props.app.api_post('/api/test_survey', data, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
        }, (data) => {
            if (data.responseJSON.message) {
                self.props.dispatch({
                    type: 'MODAL_SHOW',
                    modal: {
                        header: 'Ошибка отправки',
                        message: [data.responseJSON.message],
                        buttons: [
                            {name: 'ok', title: 'OK', default: true}
                        ]
                    }
                })
            }
        });		
    }

    renderSurveyModal() {
        if (this.state.survey) {
            return <TestsSurveyForm app={this.props.app} surveyData={JSON.parse(this.state.object.Survey)}
                                onSave={this.saveSurvey.bind(this)}  onClose={() => this.setState({'survey': false})}/>;
        }
    }


	renderActions() {
		let result = [];
		if (this.state.object.CanJoin) {
			result.push(<a key="join" id="join" onClick={this.joinTest.bind(this)} className="btn btn--black">Присоединиться <Points action='join_test' app={this.props.app}/></a>);
		}
		if (this.state.object.CanAddImprovement) {
			result.push(<Link key="new_improvement" id="new_improvement" to={ "/improvements/new?test_id=" + this.state.object._id } className="btn btn--black">Добавить улучшение</Link>);
		}
		if (this.state.object.CanGetSurveyResult) {
			result.push(<a key="get_survey" id="get_survey" onClick={this.getSurvey.bind(this)} className="btn btn--black">Получить результаты</a>);
		}
		if (this.state.object.CanSurvey) {
			if(!this.state.object.SurveyAnswered) {
				result.push(<a key="survey" id="survey" onClick={this.doSurvey.bind(this)} className="btn btn--black">Пройти опрос <Points action='survey' app={this.props.app}/></a>);
			}
		}
		if (this.state.object.CanEdit) {
				result.push(<a href={"/test/edit/" + this.state.object._id} key="edit" id="edit" className="btn btn--black" onClick={(e) => {e.preventDefault(); this.props.history.push("/tests/edit/" + this.state.object._id); }}>Изменить</a>);
		}


		return result;

	}

	sendComment(response) {
		let text = $('#comment_text').val();
		if (text == "") {
			$('#comment_text').parent().toggleClass('text-error', true);
			return;
		}
		if (response) {
					text = response + text;
    }
		let data = new FormData();
		data.append('test_id', this.state.object._id);
		data.append('text', text);
		$('form#comment_form img.file-image').each((idx, elem) => {
			let filedata = JSON.parse(elem.attributes.data.value);
			data.append('file', this.dataURItoBlob(filedata.data), filedata.name);
		});
		let self = this;
		this.props.app.api_post('/api/test_comment', data, (data) => {
			self.loadObject(self.props);
			self.forceUpdate();
		}, (data)=> { console.log('error'); if (data.responseJSON.message) {
			self.props.dispatch({
					type: 'MODAL_SHOW',
					modal: {
								header: 'Ошибка отправки',
								message: [data.responseJSON.message],
								buttons: [
										{name: 'ok', title: 'OK', default: true}
								]
					}
			})

}});

	}

	problemItems(problems) {
		return problems.map((p, idx)=> {
			return (
					<li key={idx} className="problems__item">
						<span className="num">{idx+1}.</span>
						<div className="row">
							<div className="col-md-8">
								<div className="problems__item-desc">
									<Link to={'/improvements/show/' + p.id }>
									{ p.title}
									</Link>
								</div>
								<div className="problems__item-meta">
									Автор: {p.Author.name}
								</div>
							</div>
						</div>
					</li>

			)
		});
	}

	renderProblems(mine) {
		let problems = this.state.object.Problems.filter(p => p.mine === mine);
		if (problems.length == 0) {
			return null;
		}
		let title = mine ? 'Проблемы, найденные мной' : 'Проблемы, найденные другими участниками';
		return (
			<div className="problems">
				<h2>{title}<span>{problems.length}</span></h2>
				<ol className="problems__items">
					{this.problemItems(problems)}
				</ol>
			</div>
		);
	}

	renderCommentModal() {
		if (this.state.comment == null) {
			return null;
		}
		let action = this.actionById(this.state.comment);
		if (action == null) {
			return null;
		}
		return (
			<Modal>
				<div className="modal-content">
					<div className="text-field-holder text-area-holder">
					  <label htmlFor="action_comment">{action.windowTitle}</label>
					  <textarea id="action_comment" className="text-field"></textarea>
					</div>
					<div className="form-actions">
						<a className="btn btn--black" onClick={this.processActionComment.bind(this)}>{action.title}</a>
					</div>
				</div>
				<a onClick={() => { this.setState({'comment': null})}} className="close ico icon-close"></a>

			</Modal>
		)

	}

	renderImprovement() {
		if (this.state.object == null) {
			return null;
		} else {

			let formatISODate = (iso_date) => {
				let date= new Date(iso_date);
				let year = date.getFullYear();
				let month = date.getMonth()+1;
				let dt = date.getDate();

				if (dt < 10) {
				  dt = '0' + dt;
				}
				if (month < 10) {
				  month = '0' + month;
				}

				return dt + '.' + month + '.' + year
			}

			let formatDate = (iso_date) => {
				let date= new Date(iso_date * 1000);
				let year = date.getFullYear();
				let month = date.getMonth()+1;
				let dt = date.getDate();
				let hr = date.getHours();
				let min = date.getMinutes();

				let pad = (n) => {
					if (n < 10) {
						return '0' + n;
					} else {
						return n.toString();
					}
				}
				return pad(dt) + '.' + pad(month) + '.' + year+ ' ' + pad(hr)+':'+pad(min);
			}
			return (
					<div className="container">
								<ol className="breadcrumb">
								  <li><Link to="/tests">Тесты</Link></li>
								  <li><a href="#">{this.state.object.Title}</a></li>
								</ol>
								<div className="page__head">
									<div className="row">
										<div className="col-md-12">
											<h1 className="page__head-title">{this.state.object.Title}</h1>
											<div className="page__head-desc">{this.state.object.TestStatus}</div>
										</div>
									</div>
								</div>

								<div className="idea__meta">
									<div className="row">
										<div className="col-md-6">
											<div className="idea__meta-item"><span>Начало:</span> {formatDate(this.state.object.Start)}</div>
											<div className="idea__meta-item"><span>Окончание:</span>{formatDate(this.state.object.End)}</div>
											<div className="idea__meta-item"><span>Участники теста:</span> {this.state.object.Members.length} из {this.state.object.MemberCount}</div>
											<div className="idea__meta-item"><span>Контактное лицо:</span> <Link to={"/user/" + this.state.object.Author._id}>{this.state.object.Author.Name}<span style={{display: !this.state.object.Author.removed ? 'none':'' }}>(не работает)</span></Link></div>
											<div className="idea__meta-item" style={this.state.object.Functions.length == 0 ? {'display': 'none'} : {}}>
												<span>Департаменты:</span> <a href="#">{this.state.object.Functions.map(f => f.title).join(', ')}</a></div>
											<div className="idea__meta-item"  style={this.state.object.Regions.length == 0 ? {'display': 'none'} : {}}>
												<span>Регион:</span> {this.state.object.Regions.map(r => r.title).join(', ')}</div>
											<div className="idea__meta-item"><span>Создан:</span> {formatISODate(this.state.object.Created)}</div>

										</div>
									</div>

								</div>

								<h2>Какие цели мы преследуем?</h2>
								<Markup className="pgr">{this.state.object.Description}</Markup>
								<h2>Что тебя ждет при прохождении опроса/теста и на что ориентироваться при ответах?</h2>
								<Markup className="pgr">{this.state.object.Conditions}</Markup>

								<div className="idea__imgs">
									<Attachments attachments={this.state.object.Attachments}/>
								</div>

								<span className='survey_thanks' style={this.state.object.CanSurvey && this.state.object.SurveyAnswered ? {'display': 'block'} : {'display': 'none'}}>Спасибо за участие в опросе</span>
								<div className="btn-group">
									{ this.renderActions() }
								</div>
								{this.renderSurveyModal()}

								{ this.renderProblems(false) }

								{ this.renderProblems(true) }

								<Comments object={this.state.object} onSendComment={this.sendComment.bind(this)} ts={this.state.ts} app={this.props.app} />


					</div>


			);
		}
	}


	render() {
		return (
			<div>
				<header>
					<Header app={this.props.app} ts={this.state.ts}/>
					<div className="header__title">
						<div className="container">Тесты</div>
					</div>

				</header>
				<section className="wrapper">
					{ this.renderImprovement() }
				</section>
			</div>
			);

	}

}


export default withDispatch(Test);
