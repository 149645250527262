import React from 'react';
import Header from './header.jsx'
import { Link } from "react-router-dom";

import Markup from './markup.jsx';

import Modal from './modal.jsx';

import Telecoin from './telecoin.jsx';

class Project extends React.Component {

  constructor() {
    super();
    this.state = {
      data: {},
      form: false,
      action: '',
      message: '',
      reward: 0,
      reward_kind: ''
    }
  }

  componentDidMount() {
      this.props.app.api('GET', '/api/projects/' +  this.props.match.params.id, {}, (data) => {
          this.setState({data: data})

      })

  }

  getCurrentForm() {
      return this.state.action == "action" ? this.state.data.form : this.state.data.form_study
  }


  onAction() {
    //if (action == 'form') {
    //    this.setState({form: true});
    //} else {
        let params = {action: this.state.action};
        //if (action == 'action') {
          let data = $('form#filters-form').serializeArray();
          let obj = {}
          data.forEach((item) => {
            if (item.value != "") {
              obj[item['name']] = item['value'];
            }
          });
          let error = false;
          let form =
          this.getCurrentForm().forEach((item, index) => {
            let item_error = !obj[item.name];
            if (item_error) {
                error = true;
                $("#form-field-"+index).toggleClass("text-error");
            }
          });
          if (error) {
              return;
          }
          params.data = JSON.stringify(obj);
        //}
        this.setState({form: false, action: ''});
        this.props.app.api('POST', '/api/projects/' +  this.props.match.params.id, params, (data) => {
            this.setState({data: data.project, message: data.message, reward: data.reward, reward_kind: data.reward_kind});
        })
    //}


  }

  renderHelp() {
      return (<div className="project_wrapper">
          <div className="project_title"><Link style={{marginRight: "10px"}} to={"/projects/" + this.props.match.params.id  }>←</Link><span>Как это работает</span></div>
          <Markup enableHtml>{this.state.data.help_page}</Markup>
      </div>)
  }

  showForm(action) {
      this.setState({form: true, action: action});
  }

  renderData() {
      return (<div className="project_wrapper">
          <div className="project_cover">
              <img src={"/api/projects/" + this.props.match.params.id + "/image.png"}/>
          </div>
          <div>
              <Markup enableHtml>{this.state.data.description}</Markup>
          </div>
          <div className="project_motivation">
              {this.state.data.motivation}
          </div>
          <div className="project_links">

              <a href={this.state.data.help_link} target="_blank">{this.state.data.help_link_title}</a>
          </div>
          <div className="btn-group">
              <Link className="btn btn-black" to={"/projects/" + this.props.match.params.id + "/help"}>Как это работает</Link>
              <div  className="btn btn-black" onClick={this.showForm.bind(this, this.state.data.educated ? 'action' : 'study' )}>
                 {this.state.data.educated ? this.state.data.action_name : "Я прошел обучение"}
              </div>

          </div>


      </div>)
  }

  renderFormItems() {

      return this.getCurrentForm().map((form_item, index) => {
          if (form_item.options) {
              return (
                <div key={index} id={"form-field-" + index} className="select-field-holder text-active">
                 <label>{form_item.hint}</label>
                 <select className="custom-select form-field" name={form_item.name}>
                   { form_item.options.map((opt)=> {
                        return (
                            <option value={opt} key={opt}>{opt}</option>
                        )

                   })}
                 </select>
                </div>
              );
          } else {
              return (<div key={index} className="text-field-holder" id={"form-field-" + index}>
				     	    <label htmlFor={"field_" + index }>{form_item.hint}</label>
				     	    <input type={form_item.integer ? "number": "text"} className="text-field" name={form_item.name}/>
				     	</div>)
          }
      });
  }

  closeForm() {
      this.setState({form: false});
  }

  renderForm() {
      return (<Modal>
        <div className="title-holder">
           <div className="title-frame">
              <h2>{this.state.action == "action" ? this.state.data.action_name : "Я прошел обучение"}</h2>
           </div>
        </div>
        <div className="modal-content">
           <form action="" id="filters-form">
                {this.renderFormItems() }
           </form>
        </div>

        <div className="modal-actions">
            <a className="btn btn--black" onClick={this.onAction.bind(this, 'action')}>Отправить</a>
              <a className="link--underline" onClick={this.closeForm.bind(this)}>Отменить</a>
          </div>
        <a onClick={this.closeForm.bind(this)} className="close ico icon-close"></a>


      </Modal>);
  }

  closeDialog() {
      this.setState({
        message: '',
        reward: 0,
        reward_kind: ''
      })
  }

  renderReward() {
      if (this.state.reward_kind == 'T') {
          return <Telecoin background="#000000"/>
      } else if (this.state.reward_kind == 'G') {
          return <img style={{width: '19px', height: '19px', marginLeft: '5px', marginBottom: '4px'}}src="/static/img/coin.png"/>
      } else {
          return null;
      }
  }

  renderDialog() {
      return (<Modal>
        <div className="title-holder">
           <div className="title-frame">
              <h2>Награда</h2>
           </div>
        </div>

         <div className="modal-content">
              <div>{this.state.message}</div>
              <div style={{marginTop: "10px", display: 'flex', alignItems: 'end'}}> <span>{"Твоя награда: "+ this.state.reward}</span>{this.renderReward()}</div>
         </div>
        <div className="modal-actions">
            <a className="btn btn--black" onClick={this.closeDialog.bind(this)}>Закрыть</a>

        </div>
        <a onClick={this.closeDialog.bind(this)} className="close ico icon-close"></a>
      </Modal>);
  }

  renderModal() {
      if (this.state.form) {
          return this.renderForm();
      }

      if (this.state.message !== '') {
          return this.renderDialog();
      }

      return null;


  }

  render() {
    return (
        <div>
          <header>
            <Header app={this.props.app}/>
            <div className="header__title">
              <div className="container">
                {this.state.data.name}
              </div>
            </div>
          </header>
          <section className="wrapper">
            <div className="container">
                { this.props.match.params.help == "help" ? this.renderHelp() : this.renderData() }
            </div>
          </section>

          { this.renderModal() }
        </div>

    );
  }

}

export default Project;
