export default (cls, key) => {
    class ExtendedScroller extends cls {
      constructor() {
    		super();
    		this.handleScroll = this.scrollHandler.bind(this);
    	}

      scrollHandler() {
        let data = {'offset': document.documentElement.scrollTop}
        data[key] = this.state[key];
        this.props.history.replace(location.pathname, data)
        //console.log({a: Math.ceil(window.innerHeight) + Math.ceil(document.documentElement.scrollTop), c: Math.ceil(document.documentElement.offsetHeight)});
        if ( Math.ceil(window.innerHeight) + Math.ceil(document.documentElement.scrollTop) === Math.ceil(document.documentElement.offsetHeight)) {
            let scrollTop = document.documentElement.scrollTop;
            this.onScrolledToEnd(function() {  document.documentElement.scrollTop = scrollTop;  });
        }
      }
      componentDidMount() {
          if (super.componentDidMount) {
              super.componentDidMount();
          }
          window.addEventListener('scroll', this.handleScroll);
      }
      componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount();
        }
        window.removeEventListener('scroll', this.handleScroll);
      }
      componentWillMount() {
        let history_state = this.props.history.location.state;
        if ( history_state && history_state.offset && history_state[key]) {
          let new_state = {};
          new_state[key] = history_state[key];
          this.setState(new_state);
          document.documentElement.scrollTop - history_state.offset;
        } else {
          super.componentWillMount(this.props)
        }
      }
    }

    return ExtendedScroller;
}
