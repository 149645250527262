import React from 'react';
import Header from './header.jsx';
import RouteAssign from './routes_assign.jsx';
import GroupAssign from './ideas_group.jsx';
import ResponsibleAssign from './responsible_assign.jsx';
import IdeaStatusAssign from './idea_status_assign.jsx';
import Comments from './comments.jsx';
import Attachments from './attachments.jsx';
import Markup from './markup.jsx';
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
import Like from './like.jsx';
import Points from './points.jsx';
import {Link} from 'react-router-dom';
import Workflow from './workflow.jsx';


class Idea extends React.Component {
    constructor() {
        super();
        this.state = {
            'object': null,
            'action': null,
            'text': false,
            'ts': null,
            'routes': false,
            'responsible': false,
            'group': false
        }
    }

    loadObject(props) {
        props.app.api('GET', '/api/ideas/' + props.match.params.id, {}, (data) => {
            this.setState({'object': data, 'ts': new Date().getTime()});
        }, () => {
            console.log('error')
        })
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        this.loadObject(new_props);
    }

    componentDidMount() {
        $.initializeForm($('.comments__item comment-add'));
    }

    actionById(actionId) {
        let action = this.state.object.Actions2.filter((item) => {
            return item.id == actionId
        });
        if (action.length == 0) {
            return null;
        }
        return action[0];
    }

    processAction(actionId) {
        let action = this.actionById(actionId);

        console.log('Press: ')
        console.log(action)

        if (action.id === 'group') {
            this.setState({'group': true})
            return
        }

        if (action.id === 'routes') {
            this.setState({'routes': true})
            return
        }

        if (action.id === 'responsible') {
            this.setState({'responsible': true})
            return
        }

        // if (action.id.startsWith('make_')) {
        //     let self = this;
        //     console.log(action.id)
        //     this.props.app.api_post_json('/api/ideas/' + this.state.object._id, {
        //         'Action': action.id
        //     }, () => {
        //         self.loadObject(self.props);
        //     }, (e) => {
        //         console.log(e)
        //     });
        //     return
        // }

        if (action.id === "consider") {
            this.saveIdeaStatus(action.id, "", false)
            return;
        }

        if (action != null) {
            this.setState({'action': action})
            this.setState({'text': true})
        }
    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    sendComment(response) {
        let text = $('#comment_text').val();
        if (text == "") {
            $('#comment_text').parent().toggleClass('text-error', true);
            return;
        }
        if (response) {
            text = response + text;
        }
        let data = new FormData();
        data.append('idea_id', this.state.object._id);
        data.append('text', text);
        $('form#comment_form img.file-image').each((idx, elem) => {
            let filedata = JSON.parse(elem.attributes.data.value);
            data.append('file', this.dataURItoBlob(filedata.data), filedata.name);
        });
        let self = this;
        this.props.app.api_post('/api/idea_comment', data, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);

        }, (data) => {
            if (data.responseJSON.message) {
                self.props.dispatch({
                    type: 'MODAL_SHOW',
                    modal: {
                        header: 'Ошибка отправки',
                        message: [data.responseJSON.message],
                        buttons: [
                            {name: 'ok', title: 'OK', default: true}
                        ]
                    }
                })
            }
        });
    }

    toggleFavorite() {
        let self = this;
        let method = this.state.object.Favorite ? 'DELETE' : 'POST';
        this.props.app.api(method, '/api/ideas/' + this.state.object._id + '/fav', {}, (data) => {
            self.loadObject(self.props);
            self.forceUpdate();
            self.props.setFiles('comments_form', []);
        })
    }

    renderFavoriteButton() {
        return (
            <a key="favorite" className="edit-button" onClick={(e) => {
                e.preventDefault();
                this.toggleFavorite();
            }}>
                {this.state.object.Favorite ?
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star_a.png" alt=''/> :
                    <img style={{marginBottom: "-3px", marginLeft: "-15px"}} src="/static/img/star.png" alt=''/>}
            </a>
        )
    }

    renderEditButton() {
        if (this.state.object.CanEdit) {
            return (
                <a key="edit" className='edit-button' onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/ideas/edit/" + this.state.object._id);
                }}>
                    <img src="/static/img/edit.svg" className='edit-image' alt=''/>
                </a>
            )
        }
    }


    renderAssignAction() {
        let group_btn = [];
        let status_group_btn = []
        let link = this.state.object.Actions2.map((action) => {
            const respondBtns = ["accept", "work", "postpone"]
            let item = (
                <a id={action.id} key={action.id} onClick={this.processAction.bind(this, action.id, null)}
                   className="btn btn--black">{action.title} <Points action={'idea_'+action.id} app={this.props.app}/></a>
            );
            if (action.id === 'responsible' || action.id === 'routes' || action.id === 'move') {
                group_btn.push(item)
            } else if (respondBtns.includes(action.id)) {
                if (action.id.includes('accept')) {
                    item = (
                        <a id={action.id} key={action.id} style={{fontSize: '12px'}}
                           onClick={this.processAction.bind(this, action.id, null)}
                           className="btn btn--black">{action.title} <Points action={'idea_'+action.id} app={this.props.app}/></a>
                    );
                }
                status_group_btn.push(item)
            } else {
                return item
            }
        });

        if (group_btn.length > 0) {
            return (
                <div className='btn-group'>
                    {link}
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={group_btn.length === 0 ? {display: 'none'} : {}} id="route">Направить</a>
                        <div className="dropdown-content" id="route-content">
                            {group_btn}
                        </div>
                    </div>
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={status_group_btn.length === 0 ? {display: 'none'} : {}}  id="respond">Ответить</a>
                        <div className="dropdown-content" id="respond-content">
                            {status_group_btn}
                        </div>
                    </div>

                </div>
            )
        } else {
            return (
                <div className='btn-group'>
                    {link}
                    <div className="dropdown">
                        <a className="btn btn--black"
                           style={status_group_btn.length === 0 ? {display: 'none'} : {}} id="respond">Ответить</a>
                        <div className="dropdown-content">
                            {status_group_btn}
                        </div>
                    </div>
                </div>
            )
        }

    }

    saveIdeaStatus(actionId, text, fromWttFlag) {
        let self = this;

        let data = new FormData();
        data.append('idea_id', this.state.object._id);
        data.append('action', actionId);
        data.append('from_wtt', fromWttFlag)
        data.append('text', text)
        console.log(data)

        this.props.app.api_post('/api/idea_comment', data, (data) => {
            self.loadObject(self.props);
            self.setState({'action': null, 'text': false});
        }, (e) => {
            console.log(e)
        });
    }

    saveRoutes(routes, route_id) {
        let self = this;
        console.log(routes)
        this.props.app.api_post_json('/api/ideas/' + this.state.object._id, {
            'Routes': routes,
            'RouteId': route_id
        }, () => {
            self.loadObject(self.props);
            self.setState({'routes': false});
        }, (e) => {
            console.log(e)
        });
    }

    saveResponsible(responsible) {
        let self = this;
        this.props.app.api_post_json('/api/ideas/' + this.state.object._id, {'Responsible': responsible}, () => {
            self.loadObject(self.props);
            self.setState({'responsible': false});
        }, (e) => {
            console.log(e)
        });
    }

    saveGroup(ideas) {
        let self = this;
        console.log(ideas)
        this.props.app.api_post_json('/api/ideas/' + this.state.object._id, {'Group': ideas}, () => {
            self.loadObject(self.props);
            self.setState({'group': false});
        }, (e) => {
            console.log(e)
        });

    }

    renderIdeaStatusModal() {
        if (this.state.text) {
            return <IdeaStatusAssign app={this.props.app}
                                     action={this.state.action}
                                     onSave={this.saveIdeaStatus.bind(this)}
                                     onClose={() => this.setState({'action': null, 'text': false})}/>;
        }
    }

    renderRouteModal() {
        if (this.state.routes) {
            return <RouteAssign app={this.props.app}
                                routeId={this.state.object.Route.ID}
                                routes={this.state.object.Routes}
                                onSave={this.saveRoutes.bind(this)}
                                onClose={() => this.setState({'routes': false})}/>;
        }
    }

    renderResponsibleModal() {
        if (this.state.responsible) {
            return <ResponsibleAssign app={this.props.app}
                                      responsible={this.state.object.Responsible}
                                      onSave={this.saveResponsible.bind(this)}
                                      onClose={() => this.setState({'responsible': false})}/>;
        }
    }

    renderGroupModal() {
        if (this.state.group) {
            return <GroupAssign app={this.props.app}
                                object={this.state.object}
                                onSave={this.saveGroup.bind(this)}
                                onClose={() => this.setState({'group': false})}/>;
        }
    }

    renderMap() {
        if (this.state.object.MapImage) {
            return (
                <img width="100%" src={this.state.object.MapImage}/>
            )
        }
    }

    sendLike(direction) {
        this.props.app.api('POST', '/api/ideas/' + this.props.match.params.id + '/like/' + direction, {}, (data) => {
            this.setState({'object': data, 'ts': new Date().getTime()});
        }, () => {
            console.log('error')
        })
    }

    renderLike() {
        return (<div className="likes_new">
                <Like kind="up" value={this.state.object.Likes.up} disabled={this.state.object.Likes.current_count <= 0}
                      allowClick={!this.state.object.Likes.on || this.state.object.Likes.current_count > 0} onClick={this.sendLike.bind(this, 'up')}/>
                <Like kind="down" value={this.state.object.Likes.down}
                      disabled={this.state.object.Likes.current_count >= 0} allowClick={!this.state.object.Likes.on || this.state.object.Likes.current_count<0}
                      onClick={this.sendLike.bind(this, 'down')}/>
            </div>
        )

    }

    renderAuthor() {
        return [this.state.object.Author].concat(this.state.object.Coauthors).map((x) => {
            return (
                <Link style={{marginRight: '10px'}} key={x._id} to={"/user/" + x._id}>{x.Name} <span style={{display: !x.removed ? 'none':'' }}>(не работает)</span></Link>
            )
        });
    }

    renderSimilarIdeas() {
        if (this.state.object.SimilarIdeas == null) {
            return null;
        } else {
            let ideas = this.state.object.SimilarIdeas;
            if (ideas.length == 0) {
                return null;
            }
            return (
                <div className='similar_ideas'>
                    <div className="problems">
                        <h2>Похожие идеи, созданные после взятия идеи в работу:<span>{ideas.length}</span></h2>
                        <ol className="problems__items">
                            {this.similarIdeaItems(ideas)}
                        </ol>
                    </div>
                </div>
            );
        }
    }

    similarIdeaItems(ideas) {
        return ideas.map((p, idx) => {
            return (
                <li key={idx} className="problems__item">
                    <span className="num">{idx + 1}.</span>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="problems__item-desc">
                                <Link to={'/ideas/show/' + p.ID}>
                                    {p.Title}
                                </Link>
                            </div>
                            <div className="problems__item-meta">
                                Автор: {this.renderSimilarIdeaAuthor(p.Author)}
                            </div>
                        </div>
                    </div>
                </li>
            )
        });
    }

    renderSimilarIdeaAuthor(authors) {
        return authors.map((x) => {
            return (
                <span>{x.Name} </span>
            )
        });
    }

    renderWorkflow() {
        if (this.state.object.Workflow) {
            return <Workflow app={this.props.app}
                                     workflow={this.state.object.Workflow}/>;
        }
    }

    renderIdeaParent() {
        if (this.state.object.ParentIdea == '') {
            return null;
        } else {
            let parentIdea = this.state.object.ParentIdea
            return (
                <div className='idea-group'>
                    <span>Сгруппирована со схожей идеей: </span>
                    <Link to={'/ideas/show/' + parentIdea.ID}>{parentIdea.Title}</Link>
                </div>
            )
        }
    }

    renderButtons() {
        if (this.state.object.ParentIdea === '') {
            return (
                <div className="btns-group">
                    {this.renderAssignAction()}
                </div>
            )
        }
    }

    renderIdea() {
        if (this.state.object == null) {
            return null;
        } else {
            let formatDate = (iso_date) => {
                let date = new Date(iso_date);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();

                if (dt < 10) {
                    dt = '0' + dt;
                }
                if (month < 10) {
                    month = '0' + month;
                }

                return dt + '.' + month + '.' + year
            }
            return (
                <div className="container">
                    <ol className="breadcrumb">
                        <li><Link to="/ideas">Идеи</Link></li>
                        <li><a href="#">{this.state.object.Title}</a></li>
                    </ol>
                    <div className="page__head">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-with-likes">
                                    <h1 className="page__head-title">{this.state.object.Title}</h1>
                                    <div className="button_box">
                                        {this.renderEditButton()}
                                        {this.renderFavoriteButton()}
                                    </div>
                                </div>

                                {this.renderIdeaParent()}
                                <div className="page__head-desc">{this.state.object.IdeaStatus}</div>
                            </div>
                        </div>
                    </div>
                    <div className="idea__meta">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="idea__meta-item"><span>Автор:</span> {this.renderAuthor()}</div>
                                <div className="idea__meta-item"><span>Категория: </span>
                                    <a href="#">{this.state.object.Route.Title}</a></div>
                                <div className="idea__meta-item"><span>Тип:</span> {this.state.object.IdeaTypeTitle}
                                </div>
                                <div className="idea__meta-item"><span>Регион:</span> {this.state.object.Region.Title}
                                </div>
                                <div className="idea__meta-item">
                                    <span>Создана:</span> {formatDate(this.state.object.Created)}</div>
                                <div className="idea__meta-item">
                                    <span>Изменена:</span> {formatDate(this.state.object.Modified)}</div>
                            </div>
                            <div className="col-md-6">
                                <div className="idea__meta-item idea__meta-map">
                                    {this.renderMap()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2>Описание</h2>
                    <Markup className="pgr">{this.state.object.Description}</Markup>
                    <h2>Польза для абонента</h2>
                    <Markup className="pgr">{this.state.object.UserBenefit}</Markup>
                    <h2>Польза для компании</h2>
                    <Markup className="pgr">{this.state.object.CompanyBenefit}</Markup>
                    <h2>Сегмент потребителей, целевая аудитория, потенциальный денежный эффект</h2>
                    <Markup className="pgr">{this.state.object.Effect}</Markup>
                    {this.renderSimilarIdeas()}
                    <div className="idea__imgs">
                        <Attachments attachments={this.state.object.Attachments}/>
                    </div>
                    {this.renderButtons()}
                    {this.renderWorkflow()}
                    {this.renderLike()}
                    <Comments object={this.state.object} onSendComment={this.sendComment.bind(this)} app={this.props.app} />
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">Идеи</div>
                    </div>
                </header>
                <section className="wrapper">
                    {this.renderIdea()}
                </section>
                {this.renderIdeaStatusModal()}
                {this.renderGroupModal()}
                {this.renderRouteModal()}
                {this.renderResponsibleModal()}
            </div>
        );
    }
}

export default withDispatch(withFiles(Idea));