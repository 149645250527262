import React from 'react';
import Modal from './modal.jsx';

class PracticeStatusAssign extends React.Component {

	constructor(props) {
		super(props);
		this.props = props
	}

	save() {
		let text = $('#action_comment').val();
		if (text === "") {
			$('#action_comment').parent().toggleClass('text-error', true);
			return;
		}
		let fromWtt = $("input#fromWtt")
		let fromWttFlag = '';
		if (fromWtt.length === 1) {
			if (fromWtt[0].checked) {
				fromWttFlag = 'true'
			}
		}
		this.props.onSave(this.props.action.id, text, fromWttFlag);
	}

	render() {
	  	let check = null;
		let actionStyle = {}
		if (this.props.action.id === 'solve') {
				check =  (<div className="check-wrap">
				                    	  <input type="checkbox" className="checkbox" id="fromWtt"/>
				                    	  <label htmlFor="fromWtt" className="checkbox-label">Об этой задаче я узнал из WTT</label>
                	</div>)
				actionStyle = {display: 'flex',alignItems: 'center', justifyContent: 'space-between'}
		}
		return (
			<Modal>
				<div className="modal-content">
					<div className="text-field-holder text-area-holder">
					  	<label htmlFor="action_comment">{this.props.action.windowTitle}</label>
					  	<textarea id="action_comment" className="text-field"/>
					</div>
					<div className="form-actions" style={actionStyle}>
						{ check }
						<a className="btn btn--black" onClick={this.save.bind(this)}>{this.props.action.title}</a>
					</div>
				</div>
				<a onClick={() => {this.props.onClose()}} className="close ico icon-close"/>
			</Modal>
		)

	}
}

export default PracticeStatusAssign;
