import React from 'react';
import Modal from './modal.jsx';
import withDispatch from './withDispatch.jsx'
import IdeasContentModal from "./ideas_content_modal.jsx";

class IdeaGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'search': '',
            'last_id': undefined
        }
        this.props = props
    }

    componentDidUpdate() {
        $.initializeForm($('.ideas_table'));
    }

    doSearch(value) {
        this.setState({'search': value})
    }

    save() {
        let idea = $('.group__action:checked').val()

        if (idea === undefined) {
            this.props.dispatch({
                type: 'MODAL_SHOW',
                modal: {
                    header: 'Ошибка',
                    message: [
                        'Необходимо выбрать идею для группировки'
                    ],
                    buttons: [
                        {name: 'ok', title: 'OK', default: true}
                    ]
                }

            });
        } else {
            this.props.onSave(idea);
        }

    }

    renderSearch() {
        return (
            <div className="text-wrap header__search"
                 title="Поиск по идеям можно осуществлять по названию, описанию, имени автора">
                <input type="text" style={{color: 'black', borderBottom: '1px solid #d1d8d6'}} className="text"
                       placeholder="Поиск по идеям" autoComplete="off" onChange={(e) => {
                    this.doSearch(e.target.value)
                }}/>
                <i className="ico icon-search"/>
            </div>
        )
    }

    handlerScroll(event) {
        const target = event.target
        console.log('scroll')
        if (target.scrollHeight - (target.scrollTop) <= target.clientHeight) {
            let last_id = $('.group__action').slice(-1)[0].value
            this.setState({'last_id': last_id})
        }
    }

    render() {
        return (
            <Modal group={true}>
                <div className="title-holder">
                    <div className="title-frame">
                        <h2>Похожие идеи в проработке</h2>
                    </div>
                </div>
                <div className="modal-content">
                    {this.renderSearch()}
                    <div className="ideas_table" onScroll={(e) => {
                        this.handlerScroll(e)
                    }}>
                        <div className="table table-hover">
                            <div>
                                <div>
                                    <div className="col-table-3">Название</div>
                                    <div className="col-table-4">Описание</div>
                                    <div className="col-table-2">Категория</div>
                                    <div className="col-table-1_5">Регион</div>
                                    <div className="col-table-1_5">Дата создания</div>
                                    <div className="col-table-4">Автор</div>
                                    <div className="col-table-1_5">Рейтинг</div>
                                    <div className="col-table-4">Отметить похожую идею</div>
                                </div>
                            </div>
                            <IdeasContentModal app={this.props.app} object={this.props.object}
                                               search={this.state.search} last_id={this.state.last_id}/>
                        </div>
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.save.bind(this)}>Группировать</a>
                    </div>
                </div>

                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default withDispatch(IdeaGroup);