import React from 'react';
import Header from './header.jsx'
import {withRouter} from 'react-router-dom'
import UploadFile from './upload_file.jsx'
import withFiles from './withFiles.jsx'
import withDispatch from './withDispatch.jsx'
import ResponsibleAssign from './responsible_assign.jsx';

class IdeaForm extends React.Component {
    constructor() {
        super();
        this.state = {region_name: '', coauthor_names: [], coauthors: [], files: [], assign_author: false};
    }

    loadObject(props) {
        this.props.setFiles('idea_form', []);
        if (props.match.params.id) {
            props.app.api('GET', '/api/ideas/' + props.match.params.id, {}, (data) => {
                //this.setState({'object': data, 'ts': new Date().getTime()});
                console.log(data)
                let form = $("form#idea");
                let setField = (name, value) => {
                    let item = form.find("[name='" + name + "']");
                    item.val(value);
                    item.parent().addClass('text-active');
                    if (item[0].nodeName.toLowerCase() == 'select') {
                        jcf.getInstance(item).refresh();
                    }
                };
                console.log(data.Region.Title);
                this.setState({
                    region_name: data.Region.Title,
                    coauthor_names: data.Coauthors.map((x) => x.Name),
                    coauthors: data.Coauthors.map((x) => x._id)
                });

                setField('title', data.Title);
                setField('description', data.Description);
                setField('route_id', data.Route.ID);
                this.updateTypes();
                setField('idea_type_id', data.IdeaTypeId);

                setField('user_benefit', data.UserBenefit);
                setField('company_benefit', data.CompanyBenefit);
                setField('effect', data.Effect);

                let addFile = (data, name) => {
                    let files = this.props.files['idea_form'] || []
                    this.props.setFiles('idea_form', [...files, {data: data, name: name}]);
                }
                data.Attachments.forEach((item, i) => {
                    let path = item.split('/');
                    let name = path[path.length - 1];
                    $.ajax({
                        url: item,
                        xhrFields: {responseType: 'blob'},
                        success: (blobData) => {
                            let reader = new FileReader();
                            reader.readAsDataURL(blobData);
                            reader.onloadend = () => {
                                addFile(reader.result, name)
                            };
                        }
                    })

                });


            }, () => {
            })
        }
    }

    componentWillMount() {
        this.loadObject(this.props)
    }

    componentWillReceiveProps(new_props) {
        //this.loadObject(new_props);

        if(this.props.app.storage.defaultRegion == undefined)
        {
            this.props.app.api('GET', '/api/profile', {}, (profile) => {
                if (profile.info.region) {
                    this.props.app.storage['defaultRegion'] = profile.info.region.ID;
                    this.setState({region_name: this.defaultRegion()});
                }
            });
        }
        else
        {
            this.setState({region_name: this.defaultRegion()});
        }
    }

    componentDidMount() {
        $.initializeForm($('form#idea'));
        //$("#file-1").fileinput({
        //    theme: 'fas',
        //    language: 'ru',
        //    uploadUrl: '#', // you must set a valid URL here else you will get an error
        //    allowedFileExtensions: ['jpg', 'png', 'gif'],
        //    overwriteInitial: false,
        //    maxFileSize: 1000,
        //    maxFilesNum: 10,
        //    //allowedFileTypes: ['image', 'video', 'flash'],
        //    slugCallback: function (filename) {
        //        return filename.replace('(', '_').replace(']', '_');
        //    }
        //});

    }

    routesOptions() {
        if (!this.props.app.storage.idea_routes) {
            return null;
        }        
        return this.props.app.storage.idea_routes.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )

        })

    }

    routeTypesOptions() {
        if (!this.props.app.storage.idea_routes) {
            return null;
        }
        if (this.props.app.storage.idea_routes.length == 0) {
            return null;
        }

        return this.props.app.storage.idea_routes[0].types.map((t) => {
            return (
                <option value={t.ID} key={t.ID}>{t.Title}</option>
            );

        })
    }    

    getIdeaRoute(id) {
        let currentRoute = null;
        this.props.app.storage.idea_routes.forEach((r) => {
            if (r.ID == id) {
                currentRoute = r;
            }
        });
        return currentRoute;
    }       

    updateTypes() {
        let selectedRoute = $('#select-route').val();
        let currentRoute = this.getIdeaRoute(selectedRoute);
        let selectTypes = $('#select-type');
        let data = "";
        if (currentRoute != null) {
            currentRoute.types.forEach((t) => {
                data += "\x3coption value='" + t.ID + "'\x3e" + t.Title + "\x3c/option\x3e";
            });
        }
        selectTypes.html(data);
        jcf.getInstance(selectTypes).refresh();

    }    

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    save() {
        let required = ['title', 'description', 'route_id', 'idea_type_id', 'user_benefit', 'company_benefit', 'effect'];
        let form = $("form#idea");
        let formDataArray = form.serializeArray();
        let errorInfo = {};
        let isError = false;
        required.forEach((key) => {
            errorInfo[key] = false
        });
        formDataArray.forEach((item) => {
            if (item.value == "" && required.includes(item.name)) {
                errorInfo[item.name] = true;
                isError = true;
            }
        });
        console.log(errorInfo);
        required.forEach((key) => {
            $('[name=' + key + "]").parent().toggleClass('text-error', errorInfo[key]);
        });
        if (isError) {
            return;
        }
        let formData = new FormData(form[0]);

        $('form#idea img.file-image').each((idx, elem) => {
            let data = JSON.parse(elem.attributes.data.value);
            formData.append('file', this.dataURItoBlob(data.data), data.name);
        });
        if (this.props.match.params.id) {
            formData.append('id', this.props.match.params.id);
        }

        $('span.error').text('');
        this.props.app.api_post('/api/ideas', formData, (data) => {
            this.props.setFiles('idea_form', []);
            this.props.history.push("/ideas/show/" + data.id);
        }, (data) => {
            if (data.responseJSON) {
                if (data.responseJSON.error) {
                    this.props.dispatch({
                        type: 'MODAL_SHOW',
                        modal: {
                            header: 'Ошибка отправки',
                            message: [data.responseJSON.error],
                            buttons: [
                                {name: 'ok', title: 'OK', default: true}
                            ]
                        }
                    })
                }
                if (data.responseJSON.message) {
  			  let error = data.responseJSON.message;
  				Object.keys(error).forEach((item, i) => {
  						let parent = $('[name=' + item + "]").parent();
  						parent.toggleClass('text-error', true);
  						parent.find('span.error').text(error[item]);

  				});
        }
            }

        });


    }

    defaultRegion() {
        let r = this.props.app.storage.regions.filter((x) => x.ID == this.props.app.storage.defaultRegion)
        if (r.length == 0) {
            return "";
        } else {
            return r[0].Title;
        }
    }

    addAuthor() {
        this.setState({'assign_author': true})
    }


    saveAuthor(id, title) {
        this.setState({
            coauthor_names: this.state.coauthor_names.concat([title]),
            coauthors: this.state.coauthors.concat([id]),
            assign_author: false
        })
    }

    clearAuthors() {
        this.setState({coauthor_names: [], coauthors: []})
    }


    renderAuthorModal() {
        if (this.state.assign_author) {
            return <ResponsibleAssign app={this.props.app} onSave={this.saveAuthor.bind(this)}
                                      onClose={() => this.setState({'assign_author': false})} title="Добавить автора"
                                      buttonTitle="Добавить"/>;
        }
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app}/>
                    <div className="header__title">
                        <div className="container">
                            Идеи
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page__head-title">{this.props.match.params.id ? 'Редактирование идеи' : 'Добавление новой идеи'}</h1>
                                    <div className='new_item_descr'>Идея может использоваться для формулировки новых идей, их разработки, оценки и внедрения, а также быть связанной с инновациями, новыми продуктами, услугами, процессами и т.д., а ее основной целью является создание новой ценности.</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <form action="" id="idea">
                                    <div className="text-field-holder">
                                        <label htmlFor="someField">Название</label>
                                        <input type="text" className="text-field" id="someField" name="title"/>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Категория:</label>
                                        <select className="custom-select" name="route_id" id="select-route"
                                                onChange={this.updateTypes.bind(this)}>
                                            {this.routesOptions()}
                                        </select>
                                    </div>
                                    <div className="select-field-holder text-active">
                                        <label>Тип:</label>
                                        <select className="custom-select" name="idea_type_id" id="select-type">
                                            {this.routeTypesOptions()}
                                        </select>
                                    </div>                                    

                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield01">Описание</label>
                                        <textarea id="somefield01" className="text-field" name="description"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label>Регион:</label>
                                        <input id="region_name" style={{backgroundColor: 'white !important'}}
                                               className="text-field" disabled value={this.state.region_name}/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield02">Польза для абонента <span
                                            className="error"/></label>
                                        <textarea id="somefield02" className="text-field" name="user_benefit"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield03">Польза для компании <span
                                            className="error"/></label>
                                        <textarea id="somefield03" className="text-field" name="company_benefit"/>
                                    </div>
                                    <div className="text-field-holder text-area-holder">
                                        <label htmlFor="somefield04">Сегмент потребителей, целевая аудитория,
                                            потенциальный денежный эффект <span className="error"/></label>
                                        <textarea id="somefield04" className="text-field" name="effect"/>
                                    </div>
                                    <div className="text-field-with-buttons">
                                        <div className="text-field-holder text-area-holder"
                                             style={{width: 'calc(100% - 30px)'}}>
                                            <label htmlFor="coauthors">Соавторы:</label>
                                            <textarea id="coauthors"
                                                      style={{backgroundColor: 'white !important', zIndex: -1}}
                                                      className="text-field" disabled
                                                      value={this.state.coauthor_names.join(', ')}/>
                                            <input type="hidden" name="coauthors"
                                                   value={this.state.coauthors.join(';')}/>
                                        </div>
                                        <div className="buttons">
                                            <a className="change-btn" onClick={this.addAuthor.bind(this)}>+</a>
                                            <a className="change-btn" onClick={this.clearAuthors.bind(this)}>-</a>
                                        </div>
                                    </div>
                                    <UploadFile formId="idea_form"/>
                                    <div className="form-actions">
                                        <a className="btn btn--black"
                                           onClick={this.save.bind(this)}>{this.props.match.params.id ? "Сохранить" : 'Добавить'}</a>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderAuthorModal()}
            </div>
        );
    }
}

export default withDispatch(withFiles(withRouter(IdeaForm)));
