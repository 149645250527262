import React from 'react';

class MarketHelp extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false
        }
    }

    renderHelp() {
        if (!this.state.open) {
            return null;
        }
        return (<div>
          <p>В начале каждого месяца мы выгружаем заказы за предыдущий месяц и отправляем их в течение двух недель на амбассадора вашего региона.</p>
          <p>Мы оставляем за собой право отказать в выдаче призов, если сотрудник умышленно "накручивает" рейтинг на портале. Спасибо за понимание.</p>
        </div>);
    }


    render() {
      return (<div className="market_help">
        <div onClick={() => { this.setState({open: !this.state.open})}} className="market_help_header"><img src="/static/img/help.svg"/><h3>Правила получения приза</h3></div>
        { this.renderHelp() }
      </div>)
    }

}

export default MarketHelp;
