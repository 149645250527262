export default (state = {}, action) => {
    switch (action.type) {
      case 'CLEAR_RESPONSE':
        return {};
      case 'SET_RESPONSE':
        return {response: action.response}
      default:
        return state;
   }
};
