import React from 'react';
import Header from './header.jsx'
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import IdeasContent from './ideas_content.jsx';
import Modal from './modal.jsx'

class Ideas extends React.Component {
    constructor() {
        super();
        let filters = sessionStorage.getItem("idea_filters");
        let objFilters = {}
        if (filters) {
            objFilters = JSON.parse(filters);
        }
        let sort = sessionStorage.getItem("idea_sort");
        if (!sort) {
            sort = 'date_desc';
        }
        this.state = {
            'filter_window': false,
            'idea_filters': objFilters,
            'sort': sort,
            'search': '',
            'ts': new Date().getTime()
        }

    }

    reloadChildren() {
        this.setState({'ts': new Date().getTime()})
    }

    updateTypes() {
        let selectedRoute = $('#select-route').val();
        let currentRoute = this.getIdeaRoute(selectedRoute);
        let selectTypes = $('#select-type');
        let data = "\x3coption value=''\x3eВсе\x3c/option\x3e";
        if (currentRoute != null) {
            currentRoute.types.forEach((t) => {
                data += "\x3coption value='" + t.ID + "'\x3e" + t.Title + '\x3c/option\x3e';
            });
        }
        selectTypes.html(data);
        jcf.getInstance(selectTypes).refresh();
    }    
    routesOptions() {
        return this.props.app.storage.idea_routes.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )
        })
    }

    getIdeaRoute(id) {
        let currentRoute = null;
        this.props.app.storage.idea_routes.forEach((r) => {
            if (r.ID == id) {
                currentRoute = r;
            }
        });
        return currentRoute;
    }    

    routeTypesOptions() {
        let currentRoute = this.getIdeaRoute(this.state.idea_filters['route_id']);
        if (currentRoute == null) {
            return null;
        }

        return currentRoute.types.map((t) => {
            return (
                <option value={t.ID} key={t.ID}>{t.Title}</option>
            );

        })
    }    

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    statusOptions() {
        return this.props.app.storage.statuses.statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    title() {
        if (this.props.page == 'decide')
            return "Идеи в работе";
        else if (this.props.page == 'created')
            return "Мой вклад";
        else if (this.props.page == 'solved')
            return "Решенные";
        else if (this.props.page == 'favorite')
            return 'Избранные'
        else
            return "Все идеи";
    }

    page() {
        if (this.props.page == 'decide')
            return "Decide";
        else if (this.props.page == 'created')
            return "Create";
        else if (this.props.page == 'solved')
            return "Solved";
        else if (this.props.page == 'favorite')
            return "Favorite";
        else
            return "All";
    }

    filterDisplay() {
        if (this.page() != 'Decide') {
            return {}
        } else {
            return {'display': 'none'}
        }
    }

    openFilters() {
        this.setState({'filter_window': true})
    }

    closeFilters() {
        this.setState({'filter_window': false})
    }

    saveFilters() {
        let data = $('form#filters-form').serializeArray();
        let filterObj = {}
        data.forEach((item) => {
            if (item.value != "") {
                filterObj[item['name']] = item['value'];
            }
        });
        sessionStorage.setItem('idea_filters', JSON.stringify(filterObj))

        this.setState({'filter_window': false, 'idea_filters': filterObj})

    }

    clearFilters() {
        $('#filters-form').find('.form-field').val('');
        $('#filters-form').find('.custom-select').each((i, sel) => {
            jcf.getInstance($(sel)).refresh();
        });
        $('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
        sessionStorage.setItem("idea_filters", null);
    }

    filtersCounter() {
        let size = 0;
        let pageSolved = this.page() == "Solved";
        for (var key in this.state.idea_filters) {
            if (!(pageSolved && key == 'route_id')) {
                if (this.state.idea_filters.hasOwnProperty(key)) size++;
            }
        }
        if (size > 0) {
            return (
                <em className="state-indicator state-indicator--red">{size}</em>
            )
        } else {
            return null;
        }
    }

    componentDidMount() {
        $.initializeForm($('.container'));
    }

    renderFilters() {
        if (this.state.filter_window)
            return (
                <Modal>
                    <div className="title-holder">
                        <div className="title-frame">
                            <h2>Фильтр</h2>
                        </div>
                    </div>
                    <div className="modal-content">
                        <form action="" id="filters-form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, от</label>
                                        <input type="text" name="date_from"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.idea_filters['date_from']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, до</label>
                                        <input type="text" name="date_to"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.idea_filters['date_to']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="check-wrap" style={{'display': 'none'}}>
                                <input type="checkbox" className="checkbox" id="check10"/>
                                <label htmlFor="check10" className="checkbox-label">Показать только важные</label>
                            </div>
                            <div className="select-field-holder text-active"
                                 style={this.page() == 'Solved' ? {'display': 'none'} : {}}>
                                <label>Категория:</label>
                                <select className="custom-select form-field" name='route_id' id='select-route'
                                        onChange={this.updateTypes.bind(this)}
                                        defaultValue={this.state.idea_filters['route_id']}>
                                    <option value="">Все</option>
                                    {this.routesOptions()}
                                </select>                                
                            </div>

                            <div className="select-field-holder text-active">
                                <label>Тип:</label>
                                <select className="custom-select form-field" name='idea_type_id' id='select-type'
                                        defaultValue={this.state.idea_filters['idea_type_id']}>
                                    <option value="">Все</option>
                                    {this.routeTypesOptions()}
                                </select>                                
                            </div>                            

                            <div className="select-field-holder text-active">
                                <label>Регион:</label>
                                <select className="custom-select form-field" name="region_id"
                                        defaultValue={this.state.idea_filters['region_id']}>
                                    <option value="">Все</option>
                                    {this.regionOptions()}
                                </select>
                            </div>
                            <div className="select-field-holder text-active"
                                 style={this.page() == 'Solved' ? {'display': 'none'} : {}}>
                                <label>Статус:</label>
                                <select className="custom-select form-field" name="status"
                                        defaultValue={this.state.idea_filters['status']}>
                                    <option value="">Все</option>
                                    {this.statusOptions()}
                                </select>
                            </div>
                            <div className="text-field-holder" style={{'display': 'none'}}>
                                <label htmlFor="someField">Автор</label>
                                <input type="text" className="text-field" id="someField" name="nameField"/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-actions">
                        <a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
                        <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
                        <a className="link--underline modal-reset pull-right" onClick={this.clearFilters.bind(this)}>Сбросить
                            всё</a>
                    </div>
                    <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
                </Modal>
            );
        else
            return null;
    }

    changeRoute(value) {
        let filterObj = {};
        for (var key in this.state.filtidea_filtersers) {
            if (this.state.idea_filters.hasOwnProperty(key) && key != 'route_id') {
                filterObj[key] = this.state.idea_filters[key];
            }
        }
        if (value != "") {
            filterObj['route_id'] = value
        }
        sessionStorage.setItem('idea_filters', JSON.stringify(filterObj))
        this.setState({'idea_filters': filterObj})
    }

    // changeRouteType(value) {
    //     // let filterObj = {};
    //     // for (var key in this.state.filtidea_filtersers) {
    //     //     if (this.state.idea_filters.hasOwnProperty(key) && key != 'route_id') {
    //     //         filterObj[key] = this.state.idea_filters[key];
    //     //     }
    //     // }
    //     // if (value != "") {
    //     //     filterObj['route_id'] = value
    //     // }
    //     // sessionStorage.setItem('idea_filters', JSON.stringify(filterObj))
    //     // this.setState({'idea_filters': filterObj})
    // }
    changeSort(value) {
        sessionStorage.setItem("idea_sort", value);
        this.setState({"sort": value});
    }

    renderSort() {
        return (
            <div className="select-field-holder text-active">
                <label>Сортировать:</label>
                <select className="custom-select" defaultValue={this.state.sort}
                        onChange={(e) => this.changeSort(e.target.value)}>
                    <option value="date_desc">Сначала новые</option>
                    <option value="date_asc">Сначала старые</option>
                    <option value="title_asc">от А до Я</option>
                    <option value="title_desc">от Я до А</option>
                    <option value="likes_asc">Рейтинг по возрастанию</option>
                    <option value="likes_desc">Рейтинг по убыванию</option>
                </select>
            </div>
        )
    }

    renderContainer() {
        if (this.page() == "Solved") {
            return (
                <div className="container">
                    <div className="select-field-holder text-active" style={{float: 'left', width: '350px'}}>
                        <label>Категория:</label>
                        <select className="custom-select" defaultValue={this.state.idea_filters['route_id']}
                                onChange={(e) => {
                                    this.changeRoute(e.target.value)
                                }}>
                            <option value="">Все</option>
                            {this.routesOptions()}
                        </select>
                    </div>
                    {/* <div className="select-field-holder text-active" style={{float: 'left', width: '350px'}}>
                            <label>Тип:</label>
                            <select className="custom-select" defaultValue={this.state.idea_filters['idea_type_id']}
                                onChange={(e) => {
                                    this.changeRouteType(e.target.value)
                                }}>
                                <option value="">Все</option>
                                {this.routeTypesOptions()}
                            </select>
                    </div>      */}

                    {this.renderSort()}
                </div>
            )
        } else {
            return (
                <div className="container">
                    <ul>
                        <li>
                            <Link to="/ideas">Все</Link>
                        </li>
                        <li>
                            <Link to="/ideas/created">Мой вклад</Link>
                        </li>
                        <li>
                            <Link to="/ideas/decide">В работе</Link>
                        </li>
                        <li><Link to="/ideas/solved">Решенные идеи</Link></li>
                        <li>
                            <Link to="/ideas/favorite">Избранные</Link>
                        </li>
                        <li className="create_idea">
                            <Link to="/ideas/new"><i className="ico icon-add"/>Создать идею </Link>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    doSearch(value) {
        this.setState({'search': value})
    }

    renderSearch() {
        return (
            <div className="text-wrap header__search"
                 title="Поиск по идеям можно осуществлять по названию, описанию, имени автора">
                <input type="text" className="text" defaultValue={this.state.search} placeholder="Поиск по идеям"
                       autoComplete="off" onChange={(e) => {
                    this.doSearch(e.target.value)
                }}/>
                <i className="ico icon-search"/>
            </div>
        )
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">
                            Идеи
                            {this.renderSearch()}
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="current-section-menu">
                        <div className="current-section-menu-inner">
                            <div className="section-menu-line">
                                {this.renderContainer()}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-5">
                                    <h1>{this.title()}</h1>
                                </div>
                                <div className="col-md-7">
                                    <ul className="page__filter">
                                        <li className="page__filter-item page__filter-important filter-on">
                                            <span className="page__filter-ico"><img src="static/img/star.svg"
                                                                                    alt=""/></span>
                                            <a className="js-check-important" href="#">Отметить важное</a>
                                        </li>
                                        <li className="page__filter-item page__filter-remove filter-on">
                                            <span className="page__filter-ico"><img src="static/img/trash.svg" alt=""/></span>
                                            <a href="#">Удалить</a>
                                        </li>
                                        <li className="page__filter-item page__filter-filter"
                                            style={this.filterDisplay()}>
                                            <span className="page__filter-ico"><i className="ico icon-filter"/></span>
                                            <a className="fancy"
                                               onClick={this.openFilters.bind(this)}>Фильтры {this.filtersCounter()}</a>
                                            <i className="ico icon-expander-arrow"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <div className="table table-hover">
                                <div>
                                    <div>
                                        <div className="col-table-4">
                                            Название
                                        </div>
                                        <div className="col-table-2">Категория</div>
                                        <div className="col-table-1_5 ">Регион</div>
                                        <div className="col-table-1_5 ">Дата создания</div>
                                        <div
                                            className="col-table-3">{this.page() == 'Solved' ? 'Автор' : 'Статус/автор'}</div>
                                        <div className="col-table-1_5">Рейтинг</div>
                                        <div className="col-table-1_5" style={this.page() === 'Favorite' ? {} : {display:'none'}}>Избранное</div>
                                    </div>
                                </div>
                                <IdeasContent app={this.props.app} page={this.page()} filters={this.state.idea_filters}
                                              sort={this.state.sort} search={this.state.search}/>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderFilters()}
            </div>
        );
    }
}


export default Ideas;
