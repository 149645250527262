import React from 'react';

import {withRouter} from "react-router-dom";

import withScrollHandler from './withScrollHandler.jsx';

class IdeasContent extends React.Component {
    constructor() {
        super();
        this.state = {
            'ideas': []
        };
    }

    queryParams(props, last_id) {
        let params = {
            'pageSelected': props.page,
            'count': 10,
            'last_id': last_id,
            'sort': props.sort,
            'search': props.search
        };
        for (var key in props.filters) {
            if (props.filters.hasOwnProperty(key)) {
                params[key] = props.filters[key];
            }
        }
        console.log(params);
        return params;
    }


    loadData(props, last_id, scrollBack) {
        this.props.app.api('GET', '/api/ideas', this.queryParams(props, last_id), (data) => {
            var nesState = {};
            if (last_id) {
                this.setState({'ideas': this.state.ideas.concat(data)});
                //this.props.history.push('/ideas', {'improvements': this.state.ideas.concat(data)})
            } else {
                this.setState({'ideas': data});
                //this.props.history.push('/ideas', {'ideas': data});
            }
            if (scrollBack) {
                scrollBack();
            }
        }, () => {
            console.log('error')
        })
    }

    onScrolledToEnd(scrollBack) {
        if (this.state.ideas.length > 0) {
            let last_id = this.state.ideas[this.state.ideas.length - 1]._id;
            this.loadData(this.props, last_id, scrollBack);
        }
    }

    componentWillMount() {
        this.loadData(this.props)
    }

    componentWillReceiveProps(new_props) {
        if (new_props.search != this.props.search || new_props.sort != this.props.sort || new_props.page != this.props.page || new_props.filters != this.props.filters || new_props.page != this.props.page) {
            this.loadData(new_props)
        }
    }

    toggleFavorite(item) {
        let method = item.Favorite ? 'DELETE' : 'POST';
        this.props.app.api(method, '/api/ideas/' + item._id + '/fav', {}, (data) => {
            item.Favorite = method !== 'DELETE'
        })
    }

    renderFavoriteButton(item) {
        return (
            <a className="edit-button" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggleFavorite(item);
                e.target.src = item.Favorite ? "/static/img/star.svg" : "/static/img/star_a.png"
            }}>
                <img key={item._id} style={{marginBottom: "-10px", marginLeft: "25px", display: "table-cell"}} src="/static/img/star_a.png" alt=''/>
            </a>
        )
    }

    rows() {
        let formatDate = (iso_date) => {
            let date = new Date(iso_date);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();

            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }

            return dt + '.' + month + '.' + year
        }

        let getProp = (obj, prop, defaultValue) => {
            try {
                return obj[prop];
            } catch (error) {
                return defaultValue
            }
        }
        return this.state.ideas.map((item) => {
            return (
                <a href={"/ideas/show/" + item._id} key={item._id} className="ideas__item" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/ideas/show/" + item._id);
                }}>
                    <div>
                        <div className="check-wrap" style={{display: 'none'}}>
                            <input type="checkbox" className="checkbox ideas-checkbox" id="check01"/>
                            <label htmlFor="check01" className="checkbox-label"></label>
                        </div>
                        <div className="ideas__name">{item.Title}</div>
                    </div>
                    <div>{getProp(item.Route, 'Title', '')}</div>
                    <div>{getProp(item.Region, 'Title', '')}</div>
                    <div>{formatDate(item.Created)}</div>
                    <div>
                        <div className="ideas__status"
                             style={this.props.page == 'Solved' ? {'display': 'none'} : {}}>{item.IdeaStatus}</div>
                        <div className="ideas__performer">{item.Author.Name}</div>
                    </div>
                    <div>
                        <div className="likes_container">
                            <div>{getProp(getProp(item, 'Likes', {'count': 0}), 'count', 0)}</div>
                        </div>
                    </div>
                    <object style={this.props.page === 'Favorite' ? {} : {display: 'none'}} type="owo/uwu">
                        {this.renderFavoriteButton(item)}
                    </object>
                </a>
            );
        });
    }


    render() {
        return (
            <div>
                {this.rows()}
            </div>
        );
    }

}

export default withRouter(withScrollHandler(IdeasContent, 'ideas'));
