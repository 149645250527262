import React from 'react';

import UploadFile from './upload_file.jsx'
import Points from './points.jsx';

import { connect } from 'react-redux';


class CommentForm extends React.Component {
	constructor() {
		super();
		this.state = {
			'text': ''
		}
	}
	componentDidMount() {
    	$.initializeForm($('form#comment_form'));

    }

	handleChange(event) {
  		this.setState({'text': event.target.value})
	}

	handleSend() {
		let response = null;
		if (this.props.response) {
				response = '<color=#C882FF><link="userId:'+this.props.response.id+'">'+this.props.response.name+'</link></color>, '
		}

		this.props.onClick(response);
		this.props.clearResponse();
		this.setState({'text': ''})
	}

	renderResponse() {
			if (!this.props.response) {
					return null;
			}

			return (<div className="response_form">
					<span>Ответ: </span>
					<span style={{margin: '0 10px'}}>{this.props.response.name}</span>
					<span style={{cursor: 'pointer'}} className="ico icon-close" onClick={() => this.props.clearResponse()}/>

			</div>
			)
  }

  scrollTo(ref) {
		if (this.props.response && ref) {
		    ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
		  }
  }

	render() {

		return (
			<form ref={this.scrollTo.bind(this)} action="" id="comment_form">
				{ this.renderResponse() }
				<div className="text-field-holder text-area-holder">
				  <label htmlFor="comment_text">Добавьте комментарий (минимум 10 символов)</label>
				  <textarea id="comment_text" className="text-field" name="somefield01" value={this.state.text} onChange={this.handleChange.bind(this)}></textarea>
				</div>

				<UploadFile formId="comments_form"/>

				<div className="form-actions">
					<a className="btn btn--black" onClick={this.handleSend.bind(this)}>Отправить <Points action='comment' app={this.props.app}/></a>
				</div>
			</form>
		);

	}

}

const mapStateToProps = state => {
  return { response: state.comment.response }
}

const mapDispatchToProps = dispatch => {
  return {
			clearResponse: () => { dispatch({type:'CLEAR_RESPONSE'})}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
