import React from 'react';

class Telecoin extends React.Component {

    render() {
      return(
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.3468 7.28378H5.4515V4.54268H16.2716V7.28378H12.3764V20H9.3468V7.28378Z" fill={this.props.background}/>
        <rect x="7.3213" y="12.437" width="7.02525" height="2.17577" fill={this.props.background}/>
      </svg>);
    }

}

export default Telecoin;
