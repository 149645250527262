import React from 'react';

class IdeasContentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'ideas': [],
            'last_id': undefined,
            'checked': false,
        };
        this.props = props
    }

    queryParams(props, last_id) {
        let params = {
            'pageSelected': 'Group',
            'count': 10,
            'last_id': last_id,
            'search': props.search,
            'idea_id': props.object._id
        };
        return params;
    }


    loadData(props, last_id) {
        this.props.app.api('GET', '/api/ideas', this.queryParams(props, last_id), (data) => {
            if (last_id) {
                this.setState({'ideas': this.state.ideas.concat(data), 'checked': false});
            } else {
                this.setState({'ideas': data, 'checked': false});
            }
        }, () => {
            console.log('error')
        })
    }

    componentWillMount() {
        this.loadData(this.props)
    }

    componentWillReceiveProps(new_props) {
        if (new_props.search != this.props.search) {
            this.loadData(new_props)
        }

        if (new_props.last_id != this.props.last_id) {
            this.loadData(this.props, new_props.last_id);
        }
    }

    handleChangeRadio(e) {
        if (e.target.checked && !this.state.checked) {
            this.setState({
                "checked": true,
            })
            e.target.checked = true
        } else if (e.target.checked && this.state.checked) {
            this.setState({
                "checked": false,
            })
            e.target.checked = false
        }
    }

    rows() {

        let formatDate = (iso_date) => {
            let date = new Date(iso_date);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();

            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }

            return dt + '.' + month + '.' + year
        }
        let getProp = (obj, prop, defaultValue) => {
            try {
                return obj[prop];
            } catch (error) {
                return defaultValue
            }
        }

        let getDescription = (item) => {
            let size = 300
            if (item.length > size) {
                return item.slice(0, size) + '...';
            }
            return item
        }

        return this.state.ideas.map((item) => {
            return (
                <div className="ideas__item" target="_blank">
                    <div>
                        <div className="check-wrap" style={{display: 'none'}}>
                            <input type="checkbox" className="checkbox ideas-checkbox" id="check01"/>
                            <label htmlFor="check01" className="checkbox-label"/>
                        </div>
                        <a href={"/ideas/show/" + item._id} key={item._id} className="ideas__name"
                           target="_blank">{item.Title}</a>
                    </div>
                    <div className="ideas__status">{getDescription(item.Description)}</div>
                    <div>{getProp(item.Route, 'Title', '')}</div>
                    <div>{getProp(item.Region, 'Title', '')}</div>
                    <div>{formatDate(item.Created)}</div>
                    <div>
                        <div className="ideas__status">{item.IdeaStatus.Title}</div>
                        <div className="ideas__performer">{item.Author.Name}</div>
                        <div className="check-wrap check-favourites" style={{display: 'none'}}>
                            <input type="checkbox" className="checkbox favour-checkbox" id="favour01"/>
                            <label htmlFor="favour01" className="checkbox-label"/>
                        </div>
                    </div>
                    <div>
                        <div className="likes_container">
                            <div>{getProp(getProp(item, 'Likes', {'count': 0}), 'count', 0)}</div>
                        </div>
                    </div>
                    <div>
                        <input type="radio"
                               name="group-radio"
                               className="group__action"
                               value={item._id}
                               onClick={this.handleChangeRadio.bind(this)}
                               defaultChecked={item.ParentIdea.ID == this.props.object._id}/>
                    </div>
                </div>
            );
        });
    }


    render() {
        return (
            <div>
                {this.rows()}
            </div>
        );
    }
}

export default IdeasContentModal;
