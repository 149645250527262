import React from 'react';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import ViewProfile from './view_profile.jsx';


class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            $(".login-content.on").removeClass('on');
        }
    }

    openUserInfo() {
        this.setState({open: !this.state.open});
        $(".bell-content.on").removeClass('on');

        if ($(this).find('.js-ico').hasClass('icon-chevron')) {
            $(this).find('.js-ico').removeClass('icon-chevron');
            $(this).find('.js-ico').addClass('icon-expander-arrow');
        } else {
            $(this).find('.js-ico').addClass('icon-chevron');
            $(this).find('.js-ico').removeClass('icon-expander-arrow');
        }
    }

    openBell() {
        $('.bell-content').toggleClass('on');
        $(".login-content.on").removeClass('on');
    }

    renderLoginContent() {
        if (!this.state.open) {
            return null;
        }
        return (<span className="login-content on" ref={this.setWrapperRef}>
					<ViewProfile app={this.props.app} ts={this.props.ts}/>
				</span>);
    }


    render() {
        // console.log(this.props.app.user_data());
        return (
            <div className="container">
                <Link to="/" className="header__logo"/>
                <nav>
                    <ul>
                        <li><Link to="/ideas">Идеи</Link></li>
                        <li><Link to="/improvements">Улучшения</Link></li>
                        <li><Link to="/tests">Тесты</Link></li>
                        <li><Link to="/practices">Лучшие практики</Link></li>
                        <li><Link to="/market">Призы</Link></li>
                        {this.props.app.user_data()['role'] !== 'user' ?
                            <li><Link to="/reports">Отчеты</Link></li> : null}
                    </ul>
                </nav>
                <ul className="user-nav">
                    <li className="user-nav-bell" style={{'display': 'none'}}>
                        <a href="#" className="js-bell ico icon-bell" onClick={this.openBell.bind(this)}>
                            <em className="state-indicator state-indicator--red">10</em>
                            {/*<span className="bell-content">*/}
							{/*	<span className="bell-title">Уведомления</span>*/}
							{/*	<ul className="bell-items">*/}
							{/*		{this.notifications()}*/}
							{/*	</ul>*/}
							{/*</span>*/}
                        </a>
                    </li>
                    <li className="user-nav-login">
					<span className="js-login" onClick={this.openUserInfo.bind(this)}>
						<i className="ico icon-user-drop"/>
						<span>{this.props.app.user_data()['name']}</span>
						<i className="js-ico ico icon-chevron"/>
                        {this.renderLoginContent()}
					</span>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Header;