import React, { createRef, Component } from 'react'

class Suggest extends React.Component {

constructor() {
  super()

  this.state = {
      address: '',
      focus: false,
      suggestions: []
  }
  this.outsideClickEvent = this.outsideClick.bind(this);
}

outsideClick(e) {
    let target = $(e.target);
    let clickedInsideSelect = !!target.closest(this.elementRef).length;
    this.setState({focus: clickedInsideSelect});
}

componentDidMount() {
    document.addEventListener('click', this.outsideClickEvent);
}

componentWillUnmount() {
    document.removeEventListener('click', this.outsideClickEvent);
}

selectSuggestion(sg) {
    this.setState({address: sg.value, suggestions: []})
    this.props.onSelectLatLng({lat: sg.data.geo_lat, lng: sg.data.geo_lon, address: sg.value});
}

componentWillReceiveProps(new_props) {
    this.setState({address: new_props.address})
}


renderSuggestions() {
    if (this.state.suggestions.length == 0 || !this.state.focus) {
        return null;
    }

    return (
      <div className="jcf-select-drop jcf-select-custom-select jcf-select-jcf-hidden jcf-unselectable">
          <div className="jcf-select-drop-content">
              <span className="jcf-list jcf-scroll-active">
                  <div className="jcf-scrollable-wrapper" style={{position: 'relative', height: '210px', minWidth: '300px'}}>
                      <span className="jcf-list-content" style={{maxHeight: '210px', touchAction: 'pan-x', overflow: 'hidden', height: '206px'}}>
                          <ul>
                              { this.state.suggestions.map((sg, idx)=> {
                                    return (<li key={idx} onClick={()=> {this.selectSuggestion(sg)}}>
                                          <span className="jcf-option jcf-selected jcf-hover">{sg.value}</span></li>);
                              })}
                          </ul>
                      </span>
                  </div>

              </span>

          </div>

      </div>

    )
}
suggest(query) {
  $.ajax({
      'url': 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      'method': 'POST',
      'headers': {'Content-Type': "application/json", 'Authorization': 'Token 0f2bead10c934430490d1981cde11e6fe294a3be'},
      'data': JSON.stringify({'query': query}),
      'success': (data) => {
          this.setState({suggestions: data.suggestions});
      }
  })

}


inputChange(value) {

    this.setState({address: value})
    this.suggest(value);
}

setFocus(focus) {
  this.setState({focus: focus});
}


render() {
    return (<div ref={(node) => this.elementRef = node} className="select-field-holder text-field-holder">
                <label htmlFor="someField">Адрес</label>
                <span className="jcf-select jcf-unselectable jcf-select-custom-select jcf-select-form-field jcf-drop-active jcf-focus">
                  <input onFocus={()=> this.setFocus(true)} onChange={(e) => { this.inputChange(e.target.value) }} value={this.state.address} autoComplete="new-password" type="text" className="text-field" id="addressField" name="address[address]"/>
                  { this.renderSuggestions() }
                </span>
            </div>
)

}


}


export default Suggest;
