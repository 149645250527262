import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withRouter} from 'react-router-dom'

import withScrollHandler from './withScrollHandler.jsx';

class TestsContent extends React.Component {
	constructor() {
		super();
		this.state = {
			'tests': []
		};
	}

	queryParams(props, last_id) {
        let params = {
            'pageSelected': props.page,
            'count': 10,
            'last_id': last_id,
            'sort': props.sort,
            'search': props.search
        };
        for (var key in props.filters) {
            if (props.filters.hasOwnProperty(key)) {
                params[key] = props.filters[key];
            }
        }
        // console.log(params);
        return params;
    }

    loadData(props, last_id, scrollBack) {
        console.log(props);
        this.props.app.api('GET', '/api/tests', this.queryParams(props, last_id), (data) => {
            var nesState = {};
            if (last_id) {
                // console.log(data);
                this.setState({'tests': this.state.tests.concat(data)});
            } else {
                this.setState({'tests': data});
            }
            if (scrollBack) {
                scrollBack();
            }
        }, () => {
            console.log('error')
        })
    }	

	onScrolledToEnd(scrollBack) {
        if (this.state.tests.length > 0) {
            console.log("onScrolledToEnd");
            let last_id = this.state.tests[this.state.tests.length - 1]._id;
            this.loadData(this.props, last_id, scrollBack);
        }
    }

	componentWillMount() {
        this.loadData(this.props)
    }

    componentWillReceiveProps(new_props) {
        if (new_props.search != this.props.search || new_props.sort != this.props.sort || new_props.page != this.props.page || new_props.filters != this.props.filters || new_props.page != this.props.page) {
            this.loadData(new_props)
        }
    }



	rows() {

		let formatDate = (iso_date) => {
			let date= new Date(iso_date * 1000);
			let year = date.getFullYear();
			let month = date.getMonth()+1;
			let dt = date.getDate();
			let hr = date.getHours();
			let min = date.getMinutes();

			let pad = (n) => {
				if (n < 10) {
					return '0' + n;
				} else {
					return n.toString();
				}
			}
			return pad(dt) + '.' + pad(month) + '.' + year+ ' ' + pad(hr)+':'+pad(min);
		}
		let formatRegion = (regions) => {
			let items = [];
			if (regions.length == 0) {
				return items;
			}
			items.push(<span key="title" style={{marginRight: '10px'}} className="city">{regions[0].title}</span>)
			if (regions.length > 1) {
				let tooltip = regions.slice(1, regions.length).join(', ');
				let more = regions.length - 1;
				let reg = 'регионов'
				if (more % 10 == 1 && more % 100 != 11) {
					reg = 'регион'
				} else if (more % 10 > 1 && more % 10 < 5 && (more % 100 >= 20 || more % 100 <10)) {
					reg = 'региона'
				}


				items.push(<span key="ext" data-tooltip={tooltip}>и еще {regions.length - 1} {reg}</span>)
			}
			return items;

		}

		return this.state.tests.map((item, idx)=> {
			return (
				<a href={"/tests/show/" + item._id} key={item._id} className="test__item" onClick={(e) => {e.preventDefault(); this.props.history.push("/tests/show/" + item._id); }}>
                    <div>
                    	<div className="check-wrap" style={{display:'none'}}>
                    	  <input type="checkbox" className="checkbox ideas-checkbox" id="check01"/>
                    	  <label htmlFor="check01" className="checkbox-label"></label>
                    	</div>
						<h3 id={'test_title_'+idx} className="table__item-name">{item.Title}</h3>
						<div className="table__item-desc">{item.Description.substring(0, 200)}</div>
						<div className="table__item-region">Регион: {formatRegion(item.Regions)}</div>
						<div className="table__item-contact">Контактное лицо: <span>{item.Author.Name}</span></div>
                    </div>
                    <div>{formatDate(item.Start)}</div>
                    <div>{formatDate(item.End)}</div>
                    <div>
                    	<div className="ideas__status">{item.Members.length} из {item.MemberCount}</div>
                    	<div className="check-wrap check-favourites" style={{display:'none'}}>
                    	  <input type="checkbox" className="checkbox favour-checkbox" id="favour01"/>
                    	  <label htmlFor="favour01" className="checkbox-label"></label>
                    	</div>
                    </div>
				</a>
			);
		});
	}


	render() {
		return (
		<div>
			{ this.rows() }
		</div>
		);
	}

}

export default withRouter(withScrollHandler(TestsContent));
