import React from 'react';

import {withRouter} from "react-router-dom";

import withScrollHandler from './withScrollHandler.jsx';

class UserDetailsContent extends React.Component {
    constructor() {
        super();
        this.state = {
            'point_details': [],
            'telecoins_report': [],
            'report_type': 'points',
            'amount': 10
        };
    }
    loadData(props, scrollBack) {
        
        this.props.app.api('GET', '/api/points_report', {}, (data) => {
            var items = data['data'];
            items = items.slice(4)
            items = items.reverse()
            for(var x = 0; x < items.length; x++)
                items[x] = {
                    'item': items[x],
                    '_id': x
            };

            // if (scrollBack) {
            //     scrollBack();
            // }

            this.setState({point_details: items})
        })
        this.props.app.api('GET', '/api/telecoins_report', {}, (data) => {
            var items = data['data'];
            items = items.slice(4)
            items = items.reverse()
            for(var x = 0; x < items.length; x++)
                items[x] = {
                    'item': items[x],
                    '_id': x
            };

            this.setState({telecoins_report: items})
        })
    }

    componentWillMount() {
        this.loadData(this.props)
    }
    componentDidMount(){
        $.initializeForm($('.container'));
    }

    onScrolledToEnd(scrollBack) {
        this.setState({amount: this.state.amount+10})
    }

    points_rows() {
        let getValue = (value1, value2) => {
            if(value1)
                return "+"+value1;
            return "-"+value2;
        }

    }
    rows() {
        let getValue = (value1, value2) => {
            if(value1)
                return "+"+value1;
            return "-"+value2;
        }
        var source = this.state.point_details;
        if(this.state.report_type == 'telecoins')
            source = this.state.telecoins_report
        
        if(!source.length)
            return null;

        // console.log(source);

        return source.map((x) => {
            if (x['_id'] < this.state.amount)
            {
                // console.log(x.item.item[1]);
                return (
                    <div>
                        <div>
                            <div>{x.item.item[2]}</div>
                        </div>
                        <div>{x.item.item[1]}</div>
                        <div>{getValue(x.item.item[4], x.item.item[5])}</div>
                    </div>

                );
            }
        });

    }

    changeDetailsType(value) {
        this.setState({'amount': 10})
        this.setState({'report_type': value})
    }    


    render() {
        return (
        <div>
            <div className="select-field-holder text-active">
                <label>Тип:</label>
                <select className="custom-select"
                        onChange={(e) => {
                            this.changeDetailsType(e.target.value)
                        }}
                        defaultValue={this.state.report_type}
                        >
                    <option value="points">Очки</option>
                    <option value="telecoins">Телекоины</option>
                </select>
            </div>            
            {this.rows()}
        </div>
        );
    }

}

// export default UserDetailsContent;
export default withRouter(withScrollHandler(UserDetailsContent, 'details'));
