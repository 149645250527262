import React, { createRef, Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import Suggest from './suggest.jsx'


export default class MapSelect extends Component {
  constructor(props) {
    super();
    this.state = {
      address: props.address,
      zoom: 10,
      latlng: [55, 37]
    }

    //this.mapRef = createRef(Map)

  }

  getLocation() {
    let self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=> {
        self.setState({latlng: [position.coords.latitude, position.coords.longitude ]})

      });
    }
  }

  componentDidMount() {
    if (this.props.location) {
        return;
    }
    this.getLocation();
  }

  componentWillReceiveProps(new_props) {
      if (new_props.location) {
        this.setState({latlng: new_props.location})
      }
      if (new_props.address) {
        this.setState({address: new_props.address})
      }
  }


  onViewportChanged(v) {
    let self = this;
    $.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${v.center[0]}&lon=${v.center[1]}`, function(data){
          // self.props.onSetAddress(data.display_name);
          self.props.onSetAddress(data.lat + " - " + data.lon);
    });
    this.setState({
      latlng: v.center,
      zoom: v.zoom
    })

  }

  onSelectLatLng(info) {
      this.setState({address: info.address, latlng: [info.lat, info.lng]});
  }

  render() {
    const marker = (
      <Marker position={this.state.latlng}>
        <Popup>You are here</Popup>
      </Marker>
    )

    return (
      <div>
        <Suggest address={this.state.address} onSelectLatLng={this.onSelectLatLng.bind(this)}/>
        <input type="hidden" name="address[geo][Lat]" value={this.state.latlng[0]}/>
        <input type="hidden" name="address[geo][Long]" value={this.state.latlng[1]}/>
      <Map
        center={this.state.latlng}
        length={4}
        onViewportChanged={this.onViewportChanged.bind(this)}
        ref={this.mapRef}
        zoom={this.state.zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          //url="https://mwtt.tele2.ru/tiles/{z}/{x}/{y}.png"
          url="https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=WOWTwuCoEu9Cq616A39k"
        />
        {marker}
      </Map>
      </div>
    )
  }
}
