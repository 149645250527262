import React from 'react';
import Modal from './modal.jsx'
import withDispatch from './withDispatch.jsx'

class Login extends React.Component {


  formData() {
    let data = $('form#login-form').serializeArray();
    let filterObj = {}
    data.forEach((item) => {
      if (item.value != "") {
        filterObj[item['name']] = item['value'];
      }
    });
    return filterObj;

  }

  handleChange(event) {
    let newState = {};
    newState[event.target.id] = event.target.value;
    this.setState(newState);
  }

  doLogin() {
      let state = this.formData();
      let phone = "";
      for (var i = 2; i < state.phone.length; i++) {
        if ('0123456789'.includes(state.phone[i])) {
          phone += state.phone[i];
        }
      }

      
      $.post('/api/login', {'username': phone, 'password': state.password }, (data) => {
        this.props.setLoginData(data);
      }).fail((error) => {
        this.props.dispatch({
            type: 'MODAL_SHOW',
            modal: {
              header: 'Ошибка входа',
              message: [
                    error.responseJSON.message,
                    'Возможно ты указал неверный телефон или пароль. Если все данные верные, но войти не получается, то обратись, пожалуйста, к амбассадору проекта Walk The Talk в твоем регионе.'
              ],
              buttons: [
                  {name: 'ok', title: 'OK', default: true}
              ]
            }

        });
      });
  }

  componentDidMount() {
    $.initializeForm($('#login-form'));
  }

  sendPassword() {
      let state = this.formData();
      let phone = "";
      for (var i = 2; i < state.phone.length; i++) {
        if ('0123456789'.includes(state.phone[i])) {
          phone += state.phone[i];
        }
      }
      $.ajax({
        'url': '/api/login',
        'method': 'PUT',
        'data': {'username': phone},
        'success': (data) => { this.showPasswordError(data.message) },
        'error': () => {this.showPasswordError('Произошла ошибка')}

      });

  }

  showPasswordError(message) {
    this.props.dispatch({
        type: 'MODAL_SHOW',
        modal: {
            header: 'Подключение',
            message: [message],
            buttons: [
              {name: 'ok', title: 'OK', default: true}
            ]
        }
    });

  }

  showPasswordModal() {
      this.props.dispatch({
          type: 'MODAL_SHOW',
          modal: {
              header: 'Подключение',
              message: ['Для входа необходимо использовать пароль от личного кабинета Мой Tele2. Если ты его не знаешь, то можешь получить новый пароль в SMS'],
              buttons: [
                  {name: 'cancel', title: 'Отмена'},
                  {name: 'send', title: 'Получить пароль', default: true, onClick: this.sendPassword.bind(this)}
              ]
          }
      });
  }


  render() {
    return (
      <div>
        <div className="login-header"/>
        <div className="login-container">
        <div className="title-holder">
            <div className="title-frame">
              <h2>Вход в Walk The Talk</h2>
            </div>
        </div>
    <div className="">
       <form action="" id="login-form">
        <div className="text-field-holder">
            <label htmlFor="phone">Телефон</label>
            <input type="tel" className="text-field" id="phone" data-mask="tel" autoComplete="off" name="phone" />
        </div>
        <div className="text-field-holder">
            <label htmlFor="password">Пароль</label>
            <input type="password" className="text-field" id="password" autoComplete="off" name="password"/>
        </div>
        <div className="modal-actions">
              <a className="btn" onClick={this.doLogin.bind(this)}>Войти</a>
              <a className="link--underline" onClick={this.showPasswordModal.bind(this)}>Отправить пароль на номер телефона</a>
          </div>
       </form>
    </div>
  </div>

  </div>
      );

  }
}

export default withDispatch(Login);
