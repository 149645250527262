import React from 'react';
import Header from './header.jsx'
import withDispatch from './withDispatch.jsx'

class UserConfig extends React.Component {

    constructor() {
      super();
      this.state = {
          'email_notifications': false
      }
    }

    componentWillMount() {
      this.loadObject()
    }

    loadObject() {
        this.props.app.api('GET', '/api/user/config', {}, (data) => {this.setState(data)})
    }

    save() {
        this.props.app.api_post_json('/api/user/config', this.state, () => {}, () =>  {
            this.props.dispatch({
                type: 'MODAL_SHOW',
                modal: {
                    header: 'Сохранение настроек',
                    message: ['Произошла ошибка при сохранении'],
                    buttons: [{name: 'ok', title: 'OK', default: true}]
                }
            })

        });
    }

    render() {
      return (
      <div>
        <header>
          <Header app={this.props.app}/>
          <div className="header__title">
            <div className="container">
            Настройки
          </div>
          </div>
        </header>
        <section className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <form id="config_form">
                  <div className="check-wrap">
                    <input type="checkbox" className="checkbox ideas-checkbox" id="email_notifications" checked={this.state.email_notifications} onChange={(e) => this.setState({'email_notifications': !!e.target.checked})}/>
                    <label htmlFor="email_notifications" className="checkbox-label">Уведомление по электронной почте</label>
                  </div>
                  <div className="form-actions">
                    <a className="btn btn--black" onClick={this.save.bind(this)}>Сохранить</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

      </div>);
    }
}

export default withDispatch(UserConfig);
