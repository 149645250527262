import React from 'react';
import Header from './header.jsx'
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import ImprovementsContent from './improvements_content.jsx';
import Modal from './modal.jsx'

class Improvements extends React.Component {
    constructor() {
        super();
        let filters = sessionStorage.getItem("improvements_filters");
        let objFilters = {}
        if (filters) {
            objFilters = JSON.parse(filters);
        }
        let sort = sessionStorage.getItem("issue_sort");
        if (!sort) {
            sort = 'date_desc';
        }

        this.state = {
            'filter_window': false,
            'filters': objFilters,
            'sort': sort,
            'ts': new Date().getTime(),
            'selected_column': {
                'by_title': '',
                'by_category': '',
                'by_region': '',
                'by_date': '',
                'by_status': ''
            }
        }
    }

    reloadChildren() {
        this.setState({'ts': new Date().getTime()})
    }

    changeSort(value) {
        if(value.tagName == 'SPAN') {
            value = value.parentElement;
        }
        value = value.id;
        value = value.replace('h_', '');
        console.log(value);
        sessionStorage.setItem("issue_sort", value);
        
        let direction = sessionStorage.getItem("issue_sort_direction");
        if (!direction) {
            direction = 'desc';
        }
        else {
            if(direction == 'desc') {
                direction = 'asc';
            } else {
                direction = 'desc'
            }
        }
        sessionStorage.setItem("issue_sort_direction", direction);

        this.setState({"sort": value.replace('by_','')+"_"+direction});
        console.log(value+"_"+direction);


        for(var key in this.state.selected_column) {
            this.state.selected_column[key] = '';
        }

        this.state.selected_column[value] = 'sortable_column_active';
    
    }

    routesOptions() {
        return this.props.app.storage.routes.map((item) => {
            return (
                <option value={item.ID} key={item.ID}>{item.Title}</option>
            )
        })
    }

    getRoute(id) {
        let currentRoute = null;
        this.props.app.storage.routes.forEach((r) => {
            if (r.ID == id) {
                currentRoute = r;
            }
        });
        return currentRoute;
    }


    routeTypesOptions() {
        let currentRoute = this.getRoute(this.state.filters['route_id']);
        if (currentRoute == null) {
            return null;
        }

        return currentRoute.types.map((t) => {
            return (
                <option value={t.ID} key={t.ID}>{t.Title}</option>
            );

        })
    }

    regionOptions() {
        return this.props.app.storage.regions.map((r) => {
            return (
                <option value={r.ID} key={r.ID}>{r.Title}</option>
            );
        })

    }

    statusOptions() {
        let statuses = ['Новое', 'На решении', 'Требует проверки', 'На проверке', 'Реализовано', 'Отклонено']
        return statuses.map((s) => {
            return (
                <option value={s} key={s}>{s}</option>
            );
        });
    }

    updateTypes() {
        let selectedRoute = $('#select-route').val();
        let currentRoute = this.getRoute(selectedRoute);
        let selectTypes = $('#select-type');
        let data = "\x3coption value=''\x3eВсе\x3c/option\x3e";
        if (currentRoute != null) {
            currentRoute.types.forEach((t) => {
                data += "\x3coption value='" + t.ID + "'\x3e" + t.Title + '\x3c/option\x3e';
            });
        }
        selectTypes.html(data);
        jcf.getInstance(selectTypes).refresh();
    }

    title() {
        if (this.props.page == 'decide')
            return "Улучшения в работе";
        else if (this.props.page == 'created')
            return "Мой вклад";
        else if (this.props.page == 'favorite')
            return 'Избранные'
        else
            return "Все улучшения";
    }

    page() {
        if (this.props.page == 'decide')
            return "Decide";
        else if (this.props.page == 'created')
            return "Create";
        else if (this.props.page == 'favorite')
            return "Favorite";
        else
            return "All";
    }

    filterDisplay() {
        if (this.page() != 'Decide') {
            return {}
        } else {
            return {'display': 'none'}
        }
    }

    openFilters() {
        this.setState({'filter_window': true})
    }

    closeFilters() {
        this.setState({'filter_window': false})
    }

    saveFilters() {
        let data = $('form#filters-form').serializeArray();
        let filterObj = {}
        data.forEach((item) => {
            if (item.value != "") {
                filterObj[item['name']] = item['value'];
            }
        });

        this.setState({'filter_window': false, 'filters': filterObj})
        sessionStorage.setItem('improvements_filters', JSON.stringify(filterObj))

    }

    clearFilters() {
        $('#filters-form').find('.form-field').val('');
        $('#filters-form').find('.custom-select').each((i, sel) => {
            jcf.getInstance($(sel)).refresh();
        });
        $('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
        sessionStorage.setItem("improvements_filters", null);
    }

    filtersCounter() {
        let size = 0;
        for (var key in this.state.filters) {
            if (this.state.filters.hasOwnProperty(key)) size++;
        }
        if (size > 0) {
            return (
                <em className="state-indicator state-indicator--red">{size}</em>
            )
        } else {
            return null;
        }
    }

    renderFilters() {
        if (this.state.filter_window)
            return (
                <Modal>
                    <div className="title-holder">
                        <div className="title-frame">
                            <h2>Фильтр</h2>
                        </div>
                    </div>
                    <div className="modal-content">
                        <form action="" id="filters-form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, от</label>
                                        <input type="text" name="date_from"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.filters['date_from']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-field-holder">
                                        <label>Дата создания, до</label>
                                        <input type="text" name="date_to"
                                               className="text-field hasDatepicker form-field"
                                               defaultValue={this.state.filters['date_to']} readOnly/>
                                        <i className="ico icon-calendar"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="check-wrap" style={{'display': 'none'}}>
                                <input type="checkbox" className="checkbox" id="check10"/>
                                <label htmlFor="check10" className="checkbox-label">Показать только важные</label>
                            </div>
                            <div className="select-field-holder text-active">
                                <label>Категория:</label>
                                <select className="custom-select form-field" name='route_id' id='select-route'
                                        onChange={this.updateTypes.bind(this)}
                                        defaultValue={this.state.filters['route_id']}>
                                    <option value="">Все</option>
                                    {this.routesOptions()}
                                </select>
                            </div>
                            <div className="select-field-holder text-active">
                                <label>Тип:</label>
                                <select className="custom-select form-field" name="route_type_id" id='select-type'
                                        defaultValue={this.state.filters['route_type_id']}>
                                    <option value="">Все</option>
                                    {this.routeTypesOptions()}
                                </select>
                            </div>


                            <div className="select-field-holder text-active">
                                <label>Статус:</label>
                                <select className="custom-select form-field" name="status"
                                        defaultValue={this.state.filters['status']}>
                                    <option value="">Все</option>
                                    {this.statusOptions()}
                                </select>
                            </div>


                            <div className="text-field-holder" style={{'display': 'none'}}>
                                <label htmlFor="someField">Автор</label>
                                <input type="text" className="text-field" id="someField" name="nameField"/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-actions">
                        <a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
                        <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
                        <a className="link--underline modal-reset pull-right" onClick={this.clearFilters.bind(this)}>Сбросить
                            всё</a>
                    </div>
                    <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
                </Modal>
            );
        else
            return null;
    }

    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">
                            Улучшения
                            <div className="text-wrap header__search" style={{'display': 'none'}}>
                                <input type="text" className="text" id="search-text" placeholder="Поиск по улучшениям"
                                       autoComplete="off"/>
                                <i className="ico icon-search"/>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="current-section-menu">
                        <div className="current-section-menu-inner">
                            <div className="section-menu-line">
                                <div className="container">
                                    <ul>
                                        <li>
                                            <Link to="/improvements">Все</Link>
                                        </li>
                                        <li>
                                            <Link to="/improvements/created">Мой вклад</Link>
                                        </li>
                                        <li>
                                            <Link to="/improvements/decide">В работе</Link>
                                        </li>
                                        <li>
                                            <Link to="/improvements/favorite">Избранные</Link>
                                        </li>
                                        <li className="create_improvement">
                                            <Link to="/improvements/new"><i className="ico icon-add"/>Создать улучшение</Link>
                                        </li>
                                    </ul>
                                    <div className="select-field-holder text-active" style={{display: 'none'}}>
                                        <label>Сортировать:</label>
                                        <select className="custom-select">
                                            <option value="1">Сначала новые</option>
                                            <option value="2">Сначала старые</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="page__head">
                            <div className="row">
                                <div className="col-md-5">
                                    <h1>{this.title()}</h1>
                                </div>
                                <div className="col-md-7">
                                    <ul className="page__filter">
                                        <li className="page__filter-item page__filter-important filter-on">
                                            <span className="page__filter-ico"><img src="static/img/star.svg"
                                                                                    alt=""/></span>
                                            <a className="js-check-important" href="#">Отметить важное</a>
                                        </li>
                                        <li className="page__filter-item page__filter-remove filter-on">
                                            <span className="page__filter-ico"><img src="static/img/trash.svg" alt=""/></span>
                                            <a href="#">Удалить</a>
                                        </li>
                                        {/*
										<li className="page__filter-item page__filter-excel">
											<span className="page__filter-ico"><i className="ico icon-download"/></span>
											<a href="#">Экспортировать в Excel</a>
										</li>
										*/}
                                        <li className="page__filter-item page__filter-filter"
                                            style={this.filterDisplay()}>
                                            <span className="page__filter-ico"><i className="ico icon-filter"/></span>
                                            <a className="fancy"
                                               onClick={this.openFilters.bind(this)}>Фильтры {this.filtersCounter()}</a>
                                            <i className="ico icon-expander-arrow"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <div className="table table-hover">
                                <div>
                                    <div>
                                        <div className={"col-table-4 sortable_column " + this.state.selected_column['by_title']} 
                                            id="h_by_title" onClick={(e) => this.changeSort(e.target)}>
                                                Название 
                                            <img style={this.state.selected_column['by_title'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("issue_sort_direction") + ".png"} 
                                                id="by_title" alt=''/>
                                        </div>                                        
                                        <div className={"col-table-2 sortable_column " + this.state.selected_column['by_category']} 
                                            id="h_by_category" onClick={(e) => this.changeSort(e.target)}>
                                                Категория
                                            <img style={this.state.selected_column['by_category'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("issue_sort_direction") + ".png"} 
                                                id="by_category" alt=''/>
                                        </div>
                                        <div className="col-table-1_5 ">Регион</div>
                                        <div className={"col-table-1_5 sortable_column " + this.state.selected_column['by_date']}
                                            id="h_by_date" onClick={(e) => this.changeSort(e.target)}>
                                                Дата создания
                                            <img style={this.state.selected_column['by_date'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("issue_sort_direction") + ".png"} 
                                                id="by_date" alt=''/>
                                        </div>
                                        <div className={"col-table-3 sortable_column " + this.state.selected_column['by_status']}
                                            style={this.page() != 'Solved' ? {} : {display:'none'}}
                                            id="h_by_status" onClick={(e) => this.changeSort(e.target)}>Статус/Автор
                                            <img style={this.state.selected_column['by_status'] === 'sortable_column_active' ? {} : {display:'none'}} 
                                                className='sortDirection' src={"/static/img/" + sessionStorage.getItem("issue_sort_direction") + ".png"} 
                                                id="by_status" alt=''/>
                                        </div>
                                        <div className="col-table-1_5" style={this.page() === 'Favorite' ? {} : {display:'none'}}>Избранное</div>
                                    </div>
                                </div>
                                <ImprovementsContent app={this.props.app} page={this.page()}
                                                     sort={this.state.sort} filters={this.state.filters}/>
                            </div>
                        </div>
                    </div>
                </section>
                {this.renderFilters()}
            </div>
        );

    }

}


export default Improvements;
