import React from 'react';
import withDispatch from './withDispatch.jsx'

class Attachment extends React.Component {
    		constructor() {
						super();
						this.state = {
								content: 'error',
								image: '/static/img/doc.svg'
						}
				}

				onDataRecieved(data) {
						if (data.startsWith('image')) {
								this.setState({content: 'image', image: this.props.file})
						} else {
								this.setState({content: 'file'})
						}
				}

				componentDidMount() {
						$.ajax({
								url: '/meta' + this.props.file,
								method: 'GET',
								processData: false,
								success: (data) => this.onDataRecieved(data),
								error: () => this.setState({content: 'error'})
						})
				}

				render() {
						if (this.state.content == 'error') {
								return null;
						}
						let path = this.props.file.split('/')
						let name = path[path.length - 1];
						if (this.state.content == 'file') {
							 return (
									<div className="idea__img">
										<a href={this.props.file} download>
											<img src="/static/img/doc.svg"/>
											<div>{name}</div>
										</a>
									</div>
							)
						}
						return (
							<div className="idea__img">
								<img src={this.state.image} onClick={this.props.onShowFile}/>
								<div>{name}</div>
						  </div>
						);
				}
}

class Attachments extends React.Component {

  onShowFile(file) {
    this.props.dispatch({
        type: 'MODAL_SHOW',
        modal: {
            image: file
        }
    })
  }

	renderAttachments() {
		return this.props.attachments.map((file)=> {
			return (
				<div key={file} className="col-md-2 idea__img-item">
					<Attachment file={file} onShowFile={()=> this.onShowFile(file)}/>
				</div>
			);
		});
	}

	render() {
		return (
			<div className="img-row">
				{this.renderAttachments()}
			</div>
		)
	}
}

export default withDispatch(Attachments);
