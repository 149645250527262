import React from 'react';
import {Link, withRouter} from "react-router-dom";
import Modal from './modal.jsx'

class StatisticsContent extends React.Component {
    constructor() {
        super()
        let filters = sessionStorage.getItem("date_filters");
        let objFilters = {}
        if (filters != 'null' && filters != null) {
            objFilters = JSON.parse(filters);
        }

        this.state = {
            "filter_window": false, "filter": objFilters
        }
    }


    loadData(props) {
        let data = {
            'page': props.page, 'assignee_id': props.assignee_id, 'type': props.type, 'filter': props.filter
        }
        this.props.app.api('GET', '/api/statistics', data, (data) => {
            this.setState({'data': data['values']})
            this.setState({'header': data['header']})
        }, () => {
            console.log('error')
        })
    }

    componentWillMount() {
        this.loadData(this.props)
    }

    componentWillReceiveProps(new_props) {
        if (new_props.page != this.props.page || new_props.assignee_id != this.props.assignee_id || new_props.type != this.props.type || new_props.filter != this.props.filter) {
            this.loadData(new_props)
        }
    }

    openFilters() {
        this.setState({'filter_window': true})
    }

    closeFilters() {
        this.setState({'filter_window': false})
    }

    saveFilters() {
        let data = $('form#filters-form').serializeArray();

        let filterObj = {}
        data.forEach((item) => {
            if (item.value != "") {
                filterObj[item['name']] = item['value'];
            }
        });
        sessionStorage.setItem('date_filters', JSON.stringify(filterObj))
        this.setState({'filter_window': false, 'filters': filterObj})


        let filter = window.location.pathname.split('/')
        let url = filter[0] + '/' + filter[1] + '/' + filter[2] + '/' + filter[3] + '/' + filter[4] + '/' + filterObj['date_from'] + '-' + filterObj['date_to']
        this.props.history.push(url);
    }

    clearFilters() {
        $('#filters-form').find('.form-field').val('');
        $('#filters-form').find('.custom-select').each((i, sel) => {
            jcf.getInstance($(sel)).refresh();
        });
        $('#select-type').html("\x3coption value=''\x3eВсе\x3c/option\x3e");
        sessionStorage.setItem("date_filters", null);
        this.setState({'filter_window': false, 'filters': {}})

        let filter = window.location.pathname.split('/')
        let url = filter[0] + '/' + filter[1] + '/' + filter[2] + '/' + filter[3] + '/' + filter[4] + '/null'
        this.props.history.push(url);
    }

    filtersCounter() {
        let is_filtered = sessionStorage.getItem("date_filters")
        if (is_filtered === "null" || is_filtered === null) {
            return null
        } else {
            return (<em className="state-indicator state-indicator--red">{1}</em>)
        }
    }

    renderFilters() {
        let filters = sessionStorage.getItem("date_filters");
        let objFilters = {}
        if (filters != 'null' && filters != null) {
            objFilters = JSON.parse(filters);
        }
        if (this.state.filter_window) return (<Modal>
            <div className="title-holder">
                <div className="title-frame">
                    <h2>Фильтр</h2>
                </div>
            </div>
            <div className="modal-content">
                <form action="" id="filters-form">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text-field-holder">
                                <label>Дата создания, от</label>
                                <input type="text" name="date_from"
                                       className="text-field hasDatepicker form-field"
                                       defaultValue={objFilters['date_from']} readOnly/>
                                <i className="ico icon-calendar"></i>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-field-holder">
                                <label>Дата создания, до</label>
                                <input type="text" name="date_to"
                                       className="text-field hasDatepicker form-field"
                                       defaultValue={objFilters['date_to']} readOnly/>
                                <i className="ico icon-calendar"></i>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-actions">
                <a className="btn btn--black" onClick={this.saveFilters.bind(this)}>Показать результат</a>
                <a className="link--underline" onClick={this.closeFilters.bind(this)}>Отменить</a>
                <a className="link--underline modal-reset" onClick={this.clearFilters.bind(this)}>Сбросить
                    всё</a>
            </div>
            <a onClick={this.closeFilters.bind(this)} className="close ico icon-close"></a>
        </Modal>); else return null;
    }


    onClickMainBreadCrumb() {
        sessionStorage.setItem('date_filters', null)
        this.loadData(this.props)
    }

    renderBreadCrumbs(breads) {
        if(breads == null)
        {
            return null;
        }

        return (<div>
            <div>
                {breads.map((item) => {
                    return (<Link to={item.url} style={{color: "#c8cace"}}>
                        {item.Title}
                        {item.Title == "" ? "" : " > "}
                    </Link>)
                })}
            </div>
        </div>)
    }

    redirect(type, filter, value, title) {
        let date_from = null
        let date_to = null
        let newDate = new Date()
        if (filter === 'day'){
            date_from = newDate
            date_to = newDate
        }
        else if  (filter === 'month') {
            date_from = new Date(newDate.getFullYear(), newDate.getMonth(), 1)
            date_to = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0)
        }
        else if  (filter === 'quarter'){
            let quarter = Math.floor((newDate.getMonth() / 3));
            date_from = new Date(newDate.getFullYear(), quarter * 3, 1)
            date_to = new Date(newDate.getFullYear(), newDate.getMonth() + 3, 0)
        }
        else if  (filter === 'year'){
            date_from = new Date(newDate.getFullYear(), 0, 1)
            date_to = new Date(newDate.getFullYear(), 11, 31)
        }

        let filterObj = {
            'search': value.Title,
            'status': title
        }

         if (date_from !== null || date_to !== null){
             filterObj['date_from'] = date_from.toLocaleDateString('ru-RU')
             filterObj['date_to'] = date_to.toLocaleDateString('ru-RU')
         }

        let filter_name = "idea_filters"
        if (type === "improvements") {
            filter_name = "improvements_filters"
            delete filterObj['search'];
            filterObj['author_id'] = value.id
            filterObj['pageSelected'] = 'Statistics'
        }

        sessionStorage.setItem(filter_name, JSON.stringify(filterObj))
        this.props.history.push("/" + type)
    }

    renderRow(page_type, item_id, filter, value, title) {
        if (value == null) {
            return null
        }
        let type;
        if (page_type === 'redirect') {
            return (
                <td className="statistic-item"
                    onClick={() => this.redirect(type = this.props.type, filter, value = this.state.header, title)}>
                    <a className="statistics-link" style={{cursor: "pointer"}}> {value}</a>
                </td>)
        }

        return (<td className="statistic-item">
            <Link
                to={"/statistics/" + this.props.type + "/" + page_type + "/" + item_id + "/" + filter}
                className="statistics-link">
                {value}
            </Link>
        </td>)
    }


    renderEmptyData() {
        return (<span className="no-data-found">Данные отсутствуют</span>)
    }

    renderData(header, data) {
        return (<table style={{width: '100%', borderSpacing: "10px", borderCollapse:"inherit"}}>
            <tbody>
            <tr>
                <th className="statistics-content-header" style={{fontSize: '1.4em'}}>{header.Title}</th>
                {header.ColumnsName.map((item) => {
                    return <th>{item}</th>
                })}
            </tr>
            {data.map((item) => {
                return (<tr className="statistics-item">
                    {this.renderRow(item.item.page_type, item.item._id, null, item.item.title, item.item.title)}
                    {this.renderRow(item.item.page_type, item.item._id, "day", item.day, item.item.title)}
                    {this.renderRow(item.item.page_type, item.item._id, "month", item.month, item.item.title)}
                    {this.renderRow(item.item.page_type, item.item._id, "quarter", item.quarter, item.item.title)}
                    {this.renderRow(item.item.page_type, item.item._id, "year", item.year, item.item.title)}
                    {this.renderRow(item.item.page_type, item.item._id, header.ColumnsName[0], item.period, item.item.title)}
                </tr>)
            })}
            </tbody>
        </table>)
    }

    render() {
        if (this.state.data == null) {
            return null;
        }
        if (this.state.header == null) {
            return null;
        }
        return (<div>
            <div className="header__statistics">
                <div>
                    {this.renderBreadCrumbs(this.state.header.breads)}
                </div>
                <div className="page__filter-item page__filter-filter">
                    <a className="fancy" onClick={this.openFilters.bind(this)} style={{width: 'auto'}}>
                             <span className="page__filter-ico">
                                 <i className="ico icon-calendar" style={{fontSize: '24px'}}/>
                             </span>
                        {this.filtersCounter()}
                    </a>
                    <i className="ico icon-expander-arrow"/>
                </div>
            </div>
            {this.state.data.length === 0 ? this.renderEmptyData() : this.renderData(this.state.header, this.state.data)}
            {this.renderFilters()}
        </div>)
    }
}


export default withRouter(StatisticsContent);