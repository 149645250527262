import React from 'react';
import withFiles from './withFiles.jsx'

class UploadFile extends React.Component {

	openFileDialog() {
		console.log('addFile')
		$('#file-selector').trigger('click')
	}

	getFiles(addition) {
		let files = this.props.files[this.props.formId];
		if (!files) {
			files = [];
		}
		if (addition) {
			return [...files, addition];
		}
		return files;

	}

	addFile(event) {
		let files = Array.from(event.target.files);
		

		files.forEach((f)=> {
			let reader = new FileReader();
			reader.onload = (e) => {
				this.props.setFiles(this.props.formId, this.getFiles({'data': e.target.result, 'name': f.name}));
			};

			reader.readAsDataURL(f)
		});
	}

	deleteFile(index) {
		let newFiles = this.getFiles();
		newFiles.splice(index, 1);
		this.props.setFiles(this.props.formId, newFiles);

	}

	renderPreviews() {
		return this.getFiles().map((data, index) => {
			let content = data.data;
			console.log(typeof content);
			let width = 'auto';
			let height = 'auto';
			if (!data.data.startsWith("data:image")) {
					content = "/static/img/doc.svg";
					width = '156px';
					height = '156px';
			}
			let storedData = JSON.stringify({data: data.data, name: data.name});
			return(
				<div className="file-preview-frame krajee-default  kv-preview-thumb" key={index}>
					<div className="kv-file-content">
						<img className="file-image" src={content} data={storedData} style={{'width':width, 'height':height,'maxWidth':'100%','maxHeight':'100%'}}/>
					</div>
					<div className="kv-file-name">{data.name}</div>
					<div className="file-thumbnail-footer">
						<div className="file-actions">
							<div className="file-footer-buttons">

								<button type="button" className="kv-file-remove btn btn-sm btn-kv btn-default btn-outline-secondary" onClick={this.deleteFile.bind(this, index)}title="Удалить файл"><i className="fas fa-trash-alt"></i></button>
							</div>
						</div>
					</div>

				</div>
			);


		});

	}

	renderAppendButton() {
		if (this.getFiles().length < 5) {
			return (
				<div className="input-group-btn input-group-append">
		        	<div className="btn btn-primary btn-file" onClick={this.openFileDialog.bind(this)}><i className="fas fa-folder-open"></i>Прикрепить файл</div>
    			</div>
			);
		} else {
			return null;
		}


	}

	render() {
		return (
			<div>
				<div className="file-holder">
					<label>Файлы</label>
					<div className="desc">Не более 5</div>
				</div>
				<div className="file-caption form-control kv-fileinput-caption icon-visible">
  					<span className="file-caption-icon"><i className="fas fa-file kv-caption-icon"></i> </span>
				</div>
				<div className="file-input theme-fas">
					<div className="file-preview">
						<div className=" file-drop-zone">
							<div className="file-preview-thumbnails">
								{ this.renderPreviews() }
							</div>
						</div>
					</div>

				</div>
				<div className="clearfix"/>
				{ this.renderAppendButton() }
    			<input id='file-selector' type="file" style={{'display': 'none'}} onChange={this.addFile.bind(this)} />

			</div>
		);

	}
}

export default withFiles(UploadFile);
