import React from 'react';

import {Link} from "react-router-dom";


class ViewProfile extends React.Component {

    constructor() {
        super();
        this.state = {'profile': null, 'game_profile': null}
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillReceiveProps(p) {
        //this.loadData();
    }

    loadData() {
        this.props.app.api('GET', '/api/profile', {}, (data) => {
            this.setState({'profile': data})
        }, (err) => {
            console.log(err)
        });


        this.props.app.api('GET', '/api/game/profile', {}, (data) => {
            this.setState({'game_profile': data})
        }, (err) => {
            console.log(err)
        });
    }

    renderProfile() {
        if (this.state.profile == null) {
            return null;
        }

        return (
            <div className="my-info">
                <h5>Мои данные</h5>
                <ul>
                    <li>Макрорегион: {this.state.profile.info.team && this.state.profile.info.team.Title}</li>
                    <li>Регион: {this.state.profile.info.region && this.state.profile.info.region.Title}</li>
                    <li>Департамент: {this.state.profile.info.functions.map((f) => {
                        return f.Title;
                    }).join(', ')}</li>
                    <li style={{'visibility': this.state.profile.info.alliance == '' ? 'hidden': 'visible'}}>КЦ: {this.state.profile.info.alliance}</li>
                </ul>
            </div>
        );
    }

    renderInWork() {
        if (this.state.profile == null) {
            return null;
        }

        return (

            <div className="my-info">
                <h5>Улучшения</h5>
                <ul>
                    <li>В работе: <span>{this.state.profile.stats.improvements.in_progress}</span></li>
                    <li>Создано: <span>{this.state.profile.stats.improvements.add}</span></li>
                    <li>Решил: <span>{this.state.profile.stats.improvements.solved}</span></li>
                    <li>Внедрено: <span>{this.state.profile.stats.improvements.implemented}</span></li>
                </ul>
                <h5>Идеи</h5>
                <ul>
                    <li>Создано: <span>{this.state.profile.stats.ideas.add}</span></li>
                    <li>Решил: <span>{this.state.profile.stats.ideas.solved}</span></li>
                    <li>Внедрено: <span>{this.state.profile.stats.ideas.implemented}</span></li>

                </ul>
            </div>
        )
    }

    renderGameStats() {
        if (this.state.game_profile == null) {
            return null;
        }

        return (
            <div className="skill">
                <div className="skill__item">
                    <div className="skill__name">Очки</div>
                    <div className="skill__value">{this.state.game_profile.points}</div>
                </div>
                <div className="skill__item">
                    <div className="skill__name">Телекоины</div>
                    <div className="skill__value">{this.state.game_profile.telecoins}</div>
                </div>
            </div>
        );
    }

    renderProjects() {
        if (this.state.profile == null) {
            return null;
        }

        if (this.state.profile.projects.length == 0) {
            return null;
        }

        return (
            <ul className="my-nav">
                {
                    this.state.profile.projects.map(proj => (
                        <li key={proj._id}><Link to={'/projects/' + proj._id}>{proj.name}</Link></li>))
                }
            </ul>
        )
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    {this.renderProfile()}
                    {this.renderInWork()}
                    {this.renderGameStats()}
                </div>

                <div className="col-md-6">
                    <div className="my">
                        <div className="my-name"><Link
                            to={"/user/" + this.props.app.user_data()['id']}>{this.props.app.user_data()['name']}</Link>
                        </div>

                    </div>

                    <ul className="my-nav">
                        <li><Link to="/ideas/created">Мои идеи</Link></li>
                        <li><Link to="/improvements/created">Мои улучшения</Link></li>
                        <li><Link to="/practices/created">Мои практики</Link></li>
                        <li><Link to="/comments">Мои комментарии</Link></li>
                        <li><Link to="/prizes">Мои призы</Link></li>
                        <li><Link to="/config">Настройки</Link></li>
                        <li><Link to="/ratings">Рейтинги</Link></li>
                        <li><Link to="/city_ratings">Рейтинг постройки города</Link></li>
                        <li style={{'display': 'none'}}><a href="#">Мои тесты</a></li>
                    </ul>
                    <ul className="my-nav" style={{'display': 'none'}}>
                        <li><a href="#">Мои данные</a></li>
                        <li><a href="#">Профиль и настройки</a></li>
                    </ul>
                    {this.renderProjects()}
                    <ul className="my-nav">
                        <li><a href="#" onClick={() => {
                            this.props.app.logout();
                        }}>Выйти</a></li>
                    </ul>
                </div>


            </div>
        );

    }

}


export default ViewProfile;
