import React from 'react';
import Modal from './modal.jsx';

class PracticeRouteAssign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'routes': [...props.routes, {
                function_title: '',
                region_title: '',
                can_delete: true
            }]
        }
    }

    componentDidUpdate() {
        $.initializeForm($('.select-route-table'));
    }

    functionOptions(region_id) {
        if (!region_id)
            return

        return this.props.app.storage.user_functions[region_id].map((func, idx) => {
            return (
                <option key={idx} value={func.ID}>{func.Title}</option>
            );
        });
    }

    regionOptions() {
        console.log(this.props.app.user_data())
        console.log(this.props.app.storage.user_functions)
        let region = this.props.app.storage.mr_regions


        if (this.props.app.user_data()['role'] === 'moderator_cf'){
            region = this.props.app.storage.regions
        }


        return region.map((reg, idx) => {
            if (reg.ID in this.props.app.storage.user_functions)
            {
                return (
                    <option key={idx} value={reg.ID}>{reg.Title}</option>
                );
            }
        });
    }

    functionChange(target, idx) {
        let routes = this.state.routes;
        routes[idx].function_id = $(target).val();
        routes[idx].function_title = $(target).find('option:selected').text();
        this.setState({'routes': routes})
    }

    regionChange(target, idx) {
        let routes = this.state.routes;
        routes[idx].region_id = $(target).val();
        routes[idx].region_title = $(target).find('option:selected').text();
        this.setState({'routes': routes})
    }

    saveRoutes() {
        this.props.onSave(this.state.routes.filter((elem) => !elem.removed))
    }

    removeRoute(idx) {
        let routes = this.state.routes;
        routes[idx].removed = true;
        this.setState({'routes': routes});
    }

  
    render() {
        if (this.state.routes == null) {
            return null;
        }
        let routes = this.state.routes.map((route, idx) => {
            let btn = null;
            if (route.removed) {
                return null;
            }
            if (route.can_delete) {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Регион:</label>
                                <select className="route-select custom-select" defaultValue={route.region_id}
                                        onChange={(e) => this.regionChange(e.target, idx)}>
                                    <option/>
                                    {this.regionOptions()}
                                </select>
                            </div>
                        </div>
                        <div className="select-route-cell">
                            <div className="select-field-holder text-active">
                                <label>Департамент:</label>
                                <select className="route-select custom-select" defaultValue={route.function_id}
                                        onChange={(e) => this.functionChange(e.target, idx)}>
                                    <option/>
                                    {this.functionOptions(route.region_id)}
                                </select>
                            </div>
                        </div>
                        {/* <div className="select-route-delete-btn" style={{'cursor': 'pointer'}}
                             onClick={this.removeRoute.bind(this, idx)}>Удалить
                        </div> */}
                    </div>
                );
            } else {
                return (
                    <div key={idx} className="select-route-row">
                        <div className="select-route-cell">
                            {route.region_title}
                        </div>
                        <div className="select-route-cell">
                            {route.function_title}
                        </div>
                        <div className="select-route-delete-btn" style={{'cursor': 'pointer'}}
                             onClick={this.removeRoute.bind(this, idx)}>Удалить
                        </div>
                    </div>

                );
            }
        })

        return (
            <Modal>
                <div className="modal-content">
                    <div className="select-route-header">
                        Назначить маршруты
                    </div>
                    <div className="select-route-table">
                        {routes}
                    </div>
                    <div className="form-actions">
                        <a className="btn btn--black" onClick={this.saveRoutes.bind(this)}>Сохранить</a>
                    </div>
                </div>
                <a onClick={() => {
                    this.props.onClose()
                }} className="close ico icon-close"/>
            </Modal>
        );
    }
}

export default PracticeRouteAssign;
