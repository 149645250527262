import React from 'react';

class RegionFunction extends React.Component {

  constructor() {
    super();
    this.state = {
      'regions': []
    }
   
  }

  componentDidMount() {
    $.initializeForm($('#login-form'));

    this.props.app.api('GET', '/api/regions', {}, (data) => {
      this.setState({'regions': data});
    })
  }

  renderOptions(coll) {
    return this.state[coll].map((r)=> {
      return (
        <option value={r.ID} key={r.ID}>{r.Title}</option>
      );
    })

  }

  saveData() {
    let function_id = $('#select-function').val()
    let region_id = $('#select-region').val()

    this.props.app.api('POST', '/api/user_data', {'region_id': region_id}, (data) => {
      this.props.updateLoginData(data);
    }, 
    () => { console.log('error')}
    )

  }


  render() {
    return (
      <div>
        <div className="login-header"/>
        <div className="login-container">
        <div className="title-holder">
            <div className="title-frame">
              <h2>Вход в Walk The Talk</h2>
            </div>
        </div>
    <div className="">
       <form action="" id="login-form">
        <div className="select-field-holder text-active">
               <label>Регион:</label>
               <select className="custom-select form-field" id='select-region'>
                  {this.renderOptions('regions')}
               </select>
              </div>

        <div className="modal-actions">
              <a className="btn" onClick={this.saveData.bind(this)}>Сохранить</a>
          </div>
       </form>
    </div>
  </div>
  </div>
      );

  }
}

export default RegionFunction;
