import React from 'react';
import Modal from './modal.jsx'
import { connect } from 'react-redux'

class ErrorModal extends React.Component {
    renderButtons() {
        return this.props.modal.buttons.map((btn) => {
           if (btn.price) {
                return <div key={btn.name} style={{marginBottom: '20px'}}><span className="modal_price">{btn.title}</span><span style={{width: '22px', height: '22px', backgroundSize: '100%'}} className="coin"/></div>
           }

           let onClick =  btn.onClick ? btn.onClick : () => {this.props.dismiss()};
           let className = "btn";
           if (btn.default) { className = className + " btn--black"}
           return (<a key={btn.name} onClick={onClick} className={className}>{btn.title}</a>)
        })
    }

    renderActions() {
        if (this.props.modal.buttons) {
            return (
              <div className="form-actions" style={{textAlign: 'center', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                { this.renderButtons() }
              </div>
            )
        } else {
            return null;
        }
    }

    renderMessages() {
        if (this.props.modal.message) {
            return (
              <div className="text-field-holder text-area-holder">
                {this.props.modal.message.map((item, idx) => (<p key={idx}>{item}</p>))}
              </div>
            )
        } else {
            return null;
        }
    }

    renderImage() {
        if (this.props.modal.image) {
            return (
              <div className="image-modal-wrapper">
  							<img src={this.props.modal.image} />
  						</div>
            )
        } else {
            return null;
        }
    }

    renderTitle() {
        if (this.props.modal.header) {
            return (<div className="title-holder">
               <div className="title-frame">
                  <h2>{this.props.modal.header}</h2>
               </div>
            </div>);
        } else {
            return null;
        }
    }

    renderDismiss() {
        if (this.props.modal.hideDismiss) {
              return null;
        }
        return (
            <a onClick={() => { this.props.dismiss()}} className="close ico icon-close"></a>
        )
    }

    render() {
      if (this.props.modal) {
        return (<Modal>
            <div className="modal-content">
            { this.renderTitle()}
            { this.renderImage() }
            { this.renderMessages() }
            { this.renderActions() }

            </div>
            { this.renderDismiss() }
        </Modal>)
      } else {
        return null;
      }
    }
}

const mapStateToProps = state => {
  return { modal: state.modal }
}

const mapDispatchToProps = dispatch => {
  return {
			dismiss: () => { dispatch({type:'MODAL_DISMISS'})}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
