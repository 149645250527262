import React from 'react';
import Header from './header.jsx'
import StatisticsContent from "./statistics_content.jsx";

class Statistics extends React.Component {

    constructor() {
        super();
        this.state = {
            'ts': new Date().getTime(),
            'page': null,
            'type': null,
            'assignee_id': null,
            'filter': null
        }
    }

    componentWillMount() {
        this.buildState(this.props)
    }

    buildState(props) {
        if (props.match.params.type) {
            this.setState({'type': props.match.params.type})
            if (props.match.params.type == 'ideas') {
                this.setState({'title': "Идеи"})
            } else {
                this.setState({'title': "Улучшения"})
            }
        }
        if (props.match.params.page) {
            this.setState({'page': props.match.params.page})
        }
        if (props.match.params.assignee_id) {
            this.setState({'assignee_id': props.match.params.assignee_id})
        }
        if (props.match.params.filter) {
            this.setState({'filter': props.match.params.filter})
        }
        console.log(props.match.params)
    }

    componentDidMount() {
        $.initializeForm($('.container'));
    }

    componentWillReceiveProps(new_props) {
        this.buildState(new_props)
    }


    render() {
        return (
            <div>
                <header>
                    <Header app={this.props.app} ts={this.state.ts}/>
                    <div className="header__title">
                        <div className="container">
                            Статистика
                        </div>
                    </div>
                </header>
                <section className="wrapper">
                    <div className="container">
                        <div className="statistics" style={{width: '100%', height: 'auto'}}>
                            <div style={{fontWeight: "bold", textAlign: "center"}}>{this.state.title}</div>
                            <StatisticsContent app={this.props.app}
                                               page={this.state.page}
                                               assignee_id={this.state.assignee_id}
                                               filter={this.state.filter}
                                               type={this.state.type}/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Statistics;
